<template>
    <div class="cont-table">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>


        <div class="row cont_documents_doxweb">
            <div class=" " style="margin:0 auto;">
                <div class="card">

                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive container-doc" style="">

                            <table id="myTable" style="margin-top:57px">
                                <thead>
                                    <tr>

                                        <th class='all'>&nbsp;</th>
                                        <th class='all'>N.</th>
                                        <th class='all' style="text-align: center;">Icon</th>
                                        <th class='all'>File / Folder</th>
                                        <th class='all'>Size</th>
                                        <th class='all' style="padding-left:10px!important">Date</th>
                                        <th class='all' style="text-align:center;"></th>
                                        <th class='all' style="text-align:center;"></th>

                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>
                                        <th colspan="8">
                                        </th>
                                    </tr>
                                </tfoot>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="contpopupsearch" style="display:none">

            <template>
                <modal name="popupSearch" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            <v-img alt="" src="@/assets/cerca-doxweb.png" max-width="25" max-height="25" />

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="textSearch" label="" id="fldSearch" class="fieldRicerca">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel3_doxweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Cancel" @click="btnCancelSearch" />

                                <v-img alt="" class="btn-delete-search" contain
                                    src="@/assets/icon_delete_filter_doxweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Remove filter" @click="btnDeleteSearch" />

                                <v-img alt="" class="btn-confirm-search" contain src="@/assets/btn_confirm3_doxweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Confirm" @click="btnConfirmSearch" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



        <div class="contpopupaddfolder" style="display:none">

            <template>
                <modal name="popupAddFolder" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Create New Folder

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="nameNewFolder" label="" id="fldNameNewFolder"
                                class="fieldNameNewFolder">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel3_doxweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Cancel" @click="btnCancelNewFolder" />

                                <v-img alt="" class="btn-confirm-search" contain src="@/assets/btn_confirm3_doxweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Confirm" @click="btnConfirmNewFolder" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopuprenamefolderfile" style="display:none">

            <template>
                <modal name="popupRenameFolderFile" :clickToClose="false" :width="312" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Rename "{{ nameCurrentElement }}"" to

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="nameNewElement" label="" id="fldNameNewElement"
                                class="fieldNameNewElement">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel3_doxweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Cancel" @click="btnCancelRenameFolderFile" />

                                <v-img alt="" class="btn-confirm-search" contain src="@/assets/btn_confirm3_doxweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Confirm" @click="btnConfirmRenameFolderFile" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopup3">

            <template>
                <modal name="popupUploadFile" :clickToClose="false" :width="386" :height="339">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:11px;margin-left:8px;font-weight: bold;">{{ titoloPopup }}</label>


                        </v-col>

                    </v-row>

                    <v-row class="rowPrevFile">

                        <v-col cols="12" md="12" style="margin-left: 12px;">


                            <span class="files">
                                <input type="file" id="input-file-attach" name="logo" :accept="fileAccettati"
                                    class="dropify" data-max-width="6610" data-max-height="6200" />
                            </span>


                        </v-col>

                    </v-row>


                    <v-row style="margin-top: 0px!important;">

                        <v-col cols="12" md="12" style="margin-top: -23px!important;display:flex">

                            <label style="font-size:11px;margin-left:12px;font-weight: bold;">File:</label>

                            <div style="margin-left: 10px;font-size:11px;font-weight: bold;">{{ nomeFilePresente }}
                            </div>

                        </v-col>

                    </v-row>

                    <v-row style="margin-top: 0px!important;">

                        <v-col cols="12" md="12" style="margin-top: -7px!important;display:flex">

                            <label style="font-size:11px;margin-left:12px;font-weight: bold;">Dim:</label>

                            <div style="margin-left: 10px;font-size:11px;font-weight: bold;">{{ dimFilePresente }}</div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-upload" contain src="@/assets/btn_cancel3_doxweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" @click="btnCancelUpload" />

                                <v-img alt="" class="btn-confirm-upload" contain src="@/assets/btn_confirm3_doxweb.png"
                                    max-width="32" max-height="32" style="cursor:pointer" transition="scale-transition"
                                    @click="btnConfirmUpload" />


                            </div>

                        </v-col>

                    </v-row>



                </modal>
            </template>

        </div>


        <div class="contpopupotpdelete" style="display:none">

            <template>
                <modal name="popupOtpDelete" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            OTP code sent to you via email

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field type="password" v-model="valueOtpDelete" label="" id="fldValueOtpDelete"
                                class="fieldValueOtpDelete">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-otp-delete" contain
                                    src="@/assets/btn_cancel3_doxweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Cancel" @click="btnCancelOtpDelete" />

                                <v-img alt="" class="btn-confirm-otp-delete" contain
                                    src="@/assets/btn_confirm3_doxweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Confirm"
                                    @click="btnConfirmOtpDelete" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>




        <div class="contpopupotpcud" style="display:none">

            <template>
                <modal name="popupOtpCud" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            OTP code sent to your email

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field type="password" v-model="valueOtpCud" label="" id="fldValueOtpCud"
                                class="fieldValueOtpCud">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-otp-cud" contain src="@/assets/btn_cancel3_doxweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Cancel" @click="btnCancelOtpCud" />

                                <v-img alt="" class="btn-confirm-otp-cud" contain src="@/assets/btn_confirm3_doxweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Confirm" @click="btnConfirmOtpCud" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopuppermissions" style="display:none">

            <template>
                <modal name="popupPermissions" :clickToClose="false" :width="344" :height="720">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;margin-left:10px;display:flex">

                            <div style="font-weight:bold;margin-left: 5px;font-size:8.5px">{{ eleSelectedPermissions }}
                            </div>

                        </v-col>

                    </v-row>

                    <v-row style="margin-top:5px!important;padding-top: 0px!important;">

                        <v-col cols="12" md="12" style="margin-top:0px!important;padding-top: 0px!important;">

                            <div class="clTitlePermissions">
                                E-Mail shared
                            </div>

                            <div>

                                <v-list style="overflow-y:auto;max-height: 80px;min-height: 80px;">

                                    <v-list-tile v-for="el in email_shared" :key="el.email">

                                        <div class="rowShared">

                                            <div class="colDescShared">
                                                <div class="selMail">{{ el.email }}</div>
                                            </div>

                                            <div class="colDeleteShared">

                                                <v-img alt="" contain src="@/assets/DOXWEB BOTTOM 49 cestino.png"
                                                    max-width="20" max-height="20" style="cursor:pointer"
                                                    transition="scale-transition" title="Delete" :data-id="el.email"
                                                    :folder-id="idFolderSelPermissions" :element-name="el.email"
                                                    id="imgDelEMailShared" />
                                            </div>

                                        </div>


                                    </v-list-tile>

                                </v-list>

                            </div>

                        </v-col>

                    </v-row>

                    <v-row style="margin-top:5px!important;padding-top: 0px!important;">

                        <v-col cols="12" md="12" style="margin-top:0px!important;padding-top: 0px!important;">

                            <div class="clTitlePermissions">
                                Groups shared
                            </div>

                            <div>

                                <v-list style="overflow-y:auto;max-height: 80px;min-height: 80px;">

                                    <v-list-tile v-for="el in groups_shared" :key="el.id">

                                        <div class="rowShared">

                                            <div class="colDescShared">
                                                <div class="selGroup" :data-id="el.id">{{ el.name }}</div>
                                            </div>

                                            <div class="colDeleteShared">
                                                <v-img alt="" contain src="@/assets/DOXWEB BOTTOM 49 cestino.png"
                                                    max-width="20" max-height="20" style="cursor:pointer"
                                                    transition="scale-transition" title="Delete" :data-id="el.id"
                                                    :folder-id="idFolderSelPermissions" :element-name="el.name"
                                                    id="imgDelGroupShared" />
                                            </div>

                                        </div>


                                    </v-list-tile>

                                </v-list>

                            </div>

                        </v-col>

                    </v-row>







                    <v-row style="padding-top:0px!important;padding-bottom: 0px!important;margin-top: 0px!important;">

                        <v-col cols="12" md="12"
                            style="font-weight:bold;margin-left:10px;padding-top:0px!important;padding-bottom: 0px!important;">

                            <div class="flex-container">

                                <div class="flex-center">

                                    <v-radio-group v-model="valueSelected" row style="margin-top:0px!important">

                                        <v-radio label="Group" value="G" @click="chgRadio" color="red"></v-radio>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <v-radio label="E-Mail" value="E" @click="chgRadio" color="red"></v-radio>

                                    </v-radio-group>

                                </div>


                                <div v-if="viewButtonConfirm" class="clConfirmGroupOrMail">

                                    <v-img alt="" class="btn-cancel-otp-delete" contain
                                        src="@/assets/btn_confirm3_doxweb.png" max-width="30" max-height="30"
                                        style="cursor:pointer;color:black" transition="scale-transition" title="Confirm"
                                        @click="btnConfirmGroupOrMail" />

                                </div>

                            </div>

                        </v-col>

                    </v-row>

                    <v-row v-if="viewGroups"
                        style="padding-top:0px!important;padding-bottom: 0px!important;margin-top: 0px!important;">

                        <v-col cols="12" md="12"
                            style="padding-top:0px!important;padding-bottom: 0px!important;margin-top: 0px!important;">

                            <div id="cont_gruppo" style="cursor:pointer;margin-top:5px">
                                <ion-item
                                    style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px">

                                    <ion-label
                                        style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                                        position="floating">Group </ion-label>

                                    <ion-select id="groupid" name="groupid" class='groupid' cancel-Text="Chiudi"
                                        done-Text="" :value="selGruppo" interface="action-sheet" disabled readonly>

                                        <ion-select-option v-for="item in gruppi" :key="item.idresources_group_name"
                                            :value='item.idresources_group_name'> {{
                                            item.full_name }}</ion-select-option>

                                    </ion-select>
                                </ion-item>
                            </div>


                        </v-col>

                    </v-row>

                    <v-row v-if="viewMail"
                        style="padding-top:0px!important;padding-bottom: 0px!important;margin-top: 0px!important;margin-bottom: 0px!important;">

                        <v-col cols="12" md="12"
                            style="padding-top:0px!important;padding-bottom: 0px!important;margin-top: 0px!important;margin-bottom: 0px!important">

                            <v-text-field v-model="mailSel" label="E-Mail" id="fldMail" class="clMail">
                            </v-text-field>

                        </v-col>

                    </v-row>

                    <v-row v-if="viewSpace">

                        <v-col cols="12" md="12">

                            <div style="height:288px">&nbsp;</div>

                        </v-col>

                    </v-row>

                    <v-row v-if="viewSpaceGroup">

                        <v-col cols="12" md="12">

                            <div style="height:274px">&nbsp;</div>

                        </v-col>

                    </v-row>

                    <v-row v-if="viewPermissions" style="padding-top:1px!important">

                        <v-col cols="12" md="12" style="padding-top:1px!important">

                            <div class="clTitlePermissions">
                                Files
                            </div>

                            <div class="cntRi" style="margin-top:5px">

                                <div>
                                    <input data-bs-toggle="tooltip" data-placement="top" type="checkbox" id="p_enter"
                                        title="Can enter">
                                </div>

                                <div style="margin-left: 5px;">
                                    <v-img alt="Vuetify Logo" class="  " contain
                                        src="@/assets/DOXWEB BOTTOM 34 can enter copia.png" max-width="25"
                                        max-height="25" transition="scale-transition" />
                                </div>

                                <div style="margin-left: 5px;font-weight: bold;font-size:9px;width:119px">
                                    Can enter
                                </div>

                                <div>
                                    <input data-bs-toggle="tooltip" data-placement="top" type="checkbox" id="p_show"
                                        title="Can view files">
                                </div>

                                <div style="margin-left: 5px;">
                                    <v-img alt="Vuetify Logo" class="  " contain
                                        src="@/assets/DOXWEB BOTTOM 33 view file.png" max-width="25" max-height="25"
                                        transition="scale-transition" />
                                </div>

                                <div style="margin-left: 5px;font-weight: bold;font-size:9px">
                                    Can view
                                </div>

                            </div>

                            <div class="cntRi" style="margin-top:5px">

                                <div>
                                    <input data-bs-toggle="tooltip" data-placement="top" type="checkbox"
                                        id="p_remove_file" title="Can remove file">
                                </div>

                                <div style="margin-left: 5px;">
                                    <v-img alt="Vuetify Logo" class="  " contain
                                        src="@/assets/DOXWEB BOTTOM 35 remove file.png" max-width="25" max-height="25"
                                        transition="scale-transition" />
                                </div>

                                <div style="margin-left: 5px;font-weight: bold;font-size:9px;width:119px">
                                    Can remove
                                </div>

                                <div>
                                    <input data-bs-toggle="tooltip" data-placement="top" type="checkbox"
                                        id="p_rename_file_folder" title="Can rename files and folders">
                                </div>

                                <div style="margin-left: 5px;">
                                    <v-img alt="Vuetify Logo" class="  " contain
                                        src="@/assets/DOXWEB BOTTOM 36 rename.png" max-width="25" max-height="25"
                                        transition="scale-transition" />
                                </div>

                                <div style="margin-left: 5px;font-weight: bold;font-size:9px">
                                    Can rename
                                </div>

                            </div>


                            <div class="cntRi" style="margin-top:5px">

                                <div>
                                    <input data-bs-toggle="tooltip" data-placement="top" type="checkbox"
                                        id="p_otp_delete_file" title="Request otp on delete">
                                </div>

                                <div style="margin-left: 5px;">
                                    <v-img alt="Vuetify Logo" class="  " contain
                                        src="@/assets/DOXWEB BOTTOM 35 remove otp.png" max-width="25" max-height="25"
                                        transition="scale-transition" />
                                </div>

                                <div style="margin-left: 5px;font-weight: bold;font-size:9px;width:119px">
                                    OTP delete
                                </div>

                                <div>
                                    <input data-bs-toggle="tooltip" data-placement="top" type="checkbox"
                                        id="p_download_and_delete_file" title="Delete file on download">
                                </div>

                                <div style="margin-left: 10px;">
                                    <v-img alt="Vuetify Logo" class="  " contain
                                        src="@/assets/download_and_delete_doxweb.png" max-width="15" max-height="15"
                                        transition="scale-transition" />
                                </div>

                                <div style="margin-left: 9px;font-weight: bold;font-size:9px">
                                    Download & Delete
                                </div>

                            </div>

                            <div class="cntRi" style="margin-top:5px">

                                <div>
                                    <input data-bs-toggle="tooltip" data-placement="top" type="checkbox"
                                        id="p_send_file_by_mail" title="Send by mail">
                                </div>

                                <div style="margin-left: 5px;">
                                    <v-img alt="Vuetify Logo" class="  " contain src="@/assets/send_file_by_mail.png"
                                        max-width="25" max-height="25" transition="scale-transition" />
                                </div>

                                <div style="margin-left: 5px;font-weight: bold;font-size:9px;width:119px">
                                    Send by mail
                                </div>



                                <div>
                                    <input data-bs-toggle="tooltip" data-placement="top" type="checkbox"
                                        id="p_expired_file" title="Expiration">
                                </div>

                                <div style="margin-left: 10px;">
                                    <v-img alt="Vuetify Logo" class="  " contain src="@/assets/expiring_file.png"
                                        max-width="15" max-height="15" transition="scale-transition" />
                                </div>

                                <div style="margin-left: 9px;font-weight: bold;font-size:9px">
                                    Expiration
                                </div>






                            </div>



                            <div class="clTitlePermissions" style="margin-top: 10px;">
                                Folders
                            </div>

                            <div class="cntRi" style="margin-top:5px">

                                <div>
                                    <input data-bs-toggle="tooltip" data-placement="top" type="checkbox"
                                        id="p_remove_folder" title="Can remove folder">
                                </div>

                                <div style="margin-left: 5px;">
                                    <v-img alt="Vuetify Logo" class="  " contain
                                        src="@/assets/DOXWEB BOTTOM 38 remove folder.png" max-width="25" max-height="25"
                                        transition="scale-transition" />
                                </div>

                                <div style="margin-left: 5px;font-weight: bold;font-size:9px;width:140px">
                                    Can remove
                                </div>

                                <div>
                                    <input data-bs-toggle="tooltip" data-placement="top" type="checkbox"
                                        id="p_foldersignature" title="Can set folder for signatures">
                                </div>

                                <div style="margin-left: 5px;">
                                    <v-img alt="Vuetify Logo" class="  " contain
                                        src="@/assets/DOXWEB BOTTOM 40 folder signature.png" max-width="25"
                                        max-height="25" transition="scale-transition" />
                                </div>

                                <div style="margin-left: 5px;font-weight: bold;font-size:9px;width:140px">
                                    Can set signatures
                                </div>


                            </div>


                            <div class="cntRi" style="margin-top:5px">


                                <div>
                                    <input data-bs-toggle="tooltip" data-placement="top" type="checkbox" id="p_upload"
                                        title="Can upload">
                                </div>

                                <div style="margin-left: 5px;">
                                    <v-img alt="Vuetify Logo" class="  " contain
                                        src="@/assets/DOXWEB BOTTOM 41 can upload.png" max-width="25" max-height="25"
                                        transition="scale-transition" />
                                </div>

                                <div style="margin-left: 5px;font-weight: bold;font-size:9px;width:140px">
                                    Can upload
                                </div>

                                <div>
                                    <input class="no145" data-bs-toggle="tooltip" data-placement="top" type="checkbox"
                                        id="p_worker" title="Folder for workers">
                                </div>

                                <div style="margin-left: 5px;">
                                    <v-img alt="Vuetify Logo" class="  " contain
                                        src="@/assets/DOXWEB BOTTOM 42 folder workers.png" max-width="25"
                                        max-height="25" transition="scale-transition" />
                                </div>

                                <div style="margin-left: 5px;font-weight: bold;font-size:9px;width:140px">
                                    For workers
                                </div>

                            </div>


                            <div class="cntRi" style="margin-top:5px">


                                <div>
                                    <input data-bs-toggle="tooltip" data-placement="top" type="checkbox" id="p_email"
                                        title="[EMAILS]if enabled, it notifies the users involved">
                                </div>

                                <div style="margin-left: 5px;">
                                    <v-img alt="Vuetify Logo" class="  " contain
                                        src="@/assets/DOXWEB BOTTOM 43 email alert.png" max-width="25" max-height="25"
                                        transition="scale-transition" />
                                </div>

                                <div style="margin-left: 5px;font-weight: bold;font-size:9px;width:140px">
                                    EMails alert
                                </div>

                                <div>
                                    <input data-bs-toggle="tooltip" data-placement="top" type="checkbox" id="p_copy"
                                        title="To enable copy permissions">
                                </div>

                                <div style="margin-left: 5px;">
                                    <v-img alt="Vuetify Logo" class="  " contain
                                        src="@/assets/DOXWEB BOTTOM 44 copy permission.png" max-width="25"
                                        max-height="25" transition="scale-transition" />
                                </div>

                                <div style="margin-left: 5px;font-weight: bold;font-size:9px;width:140px">
                                    Copy permissions
                                </div>

                            </div>

                            <div class="cntRi" style="margin-top:5px">



                                <div>
                                    <input data-bs-toggle="tooltip" data-placement="top" type="checkbox"
                                        id="p_otp_delete_folder" title="Request otp on delete">
                                </div>

                                <div style="margin-left: 5px;">
                                    <v-img alt="Vuetify Logo" class="  " contain
                                        src="@/assets/DOXWEB BOTTOM 35 remove otp.png" max-width="25" max-height="25"
                                        transition="scale-transition" />
                                </div>

                                <div style="margin-left: 5px;font-weight: bold;font-size:9px;width:140px">
                                    OTP delete
                                </div>

                                <div>
                                    <input data-bs-toggle="tooltip" data-placement="top" type="checkbox"
                                        id="p_create_folder" title="Request otp on delete">
                                </div>

                                <div style="margin-left: 5px;">
                                    <v-img alt="Vuetify Logo" class="  " contain src="@/assets/doxweb_create_folder.png"
                                        max-width="25" max-height="25" transition="scale-transition" />
                                </div>

                                <div style="margin-left: 5px;font-weight: bold;font-size:9px;width: 140px;">
                                    Can create
                                </div>


                            </div>


                            <div class="cntRi" style="margin-top:5px">



                                <div>
                                    <input data-bs-toggle="tooltip" data-placement="top" type="checkbox"
                                        id="p_edit_permissions" title="Request otp on delete">
                                </div>

                                <div style="margin-left: 5px;">
                                    <v-img alt="Vuetify Logo" class="  " contain
                                        src="@/assets/edit_permissions_doxweb.png" max-width="25" max-height="25"
                                        transition="scale-transition" />
                                </div>

                                <div style="margin-left: 5px;font-weight: bold;font-size:9px;width:140px">
                                    Edit permissions
                                </div>


                                <div>
                                    <input data-bs-toggle="tooltip" data-placement="top" type="checkbox"
                                        id="p_zip_folder" title="Zip folder">
                                </div>

                                <div style="margin-left: 5px;">
                                    <v-img alt="Vuetify Logo" class="  " contain src="@/assets/zip_folder_doxweb.png"
                                        max-width="25" max-height="25" transition="scale-transition" />
                                </div>

                                <div style="margin-left: 5px;font-weight: bold;font-size:9px;width: 140px;">
                                    Can zip
                                </div>




                            </div>





                        </v-col>

                    </v-row>





                    <v-row style="padding-top: 0px!important;">

                        <v-col cols="12" md="12" style="padding-top: 0px!important;">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-permissions" contain
                                    src="@/assets/btn_cancel3_doxweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black" transition="scale-transition" title="Cancel"
                                    @click="btnCancelPermissions" />

                                <v-img alt="" class="btn-confirm-permissions" contain
                                    src="@/assets/icon_save_doxweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black" transition="scale-transition" title="Save"
                                    @click="btnConfirmPermissions" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>



        </div>



        <div class="contgruppi">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetGruppi" class="vaziendesheet" style="z-index:999999999999!important">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Gruppi
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetGruppi = !sheetGruppi">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field id="fldSearchGruppi" class="clSearchGruppi" hide-details
                                        prepend-icon="mdi-magnify" single-line v-model="searchGruppi">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredGruppi"
                                        :key="item.idresources_group_name" @click="manageClickGruppi(item)">

                                        <v-img alt="" src="@/assets/permissions_doweb.png" max-width="24"
                                            max-height="24" />

                                        <v-list-item-title class='itemAzienda'>{{ item.full_name }}</v-list-item-title>

                                    </v-list-item>


                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>


        <div class="contpopupconfirmcopypermissions" style="display:none">

            <template>
                <modal name="popupConfirmCopyPermissions" :clickToClose="false" :width="344" :height="347">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px">

                            <div class="swal2-icon swal2-warning swal2-icon-show" style="display: flex;">
                                <div class="swal2-icon-content">!</div>
                            </div>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px">

                            <div class="textWarning" id="txtWarning">
                            </div>

                        </v-col>

                    </v-row>


                    <v-row style="margin-top:0px!important">

                        <v-col cols="12" md="12" style="margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;margin-top:0px!important" id="rowRecursive">

                            <v-checkbox label="Recursive" v-model="selCopyRecursive" true-value="S" false-value="N"
                                id="selCopyRecursive" class="cl-checkbox"></v-checkbox>

                        </v-col>

                    </v-row>

                    <v-row style="padding-top: 0px!important;">

                        <v-col cols="12" md="12" style="padding-top: 0px!important;">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-permissions" contain
                                    src="@/assets/btn_cancel3_doxweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black" transition="scale-transition" title="Cancel"
                                    @click="btnCancelCopyPermissions" />

                                <v-img alt="" class="btn-confirm-permissions" contain
                                    src="@/assets/btn_confirm3_doxweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black" transition="scale-transition" title="Confirm"
                                    @click="btnConfirmCopyPermissions" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>



        </div>

        <div class="contpopupsignature" style="display:none">

            <template>
                <modal name="popupSignature" :clickToClose="false" :width="600" :height="322">

                    <v-row style="margin-bottom: 0px;">

                        <v-col cols="12" md="12" class="titleSignature">
                            FIRMA DI PRESA VISIONE
                        </v-col>

                    </v-row>



                    <v-row style="margin-top: 0px;">

                        <v-col cols="12" md="12" style="text-align: center;">

                            <canvas id="firmaCanvas" width="550" height="200"></canvas>

                        </v-col>

                    </v-row>



                    <v-row style="padding-top: 0px!important;">

                        <v-col cols="12" md="12" style="padding-top: 0px!important;">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-permissions" contain
                                    src="@/assets/btn_cancel3_doxweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black" transition="scale-transition"
                                    title="Cancel Signature" @click="btnCancelSignature" />

                                <v-img alt="" class="btn-confirm-permissions" contain
                                    src="@/assets/btn_confirm3_doxweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black" transition="scale-transition" title="Confirm"
                                    @click="btnConfirmSignature" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupsignatureviewfile" style="display:none">

            <template>
                <modal name="popupSignatureViewFile" :clickToClose="false" :width="600" :height="322">

                    <v-row style="margin-bottom: 0px;">

                        <v-col cols="12" md="12" class="titleSignature">
                            FIRMA DI PRESA VISIONE
                        </v-col>

                    </v-row>


                    <v-row style="margin-top: 0px;">

                        <v-col cols="12" md="12" style="text-align: center;">

                            <canvas id="firmaCanvas" width="550" height="200"></canvas>

                        </v-col>

                    </v-row>



                    <v-row style="padding-top: 0px!important;">

                        <v-col cols="12" md="12" style="padding-top: 0px!important;">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-permissions" contain
                                    src="@/assets/btn_cancel3_doxweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black" transition="scale-transition"
                                    title="Cancel Signature" @click="btnCancelSignatureViewFile" />

                                <v-img alt="" class="btn-confirm-permissions" contain
                                    src="@/assets/btn_confirm3_doxweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black" transition="scale-transition" title="Confirm"
                                    @click="btnConfirmSignatureViewFile" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>




        <div class="contpopupfavorities" style="display:none">

            <template>
                <modal name="popupFavorities" :clickToClose="false" :width="360" :height="347">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Favorities

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <v-list style="overflow-y:auto;max-height: 233px;;min-height: 233px;">


                                <v-list-tile v-for="el in favorities" :key="el.id">

                                    <div class="contCheck" style="display:flex!important;height:30px">



                                        <div>
                                            <v-checkbox v-model="idPermSel" multiple class="cl-checkbox"
                                                :value="el.id" />
                                        </div>

                                        <div class="rowFavorite" :data-id="el.id">
                                            {{ el.folder_description }}
                                        </div>


                                    </div>



                                </v-list-tile>



                            </v-list>

                        </v-col>

                    </v-row>


                    <v-row style="padding-top: 0px!important;">

                        <v-col cols="12" md="12" style="padding-top: 0px!important;">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-permissions" contain
                                    src="@/assets/btn_cancel3_doxweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black" transition="scale-transition"
                                    title="Cancel Signature" @click="btnCancelFavorities" />

                                <v-img alt="" class="btn-confirm-permissions" contain
                                    src="@/assets/xbox-cross-doxweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black" transition="scale-transition" title="Add"
                                    @click="btnAddFavorite" />

                                <v-img alt="" class="btn-confirm-permissions" contain
                                    src="@/assets/DOXWEB BOTTOM 49 cestino.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black" transition="scale-transition" title="Delete"
                                    @click="btnDeleteFavorite" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>


        </div>


        <div class="contpopupaddpswfolder" style="display:none">

            <template>
                <modal name="popupAddPswFolder" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Set Password

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field type="password" v-model="valuePassword" label="" id="fldValuePswFolder">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-otp-delete" contain
                                    src="@/assets/btn_cancel3_doxweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Cancel" @click="btnCancelAddPassword" />

                                <v-img alt="" class="btn-confirm-otp-delete" contain
                                    src="@/assets/btn_confirm3_doxweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Confirm"
                                    @click="btnConfirmAddPassword" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



        <div class="contpopupenterpswfolder" style="display:none">

            <template>
                <modal name="popupEnterPswFolder" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Password

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field type="password" v-model="valuePassword" label="" id="fldValuePswFolder">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row v-if="viewForgettenPassword" style="margin-top:0px!important;padding-top: 0px!important;">

                        <v-col cols="12" md="12" style="margin-top:0px!important;padding-top: 0px!important;">

                            <div class="forgottenPassword">Forgotten password ?</div>

                        </v-col>

                    </v-row>







                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-otp-delete" contain
                                    src="@/assets/btn_cancel3_doxweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Cancel" @click="btnCancelEnterPassword" />

                                <v-img alt="" class="btn-confirm-otp-delete" contain
                                    src="@/assets/btn_confirm3_doxweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Confirm"
                                    @click="btnConfirmEnterPassword" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupemailrecpsw" style="display:none">

            <template>
                <modal name="popupEmailRecPsw" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            E-Mail Forgotten Password

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field type="text" v-model="emailRecPsw" label="" id="fldValuePswFolder">
                            </v-text-field>

                        </v-col>

                    </v-row>






                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-otp-delete" contain
                                    src="@/assets/btn_cancel3_doxweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Cancel" @click="btnCancelEmailRecPassword" />

                                <v-img alt="" class="btn-confirm-otp-delete" contain
                                    src="@/assets/btn_confirm3_doxweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Confirm"
                                    @click="btnConfirmEmailRecPassword" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupviewfile" style="display:none">

            <template>
                <modal name="popupViewFile" id="pViewFile" :clickToClose="false" :width="1651" :height="600">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            View File

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="display: flex;
    justify-items: center;
    justify-content: center;
    align-items: center;">

                            <iframe id="iframe-pdf" style="width: 97%;
    height: 50vh;"></iframe>


                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-otp-delete" contain
                                    src="@/assets/btn_cancel3_doxweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Cancel" @click="btnCancelViewFile" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupmultiupload" style="display:none">

            <template>
                <modal name="popupMultiUpload" :clickToClose="false" :width="360" :height="400">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Upload Files

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-list class="listFile">


                                <v-list-tile v-for="el in fileUpload" :key="el.id">

                                    <div v-if="el.isImage" class="rowUploadFile">

                                        <div class="columnUploadFileSelect">
                                            <v-checkbox v-model="idFileSel" multiple :value="el.id"
                                                class="cl-checkbox " />
                                        </div>

                                        <div class="columnUploadFilePreview">
                                            <img :src="el.preview" />
                                        </div>

                                        <div class="columnUploadFileName">{{ el.name }}</div>

                                        <div class="columnUploadFileSize">{{ el.size }}</div>


                                    </div>

                                    <div v-if="!el.isImage" class="rowUploadFile">

                                        <div class="columnUploadFileSelect">
                                            <v-checkbox v-model="idFileSel" multiple :value="el.id"
                                                class="cl-checkbox " />
                                        </div>

                                        <div class="columnUploadFilePreview">
                                            <img style="width:30px" :src="el.preview" />
                                        </div>

                                        <div class="columnUploadFileName">{{ el.name }}</div>

                                        <div class="columnUploadFileSize">{{ el.size }}</div>


                                    </div>


                                </v-list-tile>




                            </v-list>


                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-upload-file" contain
                                    src="@/assets/btn_cancel3_doxweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Cancel" @click="btnCancelUploadFiles" />

                                <v-img alt="" class="btn-confirm-upload-file" contain
                                    src="@/assets/btn_confirm3_doxweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Confirm" @click="btnConfirmUploadFiles" />

                            </div>

                        </v-col>

                    </v-row>




                </modal>
            </template>

        </div>


        <div class="contpopupsendfilebymail" style="display:none">

            <template>
                <modal name="popupSendFileByMail" :clickToClose="false" :width="360" :height="303">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Send File By Mail

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field id="fldSubject" v-model="subjectSendFileByMail"
                                label="Subject"></v-text-field>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field id="fldEMail" v-model="emailSendFileByMail" label="E-Mail"></v-text-field>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-send-file-by-mail" contain
                                    src="@/assets/btn_cancel3_doxweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Cancel" @click="btnCancelSendFileByMail" />

                                <v-img alt="" class="btn-confirm-send-file-by-mail" contain
                                    src="@/assets/btn_confirm3_doxweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Confirm" @click="btnConfirmSendFileByMail" />

                            </div>

                        </v-col>

                    </v-row>




                </modal>
            </template>

        </div>



        <div class="contpopupexpiringfile" style="display:none">

            <template>
                <modal name="popupExpiringFile" :clickToClose="false" :width="277" :height="314">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            File Expiration

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <v-menu v-model="showPicker" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="dateExpireFile" label="Expire Date" persistent-hint readonly
                                        v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dateExpireFile" no-title @input="showPicker = false"
                                    locale="it"></v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">


                            <label style=" font-weight: bold !important;
    font-size: 11px !important;
    color: black !important;margin-left:8px;">Days Before Expire</label>

                            <div id="d_val_imp_rep_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="daysBeforeExpireFile" :options="'integer'"
                                                ref="fldValDaysBeforeExpire"
                                                id="fldValDaysBeforeExpire"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-expiring-file" contain
                                    src="@/assets/btn_cancel3_doxweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Cancel" @click="btnCancelExpiringFile" />

                                <v-img alt="" class="btn-confirm-expiring-file" contain
                                    src="@/assets/btn_confirm3_doxweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Confirm" @click="btnConfirmExpiringFile" />

                                <v-img v-if="viewBtnDeleteExpireFile" alt="" class="btn-delete-expiring-file" contain
                                    src="@/assets/icon_delete_filter_doxweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Delete" @click="btnDeleteExpiringFile" />



                            </div>

                        </v-col>

                    </v-row>




                </modal>
            </template>

        </div>



        <div class="contpopupviewfilepdf" style="display:none">

            <template>
                <modal name="popupViewFilePdf" id="pViewFilePdf" :clickToClose="false" :width="1651" :height="600">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            View File

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="display: flex;
    justify-items: center;
    justify-content: center;
    align-items: center;">

                            <div style="width: 94%;height: 50vh;overflow-y: auto;overflow-x: auto;">

                                <div id="container-view-pdf"></div>

                            </div>


                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-otp-delete" contain
                                    src="@/assets/btn_cancel3_doxweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Cancel" @click="btnCancelViewFilePdf" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


    </div>
</template>

<script>

import {
    bus
} from "../main";
/* import $ from 'jquery'; */

import apidoxweb from "../utils/doxweb/apidoxweb";
import apiotp from "../utils/otp/apiotp";
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';

import * as pdfjsLib from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {

        /*eslint-disable no-undef*/



        this.$root.$children[0].checkLogout();

        console.log("WIDT WINDOW: ", $(window).width());

        this.loadLogo();


        var pointerVue = this;


        window.myVal = "";

        window.p_worker = window.$cookies.get("doxweb_p_worker");
        window.askFiles = window.$cookies.get("doxweb_askFiles");
        window.folder_level = window.$cookies.get("doxweb_folder_level");
        window.id = window.$cookies.get("doxweb_id");
        window.login = window.$cookies.get("doxweb_login");
        window.useremail = window.$cookies.get("doxweb_useremail");
        window.a = window.$cookies.get("doxweb_a");
        window.b = window.$cookies.get("doxweb_b");
        window.app = window.$cookies.get("doxweb_app");

        window.idFoldersNavigation = [];
        window.nameFoldersNavigation = [];
        window.fullPathFoldersNavigation = [];
        window.infoFolderParent = [];

        window.idFolderOriginCopyPermissions = 0;
        window.nameFolderOriginCopyPermissions = "";
        window.folderLevelOriginCopyPermissions = 0;

        window.idFolderDestCopyPermissions = 0;
        window.nameFolderDestCopyPermissions = "";
        window.folderLevelDestCopyPermissions = 0;

        window.addFavorite = 0;


        window.cancelViewRow = 0;


        window.labelUrlForCedolini = "";


        window.opForCheckPassword = "";

        $("#backHeader").hide();

        window.lastFolderSel = "/home/lcservice/s3/users/admin/aziende/";

        window.fullPathFoldersNavigation.push(window.lastFolderSel);


        if (window.p_worker == null) {
            window.p_worker = "";
        }



        window.updateFolderSize = 0;


        window.selPathFileDownload = "";


        var id_folder_cedolini = window.$cookies.get("is_folder_cedolini");


        if (id_folder_cedolini > 0) {

            window.idFoldersNavigation = window.idFoldersNavigationCedolini;
            window.nameFoldersNavigation = window.nameFoldersNavigationCedolini;
            window.fullPathFoldersNavigation = window.fullPathFoldersNavigationCedolini;
            window.infoFolderParent = window.infoFolderParentCedolini;

            console.log("ID FOLDER NAVIG CEDOLINI: ", window.idFoldersNavigation);
            console.log("NAME FOLDER NAV CEDOLINI: ", window.nameFoldersNavigation);
            console.log("FULL PATH NAV CEDOLINI: ", window.fullPathFoldersNavigation);
            console.log("INFO PARENT FOLDER CEDOLINI: ", window.infoFolderParent);


            window.lastFolderSel = window.lastFolderSelCedolini;

            window.curpage = 0;
            window.askFiles = 1;

            window.p_worker = 1;

            window.folder_level = 4;

            window.app = "doxweb";

            window.id = id_folder_cedolini;

            // aggiorno label path navigazione
            var path_navigazione = "";

            if (this.getWindowMobile() == 0) {

                for (var x = 0; x < window.nameFoldersNavigation.length; x++) {

                    if (path_navigazione != "") {
                        path_navigazione = path_navigazione + " / ";
                    }

                    path_navigazione = path_navigazione + window.nameFoldersNavigation[x];

                }

            }

            if (this.getWindowMobile() == 1) { // mobile

                if (window.nameFoldersNavigation.length <= 4) {

                    for (var x3 = 0; x3 < window.nameFoldersNavigation.length; x3++) {

                        if (path_navigazione != "") {
                            path_navigazione = path_navigazione + " / ";
                        }

                        path_navigazione = path_navigazione + window.nameFoldersNavigation[x3];

                    }


                }



                if (window.nameFoldersNavigation.length > 3) {

                    path_navigazione = "..";

                    var x_start = 0;
                    var len_array = window.nameFoldersNavigation.length;
                    if (len_array >= 4 && len_array <= 6) {
                        x_start = 3;
                    }

                    if (len_array > 6 && len_array <= 8) {
                        x_start = 4;
                    }

                    if (len_array > 8 && len_array <= 10) {
                        x_start = 5;
                    }


                    for (var x4 = x_start; x4 < window.nameFoldersNavigation.length; x4++) {

                        if (path_navigazione != "") {
                            path_navigazione = path_navigazione + " / ";
                        }

                        path_navigazione = path_navigazione + window.nameFoldersNavigation[x4];

                    }

                }





            }



            console.log("LABEL URL CEDOLINI: ", path_navigazione);

            window.labelUrlForCedolini = path_navigazione;

            $("#backHeader").show();

            window.$cookies.set("is_folder_cedolini", 0, "9y");

        }

        window.perpage = 100;
        bus.$off("bottomIconClickEvent");

        window.curSrcText = "";

        setTimeout(() => {

            this.getGroups();

        }, 100);


        $(document).ready(
            function () {

                $("body").off();

                window.uname = window.$cookies.get("username");
                var uname = window.uname;

                const args = [uname, true, ['bar', 5], {
                    foo: 'baz'
                }];
                try {
                    console.log("entering user");
                    window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                    console.log("passed");

                } catch (error) {
                    // alert(error);
                }

            }
        );

        $(window).resize(function () {

            pointerVue.centerPopupViewFile();

            pointerVue.centerPopupViewFilePdf();


        });

        $(document).ready(function () {


            $('body').on('click', '.selMail', function () {


                console.log("MAIL: ", $(this).html());

                pointerVue.valueSelected = "E";

                pointerVue.enableDisableSection();

                pointerVue.mailSel = $(this).html();

                pointerVue.btnConfirmGroupOrMail();

            });

            $('body').on('click', '.selGroup', function () {


                console.log("ID GRP: ", $(this).attr("data-id"));

                pointerVue.valueSelected = "G";

                pointerVue.enableDisableSection();

                pointerVue.selGruppo = parseInt($(this).attr("data-id"));

                pointerVue.btnConfirmGroupOrMail();

            });





            $('.container-doc').on('dragover', function (e) {
                e.preventDefault();
                e.stopPropagation();
                $(this).addClass("div-hover");
            });

            $('.container-doc').on('dragleave', function (e) {
                e.preventDefault();
                e.stopPropagation();
                $(this).removeClass("div-hover");
            });

            $('.container-doc').on('drop', function (e) {
                e.preventDefault();
                e.stopPropagation();
                $(this).removeClass("div-hover");

                console.log("files: ", e.originalEvent.dataTransfer.files);

                console.log("FOLDER LEVEL: ", window.folder_level);


                if (window.folder_level > 0) {

                    var a = window.$cookies.get("a");

                    if (a == "S") { // SU


                        if (e.originalEvent.dataTransfer.files.length > 0) {


                            pointerVue.validateDocumentsMulti(e.originalEvent.dataTransfer.files);


                        }
                        else {

                            pointerVue.$swal({
                                icon: "error",
                                text: "Area where to drag files incorrect",
                                showConfirmButton: false,
                                timer: 2000
                            });


                        }

                    }
                    else { // non SU

                        var p_upload = window.infoFolderParent[window.infoFolderParent.length - 1][0]["p_upload"];

                        console.log("P_UPLOAD: ", p_upload);

                        if (p_upload == 1) {

                            if (e.originalEvent.dataTransfer.files.length > 0) {


                                pointerVue.validateDocumentsMulti(e.originalEvent.dataTransfer.files);


                            }
                            else {

                                pointerVue.$swal({
                                    icon: "error",
                                    text: "Area where to drag files incorrect",
                                    showConfirmButton: false,
                                    timer: 2000
                                });


                            }


                        }
                        else {

                            pointerVue.$swal({
                                icon: "error",
                                text: "Upload not enabled",
                                showConfirmButton: false,
                                timer: 2000
                            });

                        }




                    }








                }

                if (window.folder_level == 0) {

                    pointerVue.$swal({
                        icon: "error",
                        text: "Operation not permitted",
                        showConfirmButton: false,
                        timer: 2000
                    });



                }


            });






            $('body').on('click', '#imgDelGroupShared', function () {

                var id_group = $(this).attr("data-id");
                var id_folder = $(this).attr("folder-id");
                var element_name = $(this).attr("element-name");

                console.log("ID-GROUP: ", id_group);
                console.log("ID-FOLDER: ", id_folder);

                pointerVue.deleteElementShared(id_group, id_folder, "G", element_name);

            });





            $('body').on('click', '#imgDelEMailShared', function () {

                var v_email = $(this).attr("data-id");
                var id_folder = $(this).attr("folder-id");
                var element_name = $(this).attr("element-name");

                console.log("E-MAIL: ", v_email);
                console.log("ID-FOLDER: ", id_folder);

                pointerVue.deleteElementShared(v_email, id_folder, "E", element_name);

            });



            $('body').on('click', '.cancelViewRow', function () {

                console.log("CANCELVIEWROW");

                window.updateFolderSize = 0;

                window.cancelViewRow = 1;


            });



            $('body').on('click', '.forgottenPassword', function () {

                pointerVue.$modal.hide('popupEnterPswFolder');


                setTimeout(() => {

                    $(".contpopupemailrecpsw").show();

                    pointerVue.$modal.show('popupEmailRecPsw');

                }, 200);


            });




            $('body').on('click', '.rowFavorite', function () {

                var v_id = $(this).attr("data-id");

                console.log(v_id);

                pointerVue.goToFavorite(v_id);

            });








            $('body').on('click', '#backHeader', function () {


                pointerVue.backFolder();

            });








            $('body').on('focus', '#fldNameNewElement', function () {


                console.log("FLDSEARCH INPUT FOCUS");

                setTimeout(() => {


                    $(".fieldNameNewElement .v-input__slot").addClass("clBorderBottomGreen");

                    $(".fieldNameNewElement .v-text-field__slot").addClass("clBorderBottomGreen");

                }, 200);


            });







            $('body').on('input', '#fldMail', function () {


                console.log("FLDMAIL INPUT EVENT");

                $(".btn-confirm-permissions").hide();

                pointerVue.viewPermissions = false;
                pointerVue.viewSpace = true;


            });





            $('body').on('click', '#cont_gruppo', function (event) {
                console.log(event);

                pointerVue.searchGruppi = "";

                pointerVue.sheetGruppi = true;


            });



            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row-doxweb");

                    $("tr").eq(0).removeClass("active-row-doxweb");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row-doxweb");


                }

                pointerVue.hideShowButtonsSelRow();


            });



        });



        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);

            //  var a = window.app = window.$cookies.get("a");

            switch (data) {

                case "btn_remove":

                    window.updateFolderSize = 0;

                    // if (a == "S") {

                    //     pointerVue.getOtpDelete();

                    // }


                    // if (a == "N") {

                    var is_file = 0;
                    //   var p_otp_delete_folder = 0;

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            is_file = $(this).attr("is-file");
                            //   p_otp_delete_folder = $(this).attr("p_otp_delete_folder");


                        }

                    });


                    if (is_file == 0) // folder
                    {

                        pointerVue.checkPasswordFolderEnabled("delete_folder");


                    }


                    if (is_file == 1) { // file

                        var info_parent = window.infoFolderParent[window.infoFolderParent.length - 1];

                        console.log("INFOR PARENT FOLDER: ", info_parent[0]["p_otp_delete_file"]);


                        if (info_parent[0]["p_otp_delete_file"] == 1) {

                            pointerVue.getOtpDelete();

                        }

                        if (info_parent[0]["p_otp_delete_file"] == 0) {

                            pointerVue.deleteRow();

                        }






                    }




                    // }




                    break;

                case "btn_exit":
                    break;

                case "btn_create":

                    window.updateFolderSize = 0;

                    var tot = 0;

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            tot = tot + 1;

                        }

                    });


                    if (tot == 0) {

                        pointerVue.nameNewFolder = "";

                        $(".contpopupaddfolder").show();

                        pointerVue.$modal.show('popupAddFolder');

                        setTimeout(() => {

                            $("#fldNameNewFolder").focus();

                        }, 200);


                    }
                    else {

                        pointerVue.checkPasswordFolderEnabled("create_folder");

                    }




                    break;

                case "btn_rename":

                    window.updateFolderSize = 0;

                    var name_element2 = "";
                    var is_file2 = 0;

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {


                            name_element2 = $(this).attr("name-element");

                            is_file2 = $(this).attr("is-file");


                        }

                    });


                    console.log("NAME ELEMENT: ", name_element2);

                    this.nameNewElement = name_element2;
                    this.nameCurrentElement = name_element2;
                    this.isFile = is_file2;



                    if (is_file2 == 0) // folder
                    {

                        pointerVue.checkPasswordFolderEnabled("rename_folder");

                    }

                    if (is_file2 == 1) // file
                    {

                        $(".contpopuprenamefolderfile").show();

                        this.$modal.show('popupRenameFolderFile');

                    }




                    break;


                case "btn_permissions":

                    window.updateFolderSize = 0;

                    var tot3 = 0;

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            tot3 = tot3 + 1;

                        }

                    });


                    if (tot3 == 0) {

                        this.loadPermissions();


                    }
                    else {

                        pointerVue.checkPasswordFolderEnabled("permissions");

                    }





                    break;

                case "btn_back_root":


                    window.p_worker = window.$cookies.get("doxweb_p_worker");
                    window.askFiles = window.$cookies.get("doxweb_askFiles");
                    window.folder_level = window.$cookies.get("doxweb_folder_level");
                    window.id = window.$cookies.get("doxweb_id");
                    window.login = window.$cookies.get("doxweb_login");
                    window.useremail = window.$cookies.get("doxweb_useremail");
                    window.a = window.$cookies.get("doxweb_a");
                    window.b = window.$cookies.get("doxweb_b");
                    window.app = window.$cookies.get("doxweb_app");
                    window.curpage = undefined;

                    window.idFoldersNavigation = [];
                    window.nameFoldersNavigation = [];
                    window.fullPathFoldersNavigation = [];
                    window.infoFolderParent = [];

                    console.log("ARRAY 1: ", window.idFoldersNavigation);
                    console.log("ARRAY 2: ", window.nameFoldersNavigation);
                    console.log("ARRAY 3: ", window.fullPathFoldersNavigation);
                    console.log("ARRAY 4: ", window.infoFolderParent);

                    window.lastFolderSel = "/home/lcservice/s3/users/admin/aziende/";
                    window.fullPathFoldersNavigation.push(window.lastFolderSel);

                    $(".label_url").html("");

                    pointerVue.$root.$children[0].showProgress = true;


                    setTimeout(() => {

                        window.table.ajax.reload();

                        pointerVue.hideShowButtons(false);


                    }, 200);


                    break;

                case "btn_back":

                    window.curSrcText = "";

                    console.log("ID NAV: ", window.idFoldersNavigation);

                    console.log("PATH NAV: ", window.fullPathFoldersNavigation);

                    console.log("FOLDER LEVEL FIRST: ", window.folder_level);

                    window.folder_level = parseInt(window.folder_level) - 1;

                    console.log("FOLDER LEVEL AFTER: ", window.folder_level);

                    window.id = window.idFoldersNavigation[window.folder_level];

                    window.idFoldersNavigation.splice(window.folder_level, 1);

                    window.infoFolderParent.splice(window.folder_level, 1);

                    if (window.folder_level > 0) {

                        window.lastFolderSel = window.fullPathFoldersNavigation[window.folder_level];

                        console.log("LAST FOLDER: ", window.lastFolderSel);

                        window.fullPathFoldersNavigation.splice(window.folder_level, 1);

                    }


                    if (window.folder_level == 0) {

                        window.lastFolderSel = "/home/lcservice/s3/users/admin/aziende/";

                    }


                    // aggiorno label path navigazione
                    window.nameFoldersNavigation.splice(window.folder_level, 1);

                    var path_navigazione2 = "";


                    if (this.getWindowMobile() == 0) {

                        for (var x2 = 0; x2 < window.nameFoldersNavigation.length; x2++) {

                            if (path_navigazione2 != "") {
                                path_navigazione2 = path_navigazione2 + " / ";
                            }

                            path_navigazione2 = path_navigazione2 + window.nameFoldersNavigation[x2];

                        }

                    }

                    if (this.getWindowMobile() == 1) { // mobile

                        if (window.nameFoldersNavigation.length <= 4) {

                            for (var x5 = 0; x5 < window.nameFoldersNavigation.length; x5++) {

                                if (path_navigazione2 != "") {
                                    path_navigazione2 = path_navigazione2 + " / ";
                                }

                                path_navigazione2 = path_navigazione2 + window.nameFoldersNavigation[x5];

                            }


                        }



                        if (window.nameFoldersNavigation.length > 3) {

                            path_navigazione2 = "..";

                            var x_start2 = 0;
                            var len_array2 = window.nameFoldersNavigation.length;

                            if (len_array2 >= 4 && len_array2 <= 6) {
                                x_start2 = 3;
                            }

                            if (len_array2 > 6 && len_array2 <= 8) {
                                x_start2 = 4;
                            }

                            if (len_array2 > 8 && len_array2 <= 10) {
                                x_start2 = 5;
                            }



                            for (var x6 = x_start2; x6 < window.nameFoldersNavigation.length; x6++) {

                                if (path_navigazione2 != "") {
                                    path_navigazione2 = path_navigazione2 + " / ";
                                }

                                path_navigazione2 = path_navigazione2 + window.nameFoldersNavigation[x6];

                            }

                        }





                    }




                    $(".label_url").html(path_navigazione2);




                    console.log("FOLDER ID: ", window.id);

                    if (window.id != "#") {
                        window.curpage = 0;
                        window.askFiles = 1;
                    }
                    else {
                        window.curpage = undefined;
                        window.askFiles = 1;

                        $("#backHeader").hide();

                    }

                    window.app = "doxweb";

                    console.log("FOLDER CURPAGE: ", window.curpage);
                    console.log("FOLDER ASKFILES: ", window.askFiles);

                    pointerVue.$root.$children[0].showProgress = true;


                    setTimeout(() => {

                        window.table.ajax.reload();

                        pointerVue.hideShowButtons(false);


                    }, 200);

                    break;

                case "btn_view":


                    pointerVue.viewFolder();


                    break;


                case "btn_zip_download":

                    this.downloadZipFolderAndDelete();

                    break;




                case "btn_download":

                    name_element = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            name_element = $(this).attr("name-element");

                        }

                    });

                    var startNameFile = name_element.slice(0, 4);

                    if (startNameFile != "CUD-") {

                        var info_parent2 = window.infoFolderParent[window.infoFolderParent.length - 1];

                        console.log("INFOR PARENT FOLDER: ", info_parent2);

                        var p_download_and_delete_file = parseInt(info_parent2[0]["p_download_and_delete_file"]);

                        var data_id = parseInt(info_parent2[0]["data_id"]);

                        if (parseInt(info_parent2[0]["p_worker"]) == 0) {

                            if (p_download_and_delete_file == 0) {

                                pointerVue.downloadFile();

                            }

                            if (p_download_and_delete_file == 1) {

                                pointerVue.downloadFileAndDelete(data_id);

                            }

                        }


                        if (parseInt(info_parent2[0]["p_worker"]) == 1) {

                            pointerVue.sendMailCedoliniWithoutSign();


                        }


                    }

                    if (startNameFile == "CUD-") {

                        pointerVue.getOtpCud();


                    }





                    break;


                case "btn_upload":

                    window.updateFolderSize = 0;

                    var tot2 = 0;

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            tot2 = tot2 + 1;

                        }

                    });


                    if (tot2 == 0) {

                        this.openPopupUpload();


                    }
                    else {

                        pointerVue.checkPasswordFolderEnabled("upload_file");

                    }




                    break;


                case "btn_copy_permissions":

                    window.updateFolderSize = 0;

                    var folder_id_origin = 0;
                    var folder_name_origin = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {


                            folder_id_origin = $(this).attr("data-id");
                            folder_name_origin = $(this).attr("folder_file");

                        }

                    });

                    window.idFolderOriginCopyPermissions = folder_id_origin;
                    window.nameFolderOriginCopyPermissions = folder_name_origin;

                    var folder_level = 0;
                    if (window.folder_level != "#") {
                        folder_level = window.folder_level;
                    }

                    window.folderLevelOriginCopyPermissions = folder_level;

                    // $(".val_status").prop("checked", false);
                    // $("tr").find("td:eq(5)").find("img").remove();
                    // $("tr").removeClass("active-row-doxweb");

                    $(".val_status").prop("checked", false);
                    $("tr").find("td:eq(5)").find("div.rowContainIcon").remove();
                    $("tr").find("td:eq(6)").find("div.rowContainIconCancelRow").remove();
                    $("tr").find("td:eq(6)").removeClass("columnCancelRow");
                    $("tr").removeClass("active-row-doxweb");

                    this.setButtonsCopyPermissions();

                    break;


                case "btn_confirm_copy_permissions":

                    var errore = "";

                    var folder_id_dest = 0;
                    var folder_name_dest = "";
                    var is_file3 = 0;

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {


                            folder_id_dest = $(this).attr("data-id");
                            folder_name_dest = $(this).attr("folder_file");
                            is_file3 = $(this).attr("is-file");

                        }

                    });


                    if (folder_id_dest == 0) {

                        errore = "Select folder destination";

                    }


                    if (errore == "") {


                        if (is_file3 == 1) {

                            errore = "Select folder not a file";

                        }

                    }




                    if (errore == "") {


                        if (window.idFolderOriginCopyPermissions == folder_id_dest) {

                            errore = "The destination folder cannot be the same as the source folder";

                        }

                    }


                    if (errore == "") {

                        window.idFolderDestCopyPermissions = folder_id_dest;
                        window.nameFolderDestCopyPermissions = folder_name_dest;

                        var folder_level2 = 0;
                        if (window.folder_level != "#") {
                            folder_level2 = window.folder_level;
                        }

                        window.folderLevelDestCopyPermissions = folder_level2;


                        var v_tmp_folder_origin = window.nameFolderOriginCopyPermissions.replace("/home/lcservice/s3/users/", "../");

                        var v_tmp_folder_dest = window.nameFolderDestCopyPermissions.replace("/home/lcservice/s3/users/", "../");


                        var txtMsg = "Are you sure you want to copy the permissions of folder<br><b>" + v_tmp_folder_origin + "</b><br> to folder<br> <b>" + v_tmp_folder_dest + "</b> ?";

                        $(".contpopupconfirmcopypermissions").show();

                        this.$modal.show('popupConfirmCopyPermissions');



                        setTimeout(() => {

                            $("#txtWarning").html(txtMsg);

                        }, 200);



                    }
                    else {

                        this.$swal({
                            icon: "error",
                            text: errore,
                            showConfirmButton: false,
                            timer: 3000
                        });



                    }




                    break;

                case "btn_cancel_copy_permissions":


                    window.idFolderOriginCopyPermissions = 0;
                    window.nameFolderOriginCopyPermissions = "";
                    window.folderLevelOriginCopyPermissions = 0;

                    window.idFolderDestCopyPermissions = 0;
                    window.nameFolderDestCopyPermissions = "";
                    window.folderLevelDestCopyPermissions = 0;

                    $(".val_status").prop("checked", false);
                    $("tr").find("td:eq(5)").find("img").remove();
                    $("tr").removeClass("active-row-doxweb");

                    this.hideShowButtons();

                    break;


                case "btn_favorities":

                    window.updateFolderSize = 0;

                    this.getFavorites();

                    break;


                case "btn_cancel_add_favorite":

                    window.addFavorite = 0;

                    $(".val_status").prop("checked", false);
                    $("tr").find("td:eq(5)").find("img").remove();
                    $("tr").removeClass("active-row-doxweb");

                    this.hideShowButtons();

                    break;


                case "btn_confirm_add_favorite":


                    pointerVue.checkPasswordFolderEnabled("favorities");

                    break;


                case "btn_set_password":

                    this.getPswFolder();

                    break;


                case "btn_view_file":



                    var name_element = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            name_element = $(this).attr("name-element");

                        }

                    });

                    var elements = name_element.split(".");

                    var isPdf = 0;

                    if (elements[elements.length - 1] == "pdf") {

                        isPdf = 1;

                    }


                    if (isPdf == 0) {

                        console.log("ok");

                        pointerVue.updateStatusFile(isPdf);

                    }

                    if (isPdf == 1) {

                        var info_parent3 = window.infoFolderParent[window.infoFolderParent.length - 1];

                        console.log("INFOR PARENT FOLDER: ", info_parent3);


                        if (parseInt(info_parent3[0]["p_worker"]) == 0) {

                            pointerVue.updateStatusFile(isPdf);

                        }


                        if (parseInt(info_parent3[0]["p_worker"]) == 1) {

                            console.log("ok");

                            pointerVue.sendMailCedoliniWithoutSignViewFile();

                        }

                    }


                    break;

                case "btn_zip_folder":

                    pointerVue.setStateZipFolder();

                    break;




                case "btn_send_file_by_mail":

                    pointerVue.subjectSendFileByMail = "";

                    pointerVue.emailSendFileByMail = "";

                    $(".contpopupsendfilebymail").show();

                    pointerVue.$modal.show('popupSendFileByMail');


                    break;

                case "btn_expiration_file":


                    pointerVue.expirationFile();

                    break;

                default:
                    break;

            }
        });


        this.setupButtonsGrid();


        window.start = 0;

        pointerVue.setupGrid();


        this.hideShowButtons(false);

    },
    data: () => ({


        nomeFile: "",

        pdfUrl: "",

        viewBtnDeleteExpireFile: false,

        daysBeforeExpireFile: null,
        dateExpireFile: "",



        subjectSendFileByMail: "",
        emailSendFileByMail: "",


        valueOtpCud: "",

        idFileSel: [],


        fileUpload: [],


        idFolderSelPermissions: 0,


        emailRecPsw: "",

        viewForgettenPassword: false,

        valuePassword: "",

        favorities: [],

        idPermSel: [],

        selCopyRecursive: "S",

        groups_shared: [],

        email_shared: [],

        userMail: "",

        viewSpace: false,
        viewSpaceGroup: false,

        viewButtonConfirm: false,

        viewPermissions: false,

        mailSel: "",

        viewGroups: false,
        viewMail: false,

        searchGruppi: "",

        sheetGruppi: false,

        selGruppo: "",

        valueSelected: "",

        eleSelectedPermissions: "",

        // parametri popup upload
        titoloPopup: "Upload File",
        fileAccettati: ".pdf,.doc,.docx,.xls,.xlsx,.csv,.zip,.gif,.tiff,.png,.jpg,.rtf,.mp3,.mp4,.txt",
        nomeFilePresente: "",
        dimFilePresente: "",
        dimKbFilePresente: 0,




        isFile: 0,

        nameNewElement: "",

        nameCurrentElement: "",

        nameNewFolder: "",

        valueOtpDelete: "",

        sheetAziende: false,
        search: "",

        textSearch: "",

        aziende: [],


        gruppi: [],


        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    computed: {

        filteredGruppi() {

            console.log("GRUPPI: ", this.gruppi);

            return _.orderBy(this.gruppi.filter(item => {
                if (!this.searchGruppi) return this.gruppi;
                return (item.full_name.toLowerCase().includes(this.searchGruppi.toLowerCase()));
            }), 'headline')
        },

    },

    components: {
        VueAutonumeric,
    },


    methods: {


      
        deleteTempPdfFile: async function () {

            var that = this;

            that.$modal.hide('popupViewFilePdf');

            var response = await apidoxweb.deleteTempPdfFile(
                that.nomeFile
            ).then((res) => {

                console.log("res from deleteTempPdfFile", res);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },


        viewFile: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            var file_path = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {


                    file_path = $(this).attr("file-path");

                }

            });

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.loadFilePdf(
                v_token,
                file_path
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from loadFilePdf", res);


                if (res.data.Result == "OK") {


                    setTimeout(() => {


                        $(".contpopupviewfilepdf").show();

                        that.$modal.show('popupViewFilePdf');

                        that.pdfUrl = atob(res.data.Valore);

                        that.nomeFile = atob(res.data.Elemento);

                        setTimeout(() => {


                            that.loadPdf();

                            that.centerPopupViewFilePdf();


                        }, 200);



                    }, 100);




                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });

                }





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );




        },





        btnCancelViewFilePdf: function () {

            this.deleteTempPdfFile();
        },

        btnCancelViewFilePdfMobile: function () {

            this.deleteTempPdfFileMobile();
        },



        async loadPdf() {

            pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

            const pdfContainer = document.getElementById('container-view-pdf');
            pdfContainer.innerHTML = '';

            try {
                const pdf = await pdfjsLib.getDocument(this.pdfUrl).promise;
                for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
                    const page = await pdf.getPage(pageNum);
                    const viewport = page.getViewport({ scale: 1.5 });

                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    const renderContext = {
                        canvasContext: context,
                        viewport: viewport
                    };

                    await page.render(renderContext).promise;

                    pdfContainer.appendChild(canvas);
                }
            } catch (error) {
                console.error('Error loading PDF: ', error);
            }
        },


        deleteDataFileExpire: async function () {

            var that = this;

            that.$swal
                .fire({
                    title: "Delete",
                    html: "Are you sure you want to delete the expiration information of the selected file ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_doxweb.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_doxweb.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        that.$modal.hide('popupExpiringFile');

                        var file_path = "";


                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {


                                file_path = $(this).attr("file-path");

                            }

                        });



                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apidoxweb.deleteDataFileExpire(
                            file_path
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteDataFileExpire", res);

                            that.$swal({
                                icon: "success",
                                text: "Data deleted successfully",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();

                                that.hideShowButtons(false);

                                window.table.ajax.reload();

                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },


        btnDeleteExpiringFile: function () {

            var that = this;

            that.deleteDataFileExpire();


        },


        getDataFileExpire: async function () {

            var that = this;

            var file_path = "";


            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {


                    file_path = $(this).attr("file-path");

                }

            });


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.getDataFileExpire(
                file_path
            ).then(async (res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getDataFileExpire", res);

                if (res.data.Result == "OK") {


                    that.dateExpireFile = "";
                    that.daysBeforeExpireFile = null;
                    that.viewBtnDeleteExpireFile = false;

                    if (res.data.FileExpire != null) {


                        that.dateExpireFile = res.data.FileExpire.date_expire;

                        that.daysBeforeExpireFile = res.data.FileExpire.n_days_before_expire;

                        that.viewBtnDeleteExpireFile = true;


                    }



                    $(".contpopupexpiringfile").show();

                    that.$modal.show('popupExpiringFile');

                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });

                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );






        },



        saveDataFileExpire: async function () {

            var that = this;

            var file_path = "";


            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {


                    file_path = $(this).attr("file-path");

                }

            });


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.saveDataFileExpire(
                that.dateExpireFile,
                that.daysBeforeExpireFile,
                file_path
            ).then(async (res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from sendFileByMail", res);

                if (res.data.Result == "OK") {

                    that.$modal.hide('popupExpiringFile');


                    that.$swal({
                        icon: "success",
                        text: "Data saved successfully",
                        showConfirmButton: false,
                        timer: 3000
                    });


                    setTimeout(() => {

                        window.table.ajax.reload();

                    }, 3000);

                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });

                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );






        },


        btnCancelExpiringFile: function () {

            this.$modal.hide('popupExpiringFile');

        },


        btnConfirmExpiringFile: function () {

            var that = this;

            var errore = "";

            if (that.dateExpireFile == "") {
                errore = "Expire date required";
            }

            if (errore == "") {
                if (that.daysBeforeExpireFile == "") {
                    errore = "Days before expire required";
                }
            }

            if (errore == "") {
                if (that.daysBeforeExpireFile == null) {
                    errore = "Days before expire required";
                }
            }


            if (errore == "") {

                console.log("ok");

                that.saveDataFileExpire();

            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });


            }

        },





        expirationFile: async function () {

            var that = this;


            // $(".contpopupexpiringfile").show();

            // that.$modal.show('popupExpiringFile');

            that.getDataFileExpire();


        },


        validateEMail2: function (valore) {

            var result;

            var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;


            if (valore != undefined) {

                if (valore != "") {

                    if (valore.match(validRegex)) {
                        result = true;
                    }
                    else {
                        result = false;

                    }

                }
                else {

                    result = true;

                }



            }
            else {
                result = true;

            }


            return result;

        },


        sendFileByMail: async function () {

            var that = this;

            var name_element = "";
            var file_path = "";


            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {


                    name_element = $(this).attr("name-element");
                    file_path = $(this).attr("file-path");

                }

            });


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.sendFileByMail(
                that.subjectSendFileByMail,
                that.emailSendFileByMail,
                name_element,
                file_path
            ).then(async (res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from sendFileByMail", res);

                if (res.data.Result == "OK") {

                    that.$modal.hide('popupSendFileByMail');


                    that.$swal({
                        icon: "success",
                        text: "Mail sent successfully",
                        showConfirmButton: false,
                        timer: 3000
                    });


                    setTimeout(() => {

                        window.table.ajax.reload();

                    }, 3000);

                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });

                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );






        },



        btnCancelSendFileByMail: function () {

            this.$modal.hide('popupSendFileByMail');


        },

        btnConfirmSendFileByMail: function () {

            var that = this;

            var errore = "";

            if (that.subjectSendFileByMail == "") {
                errore = "Subject is required";
            }


            if (errore == "") {
                if (that.emailSendFileByMail == "") {
                    errore = "E-Mail is required";
                }
            }

            if (errore == "") {
                if (!that.validateEMail2(that.emailSendFileByMail)) {
                    errore = "E-Mail not valid";
                }
            }

            if (errore == "") {


                console.log("ok");

                that.sendFileByMail();

            }
            else {


                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });



            }



        },






        btnCancelOtpCud: function () {

            this.$modal.hide('popupOtpCud');


        },

        btnConfirmOtpCud: function () {


            if (this.valueOtpCud != "") {

                console.log("OTP INP: ", this.valueOtpCud);
                console.log("ENC OTP INP: ", btoa(this.valueOtpCud));

                if (window.myVal == btoa(this.valueOtpCud)) {

                    this.$modal.hide('popupOtpCud');

                    this.downloadCud();

                }
                else {

                    this.$swal({
                        icon: "error",
                        text: "Incorrect OTP value",
                        showConfirmButton: false,
                        timer: 2000
                    });


                }



            }
            else {

                this.$swal({
                    icon: "error",
                    text: "Specificare un  valore dell'OTP",
                    showConfirmButton: false,
                    timer: 2000
                });

            }



        },







        logError: async function (v_xhr, v_code, v_error, v_url_request, v_params_request) {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            v_xhr = JSON.stringify(v_xhr);
            v_params_request = JSON.stringify(v_params_request);

            var response = await apidoxweb.logError(
                v_token,
                v_xhr,
                v_code,
                v_error,
                v_url_request,
                v_params_request
            ).then(async (res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from logError", res);


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );






        },



        btnDeleteSearch: function () {

            console.log("ok");

            window.curSrcText = "";

            this.$modal.hide('popupSearch');


            window.table.ajax.reload();

        },





        sendMailNotifyDownloadCedolini: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");


            var file_path = window.selPathFileDownload;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;


            var response = await apidoxweb.sendMailNotifyDownloadCedolini(
                file_path,
                v_token
            ).then(async (res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from sendMailNotifyDownloadCedolini", res);


                if (res.data.Result == "OK") {

                    that.downloadFileCedolini();


                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });

                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );






        },


        btnCancelUploadFiles: function () {

            this.$modal.hide('popupMultiUpload');

        },

        btnConfirmUploadFiles: function () {

            console.log("ID FILE SEL: ", this.idFileSel);

            var errore = "";

            if (this.idFileSel.length == 0) {
                errore = "Select at least one file to upload";
            }


            if (errore == "") {

                console.log("FILES ORIGINAL: ", window.filesDragAndDrop);

                var filesSelected = [];

                for (var xx3 = 0; xx3 < window.filesDragAndDrop.length; xx3++) {


                    var isFind = 0;
                    for (var xx4 = 0; xx4 < this.idFileSel.length; xx4++) {

                        var v_index = xx3 + 1;

                        if (parseInt(v_index) == parseInt(this.idFileSel[xx4])) {
                            isFind = 1;
                        }

                    }

                    if (isFind == 1) {

                        filesSelected.push(window.filesDragAndDrop[xx3]);

                    }


                }


                console.log("FILES SELECTED: ", filesSelected);

                setTimeout(() => {


                    this.uploadDocumentsMulti(filesSelected);

                }, 200);


            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 4000
                });


            }




        },

        validateDocumentsMulti: async function (v_files) {

            var that = this;

            console.log("FILES: ", v_files);

            window.filesDragAndDrop = v_files;

            var errore = "";

            var extAccepted = that.fileAccettati.split(",");

            for (var xx2 = 0; xx2 < v_files.length; xx2++) {

                var exten = v_files[xx2].name.split(".").pop();

                var is_find = 0;

                for (var xx3 = 0; xx3 < extAccepted.length; xx3++) {

                    var exten2 = "." + exten;

                    if (exten2 == extAccepted[xx3]) {
                        is_find = 1;
                    }

                }

                if (is_find == 0) {

                    errore = "Accepted file formats are: " + that.fileAccettati;

                }

            }


            if (errore == "") {

                for (var xx4 = 0; xx4 < v_files.length; xx4++) {


                    if (v_files[xx4].size == 0) {

                        errore = "File size must be greater than 0 byte";

                    }

                }

            }




            if (errore == "") {

                console.log("ok");

                that.checkFileMultiExist(v_files);


            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 4000
                });


            }




        },



        openPopupUploadDocumentsMulti: async function (v_files) {


            var that = this;


            console.log("FILES: ", v_files);

            that.idFileSel = [];

            that.fileUpload = [];

            for (var xx = 0; xx < v_files.length; xx++) {


                var v_name = v_files[xx].name;
                var v_size_orig = v_files[xx].size;


                var v_size = "";

                if (v_size_orig < 1000) {
                    v_size = v_size_orig.toString() + " b";
                }

                if ((v_size_orig >= 1000) && (v_size_orig < 1000000)) {

                    v_size_orig = Math.round(v_size_orig / 1024);
                    v_size = v_size_orig.toString() + " Kb";
                }

                if ((v_size_orig >= 1000000) && (v_size_orig < 1000000000)) {

                    v_size_orig = Math.round(v_size_orig / 1024000);
                    v_size = v_size_orig.toString() + " Mb";
                }



                var v_preview = "";

                var fileExtension = v_name.split('.').pop();



                console.log("EXTENSION: ", fileExtension);


                if ((fileExtension == "png") || (fileExtension == "gif") || (fileExtension == "tiff") || (fileExtension == "jpg")) {

                    console.log("OK EXTENSION");

                    var reader = new FileReader();

                    reader.onload = function (event) {

                        console.log("OK EXTENSION ONLOAD");

                        var imageUrl = event.target.result;
                        v_preview = imageUrl;

                        var v_file = {
                            id: xx + 1,
                            name: v_name,
                            size: v_size,
                            preview: v_preview,
                            isImage: true,
                        };

                        that.fileUpload.push(v_file);

                        that.idFileSel.push(xx + 1);

                    };

                    reader.readAsDataURL(v_files[xx]);



                }
                else {


                    v_preview = this.getUrlDomain("_lib/img/" + fileExtension.toString().toUpperCase() + ".png");

                    var v_file = {
                        id: xx + 1,
                        name: v_name,
                        size: v_size,
                        preview: v_preview,
                        isImage: false,
                    };

                    that.fileUpload.push(v_file);

                    that.idFileSel.push(xx + 1);

                }


            }

            that.$root.$children[0].showProgress = false;


            console.log("ARRAT FILE: ", that.fileUpload);


            $(".contpopupmultiupload").show();

            that.$modal.show('popupMultiUpload');


            // setTimeout(() => {


            //     that.uploadDocumentsMulti(v_files);

            // }, 200);



        },








        uploadDocumentsMulti: async function (v_files) {

            var that = this;



            var v_name = "";

            for (var dd = 0; dd < v_files.length; dd++) {


                if (v_name != "") {

                    v_name = v_name + ", ";

                }


                v_name = v_name + v_files[dd].name;


            }




            var folder_file = window.lastFolderSel;

            var p_email = window.infoFolderParent[window.infoFolderParent.length - 1][0]["p_email"];
            var folder_id = window.infoFolderParent[window.infoFolderParent.length - 1][0]["data_id"];
            var folder_text = window.infoFolderParent[window.infoFolderParent.length - 1][0]["name_element"];

            console.log("FOLDER_ID: ", folder_id);
            console.log("FOLDER_TEXT: ", folder_text);

            var a = window.$cookies.get("a");

            if (a == "S") {

                p_email = 1;

            }
            else {

                if (p_email == "") {
                    p_email = 0;
                }

            }

            console.log("P_EMAIL: ", p_email);

            if (p_email == 0) {

                console.log(folder_file);

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                var response = await apidoxweb.uploadDocumentsMulti(
                    folder_file,
                    false,
                    "cedolini",
                    v_files
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from uploadDocumentsMulti", res);

                    if (res.data.Result == "OK") {


                        that.$modal.hide('popupMultiUpload');

                        that.$swal({
                            icon: "success",
                            text: "File(s) uploaded successfully",
                            showConfirmButton: false,
                            timer: 2000
                        });

                        setTimeout(() => {

                            window.app = "doxweb";

                            window.table.ajax.reload();


                        }, 200);


                    }
                    else {

                        that.$modal.hide('popupMultiUpload');

                        that.$swal({
                            icon: "error",
                            text: res.data.ErrDetails,
                            showConfirmButton: false,
                            timer: 3000
                        });

                    }




                }).catch(err => {

                    that.$modal.hide('popupMultiUpload');

                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );




            }


            if (p_email == 1) {

                console.log(folder_file);

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                var response2 = await apidoxweb.uploadDocumentsMulti(
                    folder_file,
                    false,
                    "cedolini",
                    v_files
                ).then(async (res) => {

                    if (res.data.Result == "OK") {


                        var v_object = "upload";
                        var login = atob(window.$cookies.get("token"));
                        var useremail = "l.capra@lc-service.it";
                        var is_folder_sel = 0;

                        console.log("FOLDER_FILE: ", folder_file);
                        console.log("OBJECT: ", v_object);
                        console.log("FOLDER_ID: ", folder_id);
                        console.log("IS_FOLDER_SEL: ", is_folder_sel);
                        console.log("FOLDER_TEXT: ", folder_text);
                        console.log("LOGIN: ", login);
                        console.log("USEREMAIL: ", useremail);


                        var response3 = await apidoxweb.notifyByFolderNew(
                            v_object,
                            folder_id,
                            folder_text,
                            login,
                            useremail,
                            v_name
                        ).then((res3) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from notifyByFolderNew", res3);

                            that.$modal.hide('popupMultiUpload');

                            that.$swal({
                                icon: "success",
                                text: "File(s) uploaded successfully",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                window.app = "doxweb";

                                window.table.ajax.reload();


                            }, 200);





                        }).catch(err3 => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err3);
                            var msg3 = err3.response.data.Error;
                            console.log(msg3);

                            that.$swal({
                                icon: "error",
                                text: "Error during upload !",
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("ERrori", "Non è stato possibile salvare i dati");
                            console.log("response", response3);

                        }

                        );










                    }
                    else {

                        that.$modal.hide('popupMultiUpload');

                        that.$root.$children[0].showProgress = false;

                        that.$swal({
                            icon: "error",
                            text: res.data.ErrDetails,
                            showConfirmButton: false,
                            timer: 3000
                        });

                    }




                }).catch(err => {

                    that.$modal.hide('popupMultiUpload');

                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile salvare i dati");
                    console.log("response", response2);

                }

                );


            }






        },





        loadLogo: async function () {

            console.log("LOAD LOGO API");

            var token = window.$cookies.get("token");

            try {
                var response = await this.$api
                    .LoadLogoCompanyNew(token, "emtool")
                    .then((res) => {

                        console.log("res LoadLogoCompanyNew: ", res);

                        this.$root.$children[0].pathLogoImage = res.data.UrlLogo;




                    });
            } catch (error) {
                console.log("err LoadLogoCompanyNew", error);
            }

            console.log(response);



        },



        downloadZipFolderAndDelete: async function () {

            var that = this;

            var data_id = "";
            var name_element = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {


                    data_id = $(this).attr("data-id");
                    name_element = $(this).attr("name-element");

                }

            });


            var file_name = data_id.toString() + "_" + name_element.toString() + ".zip";

            var file_path = "/home/lcservice/s3/pubblicadocumenti/" + file_name;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.deleteZipFolder(
                file_name
            ).then((res) => {

                console.log("res from deleteZipFolder", res);


                setTimeout(() => {

                    var url_call = that.getUrlDomain("downloadFileZip") + "?path_file=" + file_path + "&file_name=" + file_name;

                    console.log("URL TO CALL: ", url_call);

                    window.app = "doxweb";

                    that.hideShowButtons(false);

                    window.table.ajax.reload();

                    window.open(url_call, "_blank");


                    setTimeout(() => {

                        that.showSpinner = false;
                        that.$root.$children[0].showProgress = false;



                    }, 1000);

                }, 500);




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },


        setStateZipFolder: async function () {

            var that = this;

            var folder_id = 0;
            var name_element = "";
            var folder_file = "";


            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    folder_id = $(this).attr("data-id");
                    name_element = $(this).attr("name-element");
                    folder_file = $(this).attr("folder_file");


                }

            });


            if (folder_id == 0) {

                var v_folder_parent = window.infoFolderParent[window.infoFolderParent.length - 1];

                folder_id = v_folder_parent[0]["data_id"];

                name_element = v_folder_parent[0]["name_element"];

                folder_file = v_folder_parent[0]["folder_file"];

            }

            var response = await apidoxweb.setStateZipFolder(
                folder_id,
                name_element,
                folder_file
            ).then(async (res) => {

                console.log("res from setStateZipFolder", res);

                that.$swal({
                    icon: "success",
                    text: "Zip folder started",
                    showConfirmButton: false,
                    timer: 2000
                });

                window.app = "doxweb";

                that.hideShowButtons(false);

                window.table.ajax.reload();

                that.zipFolder(folder_id, name_element, folder_file);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },







        zipFolder: async function (folder_id, name_element, folder_file) {



            var response = await apidoxweb.zipFolder(
                folder_id,
                name_element,
                folder_file
            ).then((res) => {

                console.log("res from zipFolder", res);




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },


        deleteElementShared: async function (id_element, id_folder, type_element, name_element) {

            var that = this;

            var msg_warning = "";

            if (type_element == "E") {

                msg_warning = "Are you sure you want to delete the sharing with the email address " + name_element + " ?";

            }

            if (type_element == "G") {

                msg_warning = "Are you sure you want to delete sharing with the " + name_element + " group ?";

            }

            that.$swal
                .fire({
                    title: "Delete",
                    html: msg_warning,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Confirm' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_doxweb.png' />",
                    cancelButtonText: "<img title='Cancel' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_doxweb.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {


                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apidoxweb.deleteElementShared(
                            id_element,
                            id_folder,
                            type_element
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteElementShared", res);

                            that.$swal({
                                icon: "success",
                                text: "Data deleted successfully",
                                showConfirmButton: false,
                                timer: 2000
                            });


                            setTimeout(() => {

                                if (type_element == "E") {

                                    var email_shared_new = [];

                                    for (var x = 0; x < that.email_shared.length; x++) {

                                        if (that.email_shared[x].email != id_element) {

                                            email_shared_new.push({
                                                email: that.email_shared[x].email
                                            });

                                        }


                                    }


                                    that.email_shared = email_shared_new;

                                }



                                if (type_element == "G") {

                                    var group_shared_new = [];

                                    for (var x2 = 0; x2 < that.groups_shared.length; x2++) {

                                        if (parseInt(that.groups_shared[x2].id) != parseInt(id_element)) {

                                            group_shared_new.push({
                                                id: that.groups_shared[x2].id,
                                                name: that.groups_shared[x2].name
                                            });

                                        }


                                    }


                                    that.groups_shared = group_shared_new;

                                }




                                that.valueSelected = "";
                                that.selGruppo = "";
                                that.mailSel = "";
                                that.viewGroups = false;
                                that.viewMail = false;
                                that.viewPermissions = false;
                                that.viewButtonConfirm = false;
                                that.viewSpace = false;
                                $(".btn-confirm-permissions").hide();


                            }, 2000);







                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },









        btnCancelSignatureViewFile: function () {

            window.signaturePad.clear();

        },


        btnConfirmSignatureViewFile: function () {

            var errore = "";

            if (window.signaturePad.isEmpty()) {
                errore = "Firma richiesta";
            }

            if (errore == "") {

                console.log("ok");

                this.saveSignatureViewFile();

            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }



        },


        centerPopupViewFilePdf: function () {



            var windowWidth = $(window).width();
            var windowHeight = $(window).height();


            var divWidth = Math.ceil((90 / 100) * windowWidth);
            var divHeight = Math.ceil((65 / 100) * windowHeight);


            var leftPosition = (windowWidth - divWidth) / 2;
            var topPosition = (windowHeight - divHeight) / 2;

            $('#pViewFilePdf').find('.vm--modal').css({
                'position': 'absolute',
                'left': leftPosition + 'px',
                'top': topPosition + 'px',
                'width': divWidth + 'px',
                'height': divHeight + 'px'
            });


        },





        centerPopupViewFile: function () {



            var windowWidth = $(window).width();
            var windowHeight = $(window).height();


            var divWidth = Math.ceil((90 / 100) * windowWidth);
            var divHeight = Math.ceil((65 / 100) * windowHeight);


            var leftPosition = (windowWidth - divWidth) / 2;
            var topPosition = (windowHeight - divHeight) / 2;

            $('#pViewFile').find('.vm--modal').css({
                'position': 'absolute',
                'left': leftPosition + 'px',
                'top': topPosition + 'px',
                'width': divWidth + 'px',
                'height': divHeight + 'px'
            });


        },



        btnCancelViewFile: function () {

            var that = this;

            that.$modal.hide('popupViewFile');

        },



        updateStatusFile: async function (is_pdf) {

            var that = this;

            console.log("IS_PDF: ", is_pdf);

            var file_path = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    file_path = $(this).attr("file-path");

                }

            });


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.updateFileStatus(
                file_path
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from updateFileStatus", res);

                if (is_pdf == 0) {

                    that.viewFileDocxImage();


                }

                if (is_pdf == 1) {

                    that.viewFile();

                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },


        viewFileDocxImage: async function () {

            console.log("VIEWFILEDOCXIMAGE");

            var that = this;

            var key = "";


            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    key = $(this).attr("data-id");

                }

            });


            var ext = key.split(".").pop();

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.getContentFileForView(
                key
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getContentFileForView", res);

                window.app = "doxweb";

                window.table.ajax.reload();

                var externalfile = that.getUrlDomainForView("") + res.data.Result;

                var url =
                    "https://view.officeapps.live.com/op/embed.aspx?src=" +
                    externalfile;
                if (
                    ext == "jpg" ||
                    ext == "jpeg" ||
                    ext == "png" ||
                    ext == "gif" ||
                    ext == "tiff" ||
                    ext == "mp4" ||
                    ext == "m4a" ||
                    ext == "mp3" ||
                    ext == "txt"
                ) {
                    url = externalfile;
                }

                console.log("URL: ", url);

                $(".contpopupviewfile").show();

                that.$modal.show('popupViewFile');

                setTimeout(() => {

                    $("#iframe-pdf").attr("src", url);




                    that.centerPopupViewFile();




                }, 100);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );

        },





        btnCancelEmailRecPassword: function () {

            var that = this;

            that.$modal.hide('popupEmailRecPsw');

            setTimeout(() => {

                that.checkPasswordFolderEnabled(window.opForCheckPassword);


            }, 200);

        },

        btnConfirmEmailRecPassword: function () {

            var that = this;

            var errore = "";

            if (that.emailRecPsw == "") {
                errore = "E-Mail required";
            }

            if (errore == "") {

                if (!that.validateEMail(that.emailRecPsw)) {
                    errore = "E-Mail not valid";
                }

            }

            if (errore == "") {

                console.log("ok");

                that.sendMailRecPassword();

            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });


            }


        },



        btnCancelEnterPassword: function () {

            this.$modal.hide('popupEnterPswFolder');

        },

        btnConfirmEnterPassword: function () {


            var that = this;

            var errore = "";
            var seconds = 3000;

            if (that.valuePassword == "") {
                errore = "Password required";
            }

            if (errore == "") {

                if (!that.validatePassword(that.valuePassword)) {
                    errore = "The password must be between 8 and 15 characters and must contain at least one special character, one uppercase character, one lowercase character and one number";
                    seconds = 7000;
                }

            }

            if (errore == "") {

                console.log("ok");

                that.validatePasswordFolder();

            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: seconds
                });


            }



        },


        sendMailRecPassword: async function () {

            console.log("SENDMAILRECPASSWORD");


            var that = this;



            var v_id = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    v_id = $(this).attr("data-id");

                }

            });



            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.sendMailRecPassword(
                v_token,
                v_id,
                that.emailRecPsw
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from sendMailRecPassword", res);

                if (res.data.Result == "OK") {

                    that.$modal.hide('popupEmailRecPsw');

                    console.log("ok");

                    that.$swal({
                        icon: "success",
                        text: "An email with a link to recover your password was sent to you",
                        showConfirmButton: false,
                        timer: 3000
                    });


                }
                else {


                    that.$swal({
                        icon: "error",
                        text: res.data.ErrorDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });


                }




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );

        },





        validatePasswordFolder: async function () {


            console.log("VALIDATEPASSWORDFOLDER");


            var that = this;


            var v_id = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    v_id = $(this).attr("data-id");

                }

            });



            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.validatePasswordFolder(
                v_token,
                v_id,
                btoa(that.valuePassword)
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from validatePasswordFolder", res);

                if (res.data.Result == "OK") {

                    that.$modal.hide('popupEnterPswFolder');

                    if (window.opForCheckPassword == "view_folder") {

                        that.viewFolder();

                    }

                    if (window.opForCheckPassword == "create_folder") {

                        that.nameNewFolder = "";

                        $(".contpopupaddfolder").show();

                        that.$modal.show('popupAddFolder');

                        setTimeout(() => {

                            $("#fldNameNewFolder").focus();

                        }, 200);


                    }


                    if (window.opForCheckPassword == "delete_folder") {

                        var p_otp_delete_folder = 0;

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                p_otp_delete_folder = $(this).attr("p_otp_delete_folder");


                            }

                        });


                        if (p_otp_delete_folder == 1) {

                            that.getOtpDelete();

                        }

                        if (p_otp_delete_folder == 0) {

                            that.deleteRow();

                        }


                    }


                    if (window.opForCheckPassword == "upload_file") {

                        that.openPopupUpload();


                    }

                    if (window.opForCheckPassword == "permissions") {

                        that.loadPermissions();


                    }

                    if (window.opForCheckPassword == "favorities") {

                        that.validateAddFavorite();


                    }

                    if (window.opForCheckPassword == "rename_folder") {

                        $(".contpopuprenamefolderfile").show();

                        that.$modal.show('popupRenameFolderFile');


                    }



                }
                else {


                    that.$swal({
                        icon: "error",
                        text: res.data.ErrorDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });


                }




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );

        },

        checkPasswordFolderEnabled: async function (tipo_op) {

            console.log("CHECKPASSWORDFOLDERENABLED");


            var that = this;

            var v_token = window.$cookies.get("token");

            window.opForCheckPassword = tipo_op;

            var v_id = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    v_id = $(this).attr("data-id");

                }

            });


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.checkPasswordFolderEnabled(
                v_id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from checkPasswordFolderEnabled", res);


                if (res.data.Result == "S") {

                    console.log("ok");

                    that.valuePassword = "";

                    if (res.data.IsOwnerPassword == "S") {
                        that.viewForgettenPassword = true;
                    }

                    setTimeout(() => {

                        $(".contpopupenterpswfolder").show();

                        that.$modal.show('popupEnterPswFolder');

                    }, 200);


                }


                if (res.data.Result == "N") {

                    console.log("ok");


                    if (tipo_op == "view_folder") {


                        that.viewFolder();

                    }

                    if (tipo_op == "create_folder") {


                        that.nameNewFolder = "";

                        $(".contpopupaddfolder").show();

                        that.$modal.show('popupAddFolder');

                        setTimeout(() => {

                            $("#fldNameNewFolder").focus();

                        }, 200);

                    }



                    if (tipo_op == "delete_folder") {

                        var p_otp_delete_folder = 0;

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                p_otp_delete_folder = $(this).attr("p_otp_delete_folder");


                            }

                        });


                        if (p_otp_delete_folder == 1) {

                            that.getOtpDelete();

                        }

                        if (p_otp_delete_folder == 0) {

                            that.deleteRow();

                        }


                    }


                    if (tipo_op == "upload_file") {

                        that.openPopupUpload();

                    }


                    if (tipo_op == "permissions") {

                        that.loadPermissions();

                    }

                    if (tipo_op == "favorities") {

                        that.validateAddFavorite();

                    }

                    if (tipo_op == "rename_folder") {

                        $(".contpopuprenamefolderfile").show();

                        that.$modal.show('popupRenameFolderFile');

                    }

                }








            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },








        getPswFolder: async function () {

            console.log("GETPSWFOLDER");


            var that = this;


            var v_id = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    v_id = $(this).attr("data-id");

                }

            });


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.getPswFolder(
                v_id
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPswFolder", res);


                if (res.data.Result == "OK") {

                    console.log("ok");


                    setTimeout(() => {

                        $(".contpopupaddpswfolder").show();

                        that.$modal.show('popupAddPswFolder');

                    }, 200);


                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res.data.ErrorDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });



                }






            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },



        btnCancelAddPassword: function () {

            this.$modal.hide('popupAddPswFolder');

        },

        validatePassword: function (v_password) {

            var result = true;

            var validRegex = /^(?=.*\d)(?=.*[!@#$%^&*+])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

            if ((v_password.toString().length < 8) || (v_password.toString().length > 15)) {
                result = false;
            }

            if (result) {

                if (v_password.toString().match(validRegex)) {
                    result = true;
                }
                else {
                    result = false;

                }

            }


            return result;

        },


        setPasswordFolder: async function () {

            console.log("SETPASSWORDFOLDER");


            var that = this;

            that.$modal.hide('popupAddPswFolder');

            var v_id = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    v_id = $(this).attr("data-id");

                }

            });



            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.setPasswordFolder(
                v_token,
                v_id,
                btoa(that.valuePassword)
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from setPasswordFolder", res);


                that.$swal({
                    icon: "success",
                    text: "Set password successful",
                    showConfirmButton: false,
                    timer: 2000
                });




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },


        btnConfirmAddPassword: function () {

            var that = this;

            var errore = "";
            var seconds = 3000;

            if (that.valuePassword == "") {
                errore = "Password required";
            }

            if (errore == "") {

                if (!that.validatePassword(that.valuePassword)) {
                    errore = "The password must be between 8 and 15 characters and must contain at least one special character, one uppercase character, one lowercase character and one number";
                    seconds = 7000;
                }

            }

            if (errore == "") {

                console.log("ok");

                that.setPasswordFolder();

            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: seconds
                });


            }

        },




        goToFavorite: async function (v_id) {

            console.log("GOTOFAVORITIES");


            var that = this;

            that.$modal.hide('popupFavorities');

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.goToFavorite(
                v_id
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from goToFavorite", res);

                var ele_folder_attrib = res.data.Result.folder_attrib.split("|");

                var ids = ele_folder_attrib[0];
                var name_folder = ele_folder_attrib[1];
                var folder_file = ele_folder_attrib[2];
                var p_worker = ele_folder_attrib[3];
                var p_remove_file = ele_folder_attrib[4];
                var p_rename_file_folder = ele_folder_attrib[5];
                var p_otp_delete_file = ele_folder_attrib[6];
                var p_email = ele_folder_attrib[7];
                var p_upload = ele_folder_attrib[8];
                var p_create_folder = ele_folder_attrib[9];

                console.log("IDS: ", ids);
                console.log("NAME_FOLDER: ", name_folder);
                console.log("FOLDER_FILE: ", folder_file);
                console.log("P_WORKER: ", p_worker);
                console.log("P_REMOVE_FILE: ", p_remove_file);
                console.log("P_RENAME_FILE_FOLDER: ", p_rename_file_folder);
                console.log("P_OTP_DELETE_FILE: ", p_otp_delete_file);
                console.log("P_EMAIL: ", p_email);
                console.log("P_IPLOAD: ", p_upload);
                console.log("P_CREATE_FOLDER: ", p_create_folder);


                window.infoFolderParent = [];

                var ele_info_parent_folder = res.data.Result.array_info_parent_folder.split("#");

                for (var x = 0; x < ele_info_parent_folder.length; x++) {

                    var ele_info_parent_folder2 = ele_info_parent_folder[x].split("|");


                    var infoParentFolder = [
                        {
                            p_remove_file: ele_info_parent_folder2[0],
                            p_rename_file_folder: ele_info_parent_folder2[1],
                            p_otp_delete_file: ele_info_parent_folder2[2],
                            p_email: ele_info_parent_folder2[3],
                            p_upload: ele_info_parent_folder2[4],
                            data_id: ele_info_parent_folder2[5],
                            name_element: ele_info_parent_folder2[6],
                            p_create_folder: ele_info_parent_folder2[7],
                            p_worker: ele_info_parent_folder2[8]
                        }
                    ];

                    window.infoFolderParent.push(infoParentFolder);


                }

                console.log("FOLDER_PARENT: ", window.infoFolderParent);

                window.lastFolderSel = folder_file;

                window.curpage = 0;
                window.askFiles = 1;

                window.p_worker = p_worker;



                window.idFoldersNavigation = [];

                var ele_id_folder_nav = res.data.Result.array_id_folder_navigation.split("|");

                for (var x2 = 0; x2 < ele_id_folder_nav.length; x2++) {


                    window.idFoldersNavigation.push(ele_id_folder_nav[x2]);

                }

                console.log("FOLDERS_NAV: ", window.idFoldersNavigation);



                window.nameFoldersNavigation = [];

                var ele_name_folder = res.data.Result.array_name_folder_navigation.split("#");

                for (var x3 = 0; x3 < ele_name_folder.length; x3++) {


                    window.nameFoldersNavigation.push(ele_name_folder[x3]);

                }

                console.log("FOLDERS_NAME: ", window.nameFoldersNavigation);



                window.fullPathFoldersNavigation = [];

                var ele_full_path = res.data.Result.array_full_path_folders_navigation.split("#");

                for (var x4 = 0; x4 < ele_full_path.length; x4++) {


                    window.fullPathFoldersNavigation.push(ele_full_path[x4]);

                }

                console.log("FOLDERS_FULL_PATH: ", window.fullPathFoldersNavigation);



                window.folder_level = res.data.Result.folder_level;

                window.app = "doxweb";

                window.id = parseInt(ids);


                // aggiorno label path navigazione
                var path_navigazione = "";

                if (that.getWindowMobile() == 0) {

                    for (var x5 = 0; x5 < window.nameFoldersNavigation.length; x5++) {

                        if (path_navigazione != "") {
                            path_navigazione = path_navigazione + " / ";
                        }

                        path_navigazione = path_navigazione + window.nameFoldersNavigation[x5];

                    }

                }

                if (that.getWindowMobile() == 1) { // mobile

                    if (window.nameFoldersNavigation.length <= 4) {

                        for (var x6 = 0; x6 < window.nameFoldersNavigation.length; x6++) {

                            if (path_navigazione != "") {
                                path_navigazione = path_navigazione + " / ";
                            }

                            path_navigazione = path_navigazione + window.nameFoldersNavigation[x6];

                        }


                    }



                    if (window.nameFoldersNavigation.length > 3) {

                        path_navigazione = "..";

                        var x_start = 0;
                        var len_array = window.nameFoldersNavigation.length;
                        if (len_array >= 4 && len_array <= 6) {
                            x_start = 3;
                        }

                        if (len_array > 6 && len_array <= 8) {
                            x_start = 4;
                        }

                        if (len_array > 8 && len_array <= 10) {
                            x_start = 5;
                        }


                        for (var x7 = x_start; x7 < window.nameFoldersNavigation.length; x7++) {

                            if (path_navigazione != "") {
                                path_navigazione = path_navigazione + " / ";
                            }

                            path_navigazione = path_navigazione + window.nameFoldersNavigation[x7];

                        }

                    }





                }


                $(".label_url").html(path_navigazione);



                $("#backHeader").show();



                //that.$root.$children[0].showProgress = true;


                setTimeout(() => {

                    window.table.ajax.reload();

                    that.hideShowButtons(false);


                }, 200);












            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },



        getFavorites: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.getFavorites(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getFavorites", res);

                that.favorities = res.data.Result;

                setTimeout(() => {

                    $(".contpopupfavorities").show();

                    this.$modal.show('popupFavorities');

                }, 200);




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },


        addFavorite: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            var v_id = 0;
            var v_folder_file = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    v_id = $(this).attr("data-id");
                    v_folder_file = $(this).attr("folder_file");

                }

            });


            var ids = $("tr.active-row-doxweb").find(".val_status").attr("data-id");
            var name_folder = $("tr.active-row-doxweb").find(".val_status").attr("name-element");
            var folder_file = $("tr.active-row-doxweb").find(".val_status").attr("folder_file");
            var p_worker = $("tr.active-row-doxweb").find(".val_status").attr("p_worker");
            var p_remove_file = $("tr.active-row-doxweb").find(".val_status").attr("p_remove_file");
            var p_rename_file_folder = $("tr.active-row-doxweb").find(".val_status").attr("p_rename_file_folder");
            var p_otp_delete_file = $("tr.active-row-doxweb").find(".val_status").attr("p_otp_delete_file");
            var p_email = $("tr.active-row-doxweb").find(".val_status").attr("p_email");
            var p_upload = $("tr.active-row-doxweb").find(".val_status").attr("p_upload");
            var p_create_folder = $("tr.active-row-doxweb").find(".val_status").attr("p_create_folder");

            if (p_remove_file == null) {
                p_remove_file = 0;
            }
            if (p_remove_file == "") {
                p_remove_file = 0;
            }

            if (p_rename_file_folder == null) {
                p_rename_file_folder = 0;
            }
            if (p_rename_file_folder == "") {
                p_rename_file_folder = 0;
            }

            if (p_otp_delete_file == null) {
                p_otp_delete_file = 0;
            }

            if (p_otp_delete_file == "") {
                p_otp_delete_file = 0;
            }

            if (p_email == "") {
                p_email = 0;
            }

            if (p_upload == "") {
                p_upload = 0;
            }

            if (p_create_folder == "") {
                p_create_folder = 0;
            }


            if (p_worker == null) {
                p_worker = 0;
            }

            if (p_worker == "") {
                p_worker = 0;
            }


            var strFolderAttrib = ids.toString() + "|" + name_folder.toString() + "|" + folder_file.toString() + "|" + p_worker.toString() + "|" + p_remove_file.toString() + "|" + p_rename_file_folder.toString() + "|" + p_otp_delete_file.toString() + "|" + p_email.toString() + "|" + p_upload.toString() + "|" + p_create_folder.toString();

            // console.log(strFolderAttrib);

            var strArrayInfoFolderParent = "";

            console.log("INFO_FOLDER_PARENT: ", window.infoFolderParent);

            for (var x = 0; x < window.infoFolderParent.length; x++) {



                if (strArrayInfoFolderParent != "") {
                    strArrayInfoFolderParent = strArrayInfoFolderParent + "#";
                }

                strArrayInfoFolderParent = strArrayInfoFolderParent + window.infoFolderParent[x][0].p_remove_file.toString();
                strArrayInfoFolderParent = strArrayInfoFolderParent + "|" + window.infoFolderParent[x][0].p_rename_file_folder.toString();
                strArrayInfoFolderParent = strArrayInfoFolderParent + "|" + window.infoFolderParent[x][0].p_otp_delete_file.toString();
                strArrayInfoFolderParent = strArrayInfoFolderParent + "|" + window.infoFolderParent[x][0].p_email.toString();
                strArrayInfoFolderParent = strArrayInfoFolderParent + "|" + window.infoFolderParent[x][0].p_upload.toString();
                strArrayInfoFolderParent = strArrayInfoFolderParent + "|" + window.infoFolderParent[x][0].data_id.toString();
                strArrayInfoFolderParent = strArrayInfoFolderParent + "|" + window.infoFolderParent[x][0].name_element.toString();
                strArrayInfoFolderParent = strArrayInfoFolderParent + "|" + window.infoFolderParent[x][0].p_create_folder.toString();
                strArrayInfoFolderParent = strArrayInfoFolderParent + "|" + window.infoFolderParent[x][0].p_worker.toString();

            }

            if (strArrayInfoFolderParent != "") {
                strArrayInfoFolderParent = strArrayInfoFolderParent + "#";
            }

            strArrayInfoFolderParent = strArrayInfoFolderParent + p_remove_file.toString();
            strArrayInfoFolderParent = strArrayInfoFolderParent + "|" + p_rename_file_folder.toString();
            strArrayInfoFolderParent = strArrayInfoFolderParent + "|" + p_otp_delete_file.toString();
            strArrayInfoFolderParent = strArrayInfoFolderParent + "|" + p_email.toString();
            strArrayInfoFolderParent = strArrayInfoFolderParent + "|" + p_upload.toString();
            strArrayInfoFolderParent = strArrayInfoFolderParent + "|" + ids.toString();
            strArrayInfoFolderParent = strArrayInfoFolderParent + "|" + name_folder.toString();
            strArrayInfoFolderParent = strArrayInfoFolderParent + "|" + p_create_folder.toString();
            strArrayInfoFolderParent = strArrayInfoFolderParent + "|" + p_worker.toString();


            var strArrayIdFolderNavigation = "";

            for (var x2 = 0; x2 < window.idFoldersNavigation.length; x2++) {

                if (strArrayIdFolderNavigation != "") {
                    strArrayIdFolderNavigation = strArrayIdFolderNavigation + "|";
                }

                strArrayIdFolderNavigation = strArrayIdFolderNavigation + window.idFoldersNavigation[x2].toString();

            }

            if (strArrayIdFolderNavigation != "") {
                strArrayIdFolderNavigation = strArrayIdFolderNavigation + "|";
            }

            strArrayIdFolderNavigation = strArrayIdFolderNavigation + window.id.toString();



            var strArrayNameFoldersNavigation = "";

            for (var x3 = 0; x3 < window.nameFoldersNavigation.length; x3++) {

                if (strArrayNameFoldersNavigation != "") {
                    strArrayNameFoldersNavigation = strArrayNameFoldersNavigation + "#";
                }

                strArrayNameFoldersNavigation = strArrayNameFoldersNavigation + window.nameFoldersNavigation[x3];

            }

            if (strArrayNameFoldersNavigation != "") {
                strArrayNameFoldersNavigation = strArrayNameFoldersNavigation + "#";
            }

            strArrayNameFoldersNavigation = strArrayNameFoldersNavigation + name_folder;





            var strArrayFullPathFoldersNavigation = "";

            for (var x4 = 0; x4 < window.fullPathFoldersNavigation.length; x4++) {

                if (strArrayFullPathFoldersNavigation != "") {
                    strArrayFullPathFoldersNavigation = strArrayFullPathFoldersNavigation + "#";
                }

                strArrayFullPathFoldersNavigation = strArrayFullPathFoldersNavigation + window.fullPathFoldersNavigation[x4];

            }

            if (strArrayFullPathFoldersNavigation != "") {
                strArrayFullPathFoldersNavigation = strArrayFullPathFoldersNavigation + "#";
            }

            strArrayFullPathFoldersNavigation = strArrayFullPathFoldersNavigation + folder_file;


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.addFavorite(
                v_token,
                v_id,
                v_folder_file,
                strFolderAttrib,
                strArrayInfoFolderParent,
                strArrayIdFolderNavigation,
                strArrayNameFoldersNavigation,
                parseInt(window.folder_level) + 1,
                strArrayFullPathFoldersNavigation
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from addFavorite", res);


                if (res.data.Result == "OK") {


                    window.addFavorite = 0;

                    $(".val_status").prop("checked", false);
                    $("tr").find("td:eq(5)").find("img").remove();
                    $("tr").removeClass("active-row-doxweb");

                    that.hideShowButtons(false);

                    that.$swal({
                        icon: "success",
                        text: "Favorite added successfully.",
                        showConfirmButton: false,
                        timer: 3000
                    });


                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res.data.ErrorDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });


                }


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },


        validateAddFavorite: async function () {

            var that = this;

            var errore = "";

            var v_id = 0;
            var v_is_file = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    v_id = $(this).attr("data-id");
                    v_is_file = $(this).attr("is-file");

                }

            });

            if (v_id == 0) {
                errore = "Select folder";
            }

            if (errore == "") {
                if (v_is_file == 1) {
                    errore = "Select folder";
                }
            }

            if (errore == "") {

                console.log("ok");

                that.addFavorite();

            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });

            }

        },


        btnCancelFavorities: function () {

            this.$modal.hide('popupFavorities');


        },

        btnAddFavorite: function () {

            this.$modal.hide('popupFavorities');

            $(".val_status").prop("checked", false);
            $("tr").find("td:eq(5)").find("img").remove();
            $("tr").removeClass("active-row-doxweb");

            window.addFavorite = 1;

            this.setButtonsAddFavorite();

        },

        btnDeleteFavorite: function () {



            console.log("ID SEL: ", this.idPermSel);

            var errore = "";

            if (this.idPermSel.length == 0) {
                errore = "Select favorites";
            }

            if (errore == "") {

                console.log("ok");

                this.deleteFavorites();

            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });

            }


        },



        deleteFavorites: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            console.log("ID SEL: ", that.idPermSel);
            console.log("ID SEL LEN: ", that.idPermSel.length);

            var idsfavsel = "";

            for (var x = 0; x < that.idPermSel.length; x++) {

                if (idsfavsel != "") {
                    idsfavsel = idsfavsel + ",";
                }

                idsfavsel = idsfavsel + that.idPermSel[x].toString();

            }

            console.log("ID SEL STR: ", idsfavsel);


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.deleteFavorites(
                v_token,
                idsfavsel
            ).then(async (res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from deleteFavorites", res);



                that.favorities = res.data.Favorities;



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );




        },


        sendMailCedoliniWithoutSignViewFile: async function () {

            var that = this;

            var document_name = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {


                    document_name = $(this).attr("name-element");

                }

            });

            var username = window.$cookies.get("token");
            username = atob(username);

            var object = "download";

            var info_parent = window.infoFolderParent[window.infoFolderParent.length - 1];

            var folder_id = parseInt(info_parent[0]["data_id"]);

            var currentDate = new Date(); // Get current date and time

            var day = currentDate.getDate();
            var month = currentDate.getMonth() + 1; // Months are zero based
            var year = currentDate.getFullYear();

            var hours = currentDate.getHours();
            var minutes = currentDate.getMinutes();

            var v_hours = hours.toString();
            if (v_hours.length < 2) {
                v_hours = "0" + v_hours;
            }

            var v_minutes = minutes.toString();
            if (v_minutes.length < 2) {
                v_minutes = "0" + v_minutes;
            }

            var mdate = day.toString() + "-" + month.toString() + "-" + year.toString() + " " + v_hours + ":" + v_minutes;

            var login = username;

            var useremail = window.$cookies.get("doxweb_useremail");

            var codice_fiscale = window.$cookies.get("doxweb_usercf");

            console.log("OBJECT: ", object);
            console.log("FOLDER_ID: ", folder_id);
            console.log("DOCUMENT_NAME: ", document_name);
            console.log("MDATE: ", mdate);
            console.log("LOGIN: ", login);
            console.log("USEREMAIL: ", useremail);



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.sendMailUtenteCedolini(
                "codesWorker",
                folder_id,
                codice_fiscale
            ).then(async (res) => {

                console.log("res from sendMailUtenteCedolini", res);

                var response2 = await apidoxweb.sendMailCedolini(
                    object,
                    folder_id,
                    document_name,
                    mdate,
                    login,
                    useremail
                ).then(async (res2) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from sendMailCedolini", res2);


                    that.$swal({
                        icon: "success",
                        text: "Gentile utente ti abbiamo inviato una email ed un sms con la password per poter visualizzare il cedolino.",
                        showConfirmButton: false,
                        timer: 3000
                    });


                    setTimeout(() => {


                        that.viewFile();

                    }, 3000);


                }).catch(err2 => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg2 = err2.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg2,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile eliminare i dati");
                    console.log("response", response2);

                }

                );


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );




        },



        sendMailCedoliniWithoutSign: async function () {

            var that = this;



            var document_name = "";
            var file_path = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {


                    document_name = $(this).attr("name-element");
                    file_path = $(this).attr("file-path");

                }

            });

            window.selPathFileDownload = file_path;



            var id_tipo_folder = 0;
            if (file_path.toString().indexOf("12-TREDICESIMA") !== -1) {
                id_tipo_folder = 1;
            }
            if (file_path.toString().indexOf("06-QUATTORDICES") !== -1) {
                id_tipo_folder = 2;
            }



            var username = window.$cookies.get("token");
            username = atob(username);

            var object = "download";

            var info_parent = window.infoFolderParent[window.infoFolderParent.length - 1];

            var folder_id = parseInt(info_parent[0]["data_id"]);

            var currentDate = new Date(); // Get current date and time

            var day = currentDate.getDate();
            var month = currentDate.getMonth() + 1; // Months are zero based
            var year = currentDate.getFullYear();

            var hours = currentDate.getHours();
            var minutes = currentDate.getMinutes();

            var v_hours = hours.toString();
            if (v_hours.length < 2) {
                v_hours = "0" + v_hours;
            }

            var v_minutes = minutes.toString();
            if (v_minutes.length < 2) {
                v_minutes = "0" + v_minutes;
            }

            var mdate = day.toString() + "-" + month.toString() + "-" + year.toString() + " " + v_hours + ":" + v_minutes;

            var login = username;

            var useremail = window.$cookies.get("doxweb_useremail");

            var codice_fiscale = window.$cookies.get("doxweb_usercf");

            var elements = document_name.split("-");

            console.log("OBJECT: ", object);
            console.log("FOLDER_ID: ", folder_id);
            console.log("DOCUMENT_NAME: ", document_name);
            console.log("MDATE: ", mdate);
            console.log("LOGIN: ", login);
            console.log("USEREMAIL: ", useremail);
            console.log("ELEMENYS: ", elements);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;


            var response = await apidoxweb.sendMailUtenteCedolini(
                "codesWorker",
                folder_id,
                codice_fiscale,
                elements[0],
                elements[1],
                id_tipo_folder
            ).then(async (res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from sendMailUtenteCedolini", res);


                if (id_tipo_folder == 0) {

                    that.$swal({
                        icon: "success",
                        text: "Gentile utente ti abbiamo inviato una email con la password per poter visualizzare il cedolino, nel caso il cedolino non si scaricasse abilitare i popup del browser.",
                        showConfirmButton: false,
                        timer: 5000
                    });


                }

                if (id_tipo_folder == 1) {


                    that.$swal({
                        icon: "success",
                        text: "Gentile utente ti abbiamo inviato una email con la password per poter visualizzare la tredicesima, nel caso la tredicesima non si scaricasse abilitare i popup del browser.",
                        showConfirmButton: false,
                        timer: 5000
                    });


                }

                if (id_tipo_folder == 2) {

                    that.$swal({
                        icon: "success",
                        text: "Gentile utente ti abbiamo inviato una email con la password per poter visualizzare la quattordicesima, nel caso la quattordicesima non si scaricasse abilitare i popup del browser.",
                        showConfirmButton: false,
                        timer: 5000
                    });

                }


                setTimeout(() => {

                    that.sendMailNotifyDownloadCedolini();

                }, 5000);




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },

        saveSignatureViewFile: async function () {

            var that = this;

            that.$modal.hide('popupSignatureViewFile');

            var username = window.$cookies.get("token");
            username = atob(username);


            var data = "";
            var document_name = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {


                    data = $(this).attr("file-path");

                    document_name = $(this).attr("name-element");

                }

            });


            console.log("USERNAME: ", username);
            console.log("DATA: ", data);
            console.log("DOCUMENT_NAME: ", document_name);

            var signature = window.signaturePad.toDataURL();


            var elements = signature.split(",");


            console.log("SIGNATURE: ", elements[1]);


            var info_parent = window.infoFolderParent[window.infoFolderParent.length - 1];

            var folder_id = parseInt(info_parent[0]["data_id"]);


            var codice_fiscale = window.$cookies.get("doxweb_usercf");

            var object = "download";

            var currentDate = new Date(); // Get current date and time

            var day = currentDate.getDate();
            var month = currentDate.getMonth() + 1; // Months are zero based
            var year = currentDate.getFullYear();

            var hours = currentDate.getHours();
            var minutes = currentDate.getMinutes();

            var v_hours = hours.toString();
            if (v_hours.length < 2) {
                v_hours = "0" + v_hours;
            }

            var v_minutes = minutes.toString();
            if (v_minutes.length < 2) {
                v_minutes = "0" + v_minutes;
            }

            var mdate = day.toString() + "-" + month.toString() + "-" + year.toString() + " " + v_hours + ":" + v_minutes;

            var login = username;

            var useremail = window.$cookies.get("doxweb_useremail");


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.sendSignature(
                username,
                data,
                elements[1]
            ).then(async (res) => {


                console.log("res from sendSignature", res);


                var response = await apidoxweb.sendMailUtenteCedolini(
                    "codesWorker",
                    folder_id,
                    codice_fiscale
                ).then(async (res) => {

                    console.log("res from sendMailUtenteCedolini", res);

                    var response2 = await apidoxweb.sendMailCedolini(
                        object,
                        folder_id,
                        document_name,
                        mdate,
                        login,
                        useremail
                    ).then(async (res2) => {

                        that.$root.$children[0].showProgress = false;
                        console.log("res from sendMailCedolini", res2);


                        that.$swal({
                            icon: "success",
                            text: "Gentile utente ti abbiamo inviato una email ed un sms con il codice Otp per poter visualizzare il cedolino.",
                            showConfirmButton: false,
                            timer: 3000
                        });


                        setTimeout(() => {


                            that.viewFile();

                        }, 3000);


                    }).catch(err2 => {
                        that.$root.$children[0].showProgress = false;
                        console.log(err);
                        var msg2 = err2.response.data.Error;

                        that.$swal({
                            icon: "error",
                            text: msg2,
                            showConfirmButton: false,
                            timer: 8000
                        });
                        console.log("Errori", "Non è stato possibile eliminare i dati");
                        console.log("response", response2);

                    }

                    );


                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile eliminare i dati");
                    console.log("response", response);

                }

                );




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },





        saveSignature: async function () {

            var that = this;

            that.$modal.hide('popupSignature');

            var username = window.$cookies.get("token");
            username = atob(username);


            var data = "";
            var document_name = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {


                    data = $(this).attr("file-path");

                    document_name = $(this).attr("name-element");

                }

            });


            console.log("USERNAME: ", username);
            console.log("DATA: ", data);
            console.log("DOCUMENT_NAME: ", document_name);

            var signature = window.signaturePad.toDataURL();


            var elements = signature.split(",");


            console.log("SIGNATURE: ", elements[1]);


            var info_parent = window.infoFolderParent[window.infoFolderParent.length - 1];

            var folder_id = parseInt(info_parent[0]["data_id"]);


            var codice_fiscale = window.$cookies.get("doxweb_usercf");

            var object = "download";

            var currentDate = new Date(); // Get current date and time

            var day = currentDate.getDate();
            var month = currentDate.getMonth() + 1; // Months are zero based
            var year = currentDate.getFullYear();

            var hours = currentDate.getHours();
            var minutes = currentDate.getMinutes();

            var v_hours = hours.toString();
            if (v_hours.length < 2) {
                v_hours = "0" + v_hours;
            }

            var v_minutes = minutes.toString();
            if (v_minutes.length < 2) {
                v_minutes = "0" + v_minutes;
            }

            var mdate = day.toString() + "-" + month.toString() + "-" + year.toString() + " " + v_hours + ":" + v_minutes;

            var login = username;

            var useremail = window.$cookies.get("doxweb_useremail");


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.sendSignature(
                username,
                data,
                elements[1]
            ).then(async (res) => {


                console.log("res from sendSignature", res);


                var response = await apidoxweb.sendMailUtenteCedolini(
                    "codesWorker",
                    folder_id,
                    codice_fiscale
                ).then(async (res) => {

                    console.log("res from sendMailUtenteCedolini", res);

                    var response2 = await apidoxweb.sendMailCedolini(
                        object,
                        folder_id,
                        document_name,
                        mdate,
                        login,
                        useremail
                    ).then(async (res2) => {

                        that.$root.$children[0].showProgress = false;
                        console.log("res from sendMailCedolini", res2);


                        that.$swal({
                            icon: "success",
                            text: "Gentile utente ti abbiamo inviato una email ed un sms con il codice Otp per poter visualizzare il cedolino.",
                            showConfirmButton: false,
                            timer: 3000
                        });


                        setTimeout(() => {


                            that.downloadFile();

                        }, 3000);


                    }).catch(err2 => {
                        that.$root.$children[0].showProgress = false;
                        console.log(err);
                        var msg2 = err2.response.data.Error;

                        that.$swal({
                            icon: "error",
                            text: msg2,
                            showConfirmButton: false,
                            timer: 8000
                        });
                        console.log("Errori", "Non è stato possibile eliminare i dati");
                        console.log("response", response2);

                    }

                    );


                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile eliminare i dati");
                    console.log("response", response);

                }

                );




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },





        saveSignatureOld: async function () {

            var that = this;

            that.$modal.hide('popupSignature');

            var username = window.$cookies.get("token");
            username = atob(username);


            var data = "";
            var document_name = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {


                    data = $(this).attr("file-path");

                    document_name = $(this).attr("name-element");

                }

            });


            console.log("USERNAME: ", username);
            console.log("DATA: ", data);

            var signature = window.signaturePad.toDataURL();


            var elements = signature.split(",");


            console.log("SIGNATURE: ", elements[1]);


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.sendSignature(
                username,
                data,
                elements[1]
            ).then(async (res) => {


                console.log("res from sendSignature", res);

                var object = "download";

                var info_parent = window.infoFolderParent[window.infoFolderParent.length - 1];

                var folder_id = parseInt(info_parent[0]["data_id"]);

                var currentDate = new Date(); // Get current date and time

                var day = currentDate.getDate();
                var month = currentDate.getMonth() + 1; // Months are zero based
                var year = currentDate.getFullYear();

                var hours = currentDate.getHours();
                var minutes = currentDate.getMinutes();

                var v_hours = hours.toString();
                if (v_hours.length < 2) {
                    v_hours = "0" + v_hours;
                }

                var v_minutes = minutes.toString();
                if (v_minutes.length < 2) {
                    v_minutes = "0" + v_minutes;
                }

                var mdate = day.toString() + "-" + month.toString() + "-" + year.toString() + " " + v_hours + ":" + v_minutes;

                var login = username;

                var useremail = window.$cookies.get("doxweb_useremail");

                console.log("OBJECT: ", object);
                console.log("FOLDER_ID: ", folder_id);
                console.log("DOCUMENT_NAME: ", document_name);
                console.log("MDATE: ", mdate);
                console.log("LOGIN: ", login);
                console.log("USEREMAIL: ", useremail);



                var response2 = await apidoxweb.sendMailCedolini(
                    object,
                    folder_id,
                    document_name,
                    mdate,
                    login,
                    useremail
                ).then(async (res2) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from sendMailCedolini", res2);


                    that.$swal({
                        icon: "success",
                        text: "Gentile utente ti abbiamo inviato una email ed un sms con il codice Otp per poter visualizzare il cedolino.",
                        showConfirmButton: false,
                        timer: 3000
                    });


                    setTimeout(() => {


                        that.downloadFile();

                    }, 3000);


                }).catch(err2 => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg2 = err2.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg2,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile eliminare i dati");
                    console.log("response", response2);

                }

                );





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );






        },


        btnCancelSignature: function () {

            window.signaturePad.clear();


        },

        btnConfirmSignature: function () {

            var errore = "";

            if (window.signaturePad.isEmpty()) {
                errore = "Firma richiesta";
            }

            if (errore == "") {

                console.log("ok");

                this.saveSignature();

            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }



        },





        btnCancelCopyPermissions: function () {

            this.$modal.hide('popupConfirmCopyPermissions');


        },

        btnConfirmCopyPermissions: function () {

            this.copyPermissions();

        },


        copyPermissions: async function () {

            var that = this;

            that.$modal.hide('popupConfirmCopyPermissions');

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.copyPermissions(
                window.idFolderOriginCopyPermissions,
                window.nameFolderOriginCopyPermissions,
                window.folderLevelOriginCopyPermissions,
                window.idFolderDestCopyPermissions,
                window.nameFolderDestCopyPermissions,
                window.folderLevelDestCopyPermissions,
                that.selCopyRecursive
            ).then(async (res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from copyPermissions", res);

                window.idFolderOriginCopyPermissions = 0;
                window.nameFolderOriginCopyPermissions = "";
                window.folderLevelOriginCopyPermissions = 0;
                window.idFolderDestCopyPermissions = 0;
                window.nameFolderDestCopyPermissions = "";
                window.folderLevelDestCopyPermissions = 0;

                $(".val_status").prop("checked", false);
                $("tr").find("td:eq(5)").find("div.rowContainIcon").remove();
                $("tr").find("td:eq(6)").find("div.rowContainIconCancelRow").remove();
                $("tr").find("td:eq(6)").removeClass("columnCancelRow");
                $("tr").removeClass("active-row-doxweb");

                that.$swal({
                    icon: "success",
                    text: "Permissions copied successfully",
                    showConfirmButton: false,
                    timer: 2000
                });


                setTimeout(() => {

                    that.dialogMsg = false;
                    that.setupButtons();

                    that.hideShowButtons(false);

                }, 200);


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );






        },



        backFolder: function () {

            // var that = this;

            window.updateFolderSize = 0;

            window.curSrcText = "";

            console.log("ID NAV: ", window.idFoldersNavigation);

            console.log("PATH NAV FIRST: ", window.fullPathFoldersNavigation);

            console.log("FOLDER LEVEL FIRST: ", window.folder_level);

            window.fullPathFoldersNavigation.splice(window.folder_level, 1);

            console.log("PATH NAV AFTER: ", window.fullPathFoldersNavigation);



            window.folder_level = parseInt(window.folder_level) - 1;

            console.log("FOLDER LEVEL AFTER: ", window.folder_level);

            window.id = window.idFoldersNavigation[window.folder_level];

            window.idFoldersNavigation.splice(window.folder_level, 1);

            window.infoFolderParent.splice(window.folder_level, 1);

            if (window.folder_level > 0) {

                window.lastFolderSel = window.fullPathFoldersNavigation[window.folder_level];


                console.log("LAST FOLDER: ", window.lastFolderSel);

                // window.fullPathFoldersNavigation.splice(window.folder_level, 1);

            }


            if (window.folder_level == 0) {

                window.lastFolderSel = "/home/lcservice/s3/users/admin/aziende/";

            }


            // aggiorno label path navigazione
            window.nameFoldersNavigation.splice(window.folder_level, 1);

            var path_navigazione2 = "";


            if (this.getWindowMobile() == 0) {

                for (var x2 = 0; x2 < window.nameFoldersNavigation.length; x2++) {

                    if (path_navigazione2 != "") {
                        path_navigazione2 = path_navigazione2 + " / ";
                    }

                    path_navigazione2 = path_navigazione2 + window.nameFoldersNavigation[x2];

                }

            }

            if (this.getWindowMobile() == 1) { // mobile

                if (window.nameFoldersNavigation.length <= 4) {

                    for (var x5 = 0; x5 < window.nameFoldersNavigation.length; x5++) {

                        if (path_navigazione2 != "") {
                            path_navigazione2 = path_navigazione2 + " / ";
                        }

                        path_navigazione2 = path_navigazione2 + window.nameFoldersNavigation[x5];

                    }


                }



                if (window.nameFoldersNavigation.length > 3) {

                    path_navigazione2 = "..";

                    var x_start2 = 0;
                    var len_array2 = window.nameFoldersNavigation.length;

                    if (len_array2 >= 4 && len_array2 <= 6) {
                        x_start2 = 3;
                    }

                    if (len_array2 > 6 && len_array2 <= 8) {
                        x_start2 = 4;
                    }

                    if (len_array2 > 8 && len_array2 <= 10) {
                        x_start2 = 5;
                    }



                    for (var x6 = x_start2; x6 < window.nameFoldersNavigation.length; x6++) {

                        if (path_navigazione2 != "") {
                            path_navigazione2 = path_navigazione2 + " / ";
                        }

                        path_navigazione2 = path_navigazione2 + window.nameFoldersNavigation[x6];

                    }

                }





            }




            $(".label_url").html(path_navigazione2);




            console.log("FOLDER ID: ", window.id);

            if (window.id != "#") {
                window.curpage = 0;
                window.askFiles = 1;
            }
            else {
                window.curpage = undefined;
                window.askFiles = 1;

                $("#backHeader").hide();

            }

            window.app = "doxweb";

            console.log("FOLDER CURPAGE: ", window.curpage);
            console.log("FOLDER ASKFILES: ", window.askFiles);



            setTimeout(() => {

                window.table.ajax.reload();

                that.hideShowButtons(false);


            }, 200);



        },

        backToRoot: function () {

            var that = this;

            window.updateFolderSize = 0;

            window.p_worker = window.$cookies.get("doxweb_p_worker");
            window.askFiles = window.$cookies.get("doxweb_askFiles");
            window.folder_level = window.$cookies.get("doxweb_folder_level");
            window.id = window.$cookies.get("doxweb_id");
            window.login = window.$cookies.get("doxweb_login");
            window.useremail = window.$cookies.get("doxweb_useremail");
            window.a = window.$cookies.get("doxweb_a");
            window.b = window.$cookies.get("doxweb_b");
            window.app = window.$cookies.get("doxweb_app");
            window.curpage = undefined;

            window.idFoldersNavigation = [];
            window.nameFoldersNavigation = [];
            window.fullPathFoldersNavigation = [];
            window.infoFolderParent = [];

            console.log("ARRAY 1: ", window.idFoldersNavigation);
            console.log("ARRAY 2: ", window.nameFoldersNavigation);
            console.log("ARRAY 3: ", window.fullPathFoldersNavigation);
            console.log("ARRAY 4: ", window.infoFolderParent);

            window.lastFolderSel = "/home/lcservice/s3/users/admin/aziende/";
            window.fullPathFoldersNavigation.push(window.lastFolderSel);

            $(".label_url").html("");

            $("#backHeader").hide();

            //  that.$root.$children[0].showProgress = true;


            setTimeout(() => {

                window.table.ajax.reload();

                that.hideShowButtons(false);


            }, 200);


        },

        btnConfirmGroupOrMail: function () {

            var errore = "";


            if (this.valueSelected == "E") {

                if (this.mailSel == "") {

                    errore = "E-Mail required";

                }


            }


            if (this.valueSelected == "E") {

                if (errore == "") {


                    if (!this.validateEMail(this.mailSel)) {

                        errore = "E-Mail not valid";

                    }



                }



            }


            if (this.valueSelected == "G") {

                if (this.selGruppo == "") {

                    errore = "Group required";

                }


            }

            if (errore == "") {

                if (this.valueSelected == "E") {


                    this.loadPermissionsMail();


                }

                if (this.valueSelected == "G") {


                    this.loadPermissionsGroup();


                }

            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });

            }




        },


        enableDisableSection: function () {

            $(".btn-confirm-permissions").hide();

            console.log("CHANGE: ", this.valueSelected);

            if (this.valueSelected == "G") {

                this.mailSel = "";
                this.viewMail = false;
                this.selGruppo = "";
                this.viewGroups = true;
                this.viewPermissions = false;
                this.viewButtonConfirm = true;
                this.viewSpace = false;
                this.viewSpaceGroup = true;

            }

            if (this.valueSelected == "E") {

                this.selGruppo = "";
                this.viewGroups = false;
                this.mailSel = "";
                this.viewMail = true;
                this.viewPermissions = false;
                this.viewButtonConfirm = true;
                this.viewSpace = true;
                this.viewSpaceGroup = false;

            }

        },

        chgRadio: function () {

            this.enableDisableSection();

        },


        manageClickGruppi: async function (gruppo) {

            console.log("AZI SEL: ", gruppo);

            this.sheetGruppi = false;

            this.selGruppo = gruppo.idresources_group_name;

            this.viewPermissions = false;
            this.viewSpaceGroup = true;

        },


        btnCancelPermissions: function () {

            this.$modal.hide('popupPermissions');

        },

        btnConfirmPermissions: async function () {


            var that = this;

            that.$modal.hide('popupPermissions');


            var dest = "";
            var group_id = "";
            var lastSelection = "";

            if (that.valueSelected == "G") // gruppo
            {
                group_id = that.selGruppo;
                lastSelection = that.selGruppo;
            }

            if (that.valueSelected == "E") // email
            {

                dest = that.mailSel;
                lastSelection = that.mailSel;
            }


            var login = atob(window.$cookies.get("token"));

            var v_id = "";
            var v_text = "";
            var v_icon = true;
            var v_folder_file = "";
            var val = 0;
            var field = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {


                    if (v_id != "") {
                        v_id = v_id + ",";
                    }
                    v_id = v_id + $(this).attr("data-id");

                    v_text = $(this).attr("name-element");

                    v_folder_file = $(this).attr("folder_file");

                }

            });


            if (v_id == "") { // sono dentro ad una folder

                var v_folder_parent = window.infoFolderParent[window.infoFolderParent.length - 1];

                console.log("FOLDER PARENT: ", v_folder_parent);

                v_folder_file = v_folder_parent[0]["folder_file"];

                v_id = v_folder_parent[0]["data_id"];

                v_text = v_folder_parent[0]["name_element"];

            }





            var nodeOriginalArray = [{ folder_file: v_folder_file }];

            var nodeArray = [{ id: v_id, text: v_text, icon: v_icon, original: nodeOriginalArray }];

            var node = JSON.stringify(nodeArray);


            field = "p_enter";



            val = 1;

            console.log("DEST: ", dest);
            console.log("GROUP_ID: ", group_id);
            console.log("LOGIN: ", login);
            console.log("LASTSELECTION: ", lastSelection);
            console.log("NODE: ", node);
            console.log("FIELD: ", field);
            console.log("VAL: ", val);



            var v_p_enter = 0;
            if ($('#p_enter').prop('checked')) {
                v_p_enter = 1;
            }


            var v_p_show = 0;
            if ($('#p_show').prop('checked')) {
                v_p_show = 1;
            }

            var v_p_remove_file = 0;
            if ($('#p_remove_file').prop('checked')) {
                v_p_remove_file = 1;
            }


            var v_p_rename_file_folder = 0;
            if ($('#p_rename_file_folder').prop('checked')) {
                v_p_rename_file_folder = 1;
            }

            var v_p_remove_folder = 0;
            if ($('#p_remove_folder').prop('checked')) {
                v_p_remove_folder = 1;
            }


            var v_p_set_password = 0;


            var v_p_foldersignature = 0;
            if ($('#p_foldersignature').prop('checked')) {
                v_p_foldersignature = 1;
            }

            var v_p_upload = 0;
            if ($('#p_upload').prop('checked')) {
                v_p_upload = 1;
            }


            var v_p_worker = 0;
            if ($('#p_worker').prop('checked')) {
                v_p_worker = 1;
            }

            var v_p_email = 0;
            if ($('#p_email').prop('checked')) {
                v_p_email = 1;
            }


            var v_p_copy = 0;
            if ($('#p_copy').prop('checked')) {
                v_p_copy = 1;
            }


            var v_p_otp_delete_file = 0;
            if ($('#p_otp_delete_file').prop('checked')) {
                v_p_otp_delete_file = 1;
            }


            var v_p_otp_delete_folder = 0;
            if ($('#p_otp_delete_folder').prop('checked')) {
                v_p_otp_delete_folder = 1;
            }

            var v_p_create_folder = 0;
            if ($('#p_create_folder').prop('checked')) {
                v_p_create_folder = 1;
            }

            var v_p_edit_permissions = 0;
            if ($('#p_edit_permissions').prop('checked')) {
                v_p_edit_permissions = 1;
            }

            var v_p_download_and_delete_file = 0;
            if ($('#p_download_and_delete_file').prop('checked')) {
                v_p_download_and_delete_file = 1;
            }

            var v_p_zip_folder = 0;
            if ($('#p_zip_folder').prop('checked')) {
                v_p_zip_folder = 1;
            }

            var v_p_send_file_by_mail = 0;
            if ($('#p_send_file_by_mail').prop('checked')) {
                v_p_send_file_by_mail = 1;
            }

            var v_p_expired_file = 0;
            if ($('#p_expired_file').prop('checked')) {
                v_p_expired_file = 1;
            }



            console.log("FLAG: ",);



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.savePermissions(
                dest,
                group_id,
                login,
                node,
                lastSelection,
                "p_enter",
                1,
                v_p_enter,
                v_p_show,
                v_p_remove_file,
                v_p_rename_file_folder,
                v_p_remove_folder,
                v_p_set_password,
                v_p_foldersignature,
                v_p_upload,
                v_p_worker,
                v_p_email,
                v_p_copy,
                v_p_otp_delete_file,
                v_p_otp_delete_folder,
                v_p_create_folder,
                v_p_edit_permissions,
                v_p_download_and_delete_file,
                v_p_zip_folder,
                v_p_send_file_by_mail,
                v_p_expired_file
            ).then(async (res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from savePermissions", res);

                that.$swal({
                    icon: "success",
                    text: "Data saved successfully",
                    showConfirmButton: false,
                    timer: 2000
                });



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );







        },






        getGroups: async function () {

            var that = this;

            var response = await apidoxweb.getGroups(
                atob(window.$cookies.get("token"))
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getGroups", res);

                that.gruppi = res.data.data.myGroups;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },



        updatePermissions: async function (v_name, v_value) {

            var that = this;

            var dest = "";
            var group_id = "";
            var lastSelection = "";

            if (that.valueSelected == "G") // gruppo
            {
                group_id = that.selGruppo;
                lastSelection = that.selGruppo;
            }

            if (that.valueSelected == "E") // email
            {

                dest = that.mailSel;
                lastSelection = that.mailSel;
            }


            var login = atob(window.$cookies.get("token"));

            var v_id = "";
            var v_text = "";
            var v_icon = true;
            var v_folder_file = "";
            var val = 0;
            var field = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {


                    if (v_id != "") {
                        v_id = v_id + ",";
                    }
                    v_id = v_id + $(this).attr("data-id");

                    v_text = $(this).attr("name-element");

                    v_folder_file = $(this).attr("folder_file");

                }

            });

            var nodeOriginalArray = [{ folder_file: v_folder_file }];

            var nodeArray = [{ id: v_id, text: v_text, icon: v_icon, original: nodeOriginalArray }];

            var node = JSON.stringify(nodeArray);


            field = v_name;

            if (v_value) {
                val = 1;
            }


            console.log("DEST: ", dest);
            console.log("GROUP_ID: ", group_id);
            console.log("LOGIN: ", login);
            console.log("LASTSELECTION: ", lastSelection);
            console.log("NODE: ", node);
            console.log("FIELD: ", field);
            console.log("VAL: ", val);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.updatePermissions(
                dest,
                group_id,
                login,
                node,
                lastSelection,
                field,
                val
            ).then(async (res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from updatePermissions", res);




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );






        },









        loadPermissionsGroup: async function () {

            var that = this;

            var dest = "";
            var group_id = that.selGruppo;
            var login = atob(window.$cookies.get("token"));
            var lastSelection = that.selGruppo;

            var v_id = "";
            var v_text = "";
            var v_icon = true;
            var v_folder_file = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {


                    if (v_id != "") {
                        v_id = v_id + ",";
                    }
                    v_id = v_id + $(this).attr("data-id");

                    v_text = $(this).attr("name-element");

                    v_folder_file = $(this).attr("folder_file");

                }

            });

            if (v_folder_file == "") {
                v_folder_file = window.lastFolderSel;

                v_id = window.infoFolderParent[window.infoFolderParent.length - 1][0]["data_id"];
                v_text = window.infoFolderParent[window.infoFolderParent.length - 1][0]["name_element"];

                console.log("v_FOLDER_FILE: ", v_folder_file);
                console.log("V_ID: ", v_id);
                console.log("V_TEXT: ", v_text);

            }





            var nodeOriginalArray = [{ folder_file: v_folder_file }];


            var nodeArray = [{ id: v_id, text: v_text, icon: v_icon, original: nodeOriginalArray }];


            var node = JSON.stringify(nodeArray);

            console.log("DEST: ", dest);
            console.log("GROUP_ID: ", group_id);
            console.log("LOGIN: ", login);
            console.log("LASTSELECTION: ", lastSelection);
            console.log("NODE: ", node);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.loadPermissions(
                dest,
                group_id,
                login,
                node,
                lastSelection
            ).then(async (res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from loadPermissions", res);


                that.viewSpaceGroup = false;
                that.viewPermissions = true;


                setTimeout(() => {

                    $(".btn-confirm-permissions").show();


                    $("#p_enter").prop("checked", false);
                    $("#p_show").prop("checked", false);
                    $("#p_remove_file").prop("checked", false);
                    $("#p_rename_file_folder").prop("checked", false);
                    $("#p_remove_folder").prop("checked", false);
                    $("#p_set_password").prop("checked", false);
                    $("#p_foldersignature").prop("checked", false);
                    $("#p_upload").prop("checked", false);
                    $("#p_worker").prop("checked", false);
                    $("#p_email").prop("checked", false);
                    $("#p_copy").prop("checked", false);
                    $("#p_otp_delete_file").prop("checked", false);
                    $("#p_otp_delete_folder").prop("checked", false);
                    $("#p_create_folder").prop("checked", false);
                    $("#p_edit_permissions").prop("checked", false);
                    $("#p_download_and_delete_file").prop("checked", false);
                    $("#p_zip_folder").prop("checked", false);
                    $("#p_send_file_by_mail").prop("checked", false);
                    $("#p_expired_file").prop("checked", false);

                    if (res.data.singleShare != null) {

                        if (res.data.singleShare.p_enter == 1) {

                            $("#p_enter").prop("checked", true);

                        }

                        if (res.data.singleShare.p_show == 1) {

                            $("#p_show").prop("checked", true);

                        }

                        if (res.data.singleShare.p_remove_file == 1) {

                            $("#p_remove_file").prop("checked", true);

                        }

                        if (res.data.singleShare.p_rename_file_folder == 1) {

                            $("#p_rename_file_folder").prop("checked", true);

                        }


                        if (res.data.singleShare.p_remove_folder == 1) {

                            $("#p_remove_folder").prop("checked", true);

                        }


                        if (res.data.singleShare.p_set_password == 1) {

                            $("#p_set_password").prop("checked", true);

                        }

                        if (res.data.singleShare.p_foldersignature == 1) {

                            $("#p_foldersignature").prop("checked", true);

                        }


                        if (res.data.singleShare.p_upload == 1) {

                            $("#p_upload").prop("checked", true);

                        }

                        if (res.data.singleShare.p_worker == 1) {

                            $("#p_worker").prop("checked", true);

                        }

                        if (res.data.singleShare.p_email == 1) {

                            $("#p_email").prop("checked", true);

                        }

                        if (res.data.singleShare.p_copy == 1) {

                            $("#p_copy").prop("checked", true);

                        }

                        if (res.data.singleShare.p_otp_delete_file == 1) {

                            $("#p_otp_delete_file").prop("checked", true);

                        }

                        if (res.data.singleShare.p_otp_delete_folder == 1) {

                            $("#p_otp_delete_folder").prop("checked", true);

                        }

                        if (res.data.singleShare.p_create_folder == 1) {

                            $("#p_create_folder").prop("checked", true);

                        }


                        if (res.data.singleShare.p_edit_permissions == 1) {

                            $("#p_edit_permissions").prop("checked", true);

                        }

                        if (res.data.singleShare.p_download_and_delete_file == 1) {

                            $("#p_download_and_delete_file").prop("checked", true);

                        }

                        if (res.data.singleShare.p_zip_folder == 1) {

                            $("#p_zip_folder").prop("checked", true);

                        }

                        if (res.data.singleShare.p_send_file_by_mail == 1) {

                            $("#p_send_file_by_mail").prop("checked", true);

                        }

                        if (res.data.singleShare.p_expired_file == 1) {

                            $("#p_expired_file").prop("checked", true);

                        }

                    }


                }, 200);



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );






        },



        loadPermissionsMail: async function () {

            var that = this;

            window.oldSetPassword = 0;

            var dest = that.mailSel;
            var group_id = "";
            var login = atob(window.$cookies.get("token"));
            var lastSelection = that.mailSel;

            var v_id = "";
            var v_text = "";
            var v_icon = true;
            var v_folder_file = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {


                    if (v_id != "") {
                        v_id = v_id + ",";
                    }
                    v_id = v_id + $(this).attr("data-id");

                    v_text = $(this).attr("name-element");

                    v_folder_file = $(this).attr("folder_file");

                }

            });


            if (v_folder_file == "") {
                v_folder_file = window.lastFolderSel;

                v_id = window.infoFolderParent[window.infoFolderParent.length - 1][0]["data_id"];
                v_text = window.infoFolderParent[window.infoFolderParent.length - 1][0]["name_element"];

                console.log("v_FOLDER_FILE: ", v_folder_file);
                console.log("V_ID: ", v_id);
                console.log("V_TEXT: ", v_text);

            }






            var nodeOriginalArray = [{ folder_file: v_folder_file }];


            var nodeArray = [{ id: v_id, text: v_text, icon: v_icon, original: nodeOriginalArray }];


            var node = JSON.stringify(nodeArray);

            console.log("DEST: ", dest);
            console.log("GROUP_ID: ", group_id);
            console.log("LOGIN: ", login);
            console.log("LASTSELECTION: ", lastSelection);
            console.log("NODE: ", node);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.loadPermissions(
                dest,
                group_id,
                login,
                node,
                lastSelection
            ).then(async (res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from loadPermissions", res);

                that.viewSpace = false;
                that.viewPermissions = true;


                setTimeout(() => {

                    $(".btn-confirm-permissions").show();


                    $("#p_enter").prop("checked", false);
                    $("#p_show").prop("checked", false);
                    $("#p_remove_file").prop("checked", false);
                    $("#p_rename_file_folder").prop("checked", false);
                    $("#p_remove_folder").prop("checked", false);
                    $("#p_set_password").prop("checked", false);
                    $("#p_foldersignature").prop("checked", false);
                    $("#p_upload").prop("checked", false);
                    $("#p_worker").prop("checked", false);
                    $("#p_email").prop("checked", false);
                    $("#p_copy").prop("checked", false);
                    $("#p_otp_delete_file").prop("checked", false);
                    $("#p_otp_delete_folder").prop("checked", false);
                    $("#p_create_folder").prop("checked", false);
                    $("#p_edit_permissions").prop("checked", false);
                    $("#p_download_and_delete_file").prop("checked", false);
                    $("#p_zip_folder").prop("checked", false);
                    $("#p_send_file_by_mail").prop("checked", false);
                    $("#p_expired_file").prop("checked", false);

                    if (res.data.singleShare != null) {

                        window.oldSetPassword = res.data.singleShare.p_set_password;

                        if (window.oldSetPassword == null) {
                            window.oldSetPassword = 0;
                        }

                        console.log("ok singleshare");

                        if (res.data.singleShare.p_enter == 1) {

                            console.log("ok p_enter");


                            $("#p_enter").prop("checked", true);

                        }

                        if (res.data.singleShare.p_show == 1) {

                            $("#p_show").prop("checked", true);

                        }

                        if (res.data.singleShare.p_remove_file == 1) {

                            $("#p_remove_file").prop("checked", true);

                        }

                        if (res.data.singleShare.p_rename_file_folder == 1) {

                            $("#p_rename_file_folder").prop("checked", true);

                        }


                        if (res.data.singleShare.p_remove_folder == 1) {

                            $("#p_remove_folder").prop("checked", true);

                        }


                        if (res.data.singleShare.p_set_password == 1) {

                            $("#p_set_password").prop("checked", true);

                        }

                        if (res.data.singleShare.p_foldersignature == 1) {

                            $("#p_foldersignature").prop("checked", true);

                        }


                        if (res.data.singleShare.p_upload == 1) {

                            $("#p_upload").prop("checked", true);

                        }

                        if (res.data.singleShare.p_worker == 1) {

                            $("#p_worker").prop("checked", true);

                        }

                        if (res.data.singleShare.p_email == 1) {

                            $("#p_email").prop("checked", true);

                        }

                        if (res.data.singleShare.p_copy == 1) {

                            $("#p_copy").prop("checked", true);

                        }

                        if (res.data.singleShare.p_otp_delete_file == 1) {

                            $("#p_otp_delete_file").prop("checked", true);

                        }

                        if (res.data.singleShare.p_otp_delete_folder == 1) {

                            $("#p_otp_delete_folder").prop("checked", true);

                        }

                        if (res.data.singleShare.p_create_folder == 1) {

                            $("#p_create_folder").prop("checked", true);

                        }

                        if (res.data.singleShare.p_edit_permissions == 1) {

                            $("#p_edit_permissions").prop("checked", true);

                        }

                        if (res.data.singleShare.p_download_and_delete_file == 1) {

                            $("#p_download_and_delete_file").prop("checked", true);

                        }

                        if (res.data.singleShare.p_zip_folder == 1) {

                            $("#p_zip_folder").prop("checked", true);

                        }

                        if (res.data.singleShare.p_send_file_by_mail == 1) {

                            $("#p_send_file_by_mail").prop("checked", true);

                        }


                        if (res.data.singleShare.p_expired_file == 1) {

                            $("#p_expired_file").prop("checked", true);

                        }



                    }


                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );






        },






        loadPermissions: async function () {

            var that = this;

            that.valueSelected = "";
            that.selGruppo = "";
            that.mailSel = "";
            that.viewGroups = false;
            that.viewMail = false;
            that.viewPermissions = false;
            that.viewButtonConfirm = false;
            that.viewSpace = false;

            var dest = "";
            var group_id = "";
            var login = atob(window.$cookies.get("token"));
            var lastSelection = "";

            var v_id = "";
            var v_text = "";
            var v_icon = true;
            var v_folder_file = "";

            var is_folder_sel = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {


                    if (v_id != "") {
                        v_id = v_id + ",";
                    }
                    v_id = v_id + $(this).attr("data-id");

                    v_text = $(this).attr("name-element");

                    v_folder_file = $(this).attr("folder_file");

                    is_folder_sel = 1;

                }

            });


            if (v_folder_file == "") {
                v_folder_file = window.lastFolderSel;

                v_id = window.infoFolderParent[window.infoFolderParent.length - 1][0]["data_id"];
                v_text = window.infoFolderParent[window.infoFolderParent.length - 1][0]["name_element"];

                console.log("v_FOLDER_FILE: ", v_folder_file);
                console.log("V_ID: ", v_id);
                console.log("V_TEXT: ", v_text);

            }


            that.idFolderSelPermissions = v_id;


            var nodeOriginalArray = [{ folder_file: v_folder_file }];


            var nodeArray = [{ id: v_id, text: v_text, icon: v_icon, original: nodeOriginalArray }];


            var node = JSON.stringify(nodeArray);

            console.log("DEST: ", dest);
            console.log("GROUP_ID: ", group_id);
            console.log("LOGIN: ", login);
            console.log("LASTSELECTION: ", lastSelection);
            console.log("NODE: ", node);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.loadPermissions(
                dest,
                group_id,
                login,
                node,
                lastSelection
            ).then(async (res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from loadPermissions", res);



                that.email_shared = [];


                if (res.data.multiShare != null) {

                    for (var x = 0; x < res.data.multiShare.length; x++) {


                        if (res.data.multiShare[x].email != null) {

                            that.email_shared.push({
                                email: res.data.multiShare[x].email
                            });

                        }

                    }


                }

                console.log("MAIL MULTISHARE: ", that.email_shared);



                that.groups_shared = [];

                if (res.data.groupsShareForDelete != null) {

                    for (var xx = 0; xx < res.data.groupsShareForDelete.length; xx++) {

                        that.groups_shared.push({
                            id: res.data.groupsShareForDelete[xx].id,
                            name: res.data.groupsShareForDelete[xx].name
                        });

                    }


                }




                console.log("GROUP MULTISHARE: ", that.groups_shared);





                var v_path_navigation = that.getPathNavigazionePermissions();


                if (is_folder_sel == 1) {

                    if (v_path_navigation != "") {
                        v_path_navigation = v_path_navigation + " / ";
                    }

                    v_path_navigation = v_path_navigation + v_text;

                }


                console.log("V_PATH_NAVIGATION: ", v_path_navigation);

                that.eleSelectedPermissions = v_path_navigation;


                setTimeout(() => {

                    $(".contpopuppermissions").show();

                    that.$modal.show('popupPermissions');


                    setTimeout(() => {

                        $(".btn-confirm-permissions").hide();

                    }, 100);


                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );






        },





        deleteRow: async function () {

            var that = this;

            var ids = "";
            var is_file = "";
            var name_element = "";
            var tot = 0;
            var tot_file = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");

                    is_file = $(this).attr("is-file");

                    if (is_file == 1) {

                        tot_file = tot_file + 1;
                    }

                    name_element = $(this).attr("name-element");

                }

            });



            var txt_msg = "";

            if (tot == 1) {

                if (is_file == 0) {
                    txt_msg = "Are you sure you want to delete the " + name_element + " folder ?";
                }

                if (is_file == 1) {
                    txt_msg = "Are you sure you want to delete the " + name_element + " file ?";
                }

            }

            if (tot > 1) {

                txt_msg = "Are you sure you want to delete selected files ?";


            }


            var htmlCancelButton = "<img title='Cancel' style='width:30px' src='" + this.getUrlDomain("public/_lib/img/btn_cancel3_doxweb.png") + "' />";

            var htmlConfirmButton = "<img title='Confirm' style='width:30px' src='" + this.getUrlDomain("public/_lib/img/btn_confirm3_doxweb.png") + "' />";


            that.$swal
                .fire({
                    title: "Remove",
                    html: txt_msg,
                    icon: "warning",
                    iconColor: "#8ec8a3",
                    showCancelButton: true,
                    confirmButtonText: htmlConfirmButton,
                    cancelButtonText: htmlCancelButton,
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {


                        if (tot == 1) {

                            if (is_file == 0) // elimino folder
                            {


                                console.log("FULL PATH NAV: ", window.fullPathFoldersNavigation);

                                var folderContain = window.fullPathFoldersNavigation[window.fullPathFoldersNavigation.length - 1];

                                var id = "";
                                var folder_file = "";
                                var action = "";
                                var login = "";
                                var a = "";
                                var b = "";
                                var app = "";

                                $(".val_status").each(function (index) {

                                    console.log(index);

                                    if ($(this).prop("checked")) {

                                        if (id != "") {
                                            id = id + ",";
                                        }
                                        id = id + $(this).attr("data-id");

                                        folder_file = $(this).attr("folder_file");

                                    }

                                });

                                id = btoa(id);
                                folder_file = btoa(folder_file);

                                action = "remove";
                                app = "doxweb";

                                var v_a = window.$cookies.get("a");

                                if (v_a == "S") {
                                    login = "admin";
                                }
                                else {

                                    login = atob(window.$cookies.get("token"));

                                }

                                a = window.$cookies.get("token");
                                b = window.$cookies.get("token_system");

                                console.log("APP:", app);
                                console.log("FOLDER_FILE:", folder_file);
                                console.log("ACTION:", action);
                                console.log("ID:", id);
                                console.log("LOGIN:", login);
                                console.log("a:", a);
                                console.log("b:", b);





                                that.showSpinner = true;
                                that.$root.$children[0].showProgress = true;

                                var response = await apidoxweb.deleteFolder(
                                    app,
                                    folder_file,
                                    action,
                                    id,
                                    login,
                                    a,
                                    b,
                                    folderContain
                                ).then((res) => {

                                    that.$root.$children[0].showProgress = false;
                                    console.log("res from deleteFolder", res);

                                    that.$swal({
                                        icon: "success",
                                        text: "Deletion successful",
                                        showConfirmButton: false,
                                        timer: 2000
                                    });

                                    setTimeout(() => {

                                        that.dialogMsg = false;
                                        that.setupButtons();

                                        window.app = "doxweb";

                                        window.table.ajax.reload();

                                        that.hideShowButtons(false);


                                    }, 200);

                                }).catch(err => {
                                    that.$root.$children[0].showProgress = false;
                                    console.log(err);
                                    var msg = err.response.data.Error;

                                    that.$swal({
                                        icon: "error",
                                        text: msg,
                                        showConfirmButton: false,
                                        timer: 8000
                                    });
                                    console.log("Errori", "Non è stato possibile eliminare i dati");
                                    console.log("response", response);

                                }

                                );


                            }


                            if (is_file == 1) // elimino file
                            {

                                console.log("FULL PATH NAV: ", window.fullPathFoldersNavigation);

                                var folderContain2 = window.fullPathFoldersNavigation[window.fullPathFoldersNavigation.length - 1];


                                var action2 = "";
                                var key2 = "";
                                var keys2 = "";

                                $(".val_status").each(function (index) {

                                    console.log(index);

                                    if ($(this).prop("checked")) {

                                        key2 = $(this).attr("file-path");

                                        if (keys2 != "") {
                                            keys2 = keys2 + ",";
                                        }
                                        keys2 = keys2 + key2;

                                    }

                                });


                                action2 = "remove";


                                console.log("ACTION:", action2);
                                console.log("KEY:", key2);
                                console.log("KEYS:", keys2);


                                that.showSpinner = true;
                                that.$root.$children[0].showProgress = true;

                                var response2 = await apidoxweb.deleteFile(
                                    action2,
                                    key2,
                                    keys2,
                                    folderContain2
                                ).then((res2) => {

                                    that.$root.$children[0].showProgress = false;
                                    console.log("res from deleteFile", res2);

                                    that.$swal({
                                        icon: "success",
                                        text: "Deletion successful",
                                        showConfirmButton: false,
                                        timer: 2000
                                    });

                                    setTimeout(() => {

                                        that.dialogMsg = false;
                                        that.setupButtons();



                                        window.table.ajax.reload();

                                        that.hideShowButtons(false);


                                    }, 200);

                                }).catch(err2 => {
                                    that.$root.$children[0].showProgress = false;
                                    console.log(err2);
                                    var msg2 = err2.response.data.Error;

                                    that.$swal({
                                        icon: "error",
                                        text: msg2,
                                        showConfirmButton: false,
                                        timer: 8000
                                    });
                                    console.log("Errori", "Non è stato possibile eliminare i dati");
                                    console.log("response", response2);

                                }

                                );





                            }


                        }

                        if (tot > 1) {

                            var action3 = "";
                            var key3 = "";
                            var keys3 = "";

                            $(".val_status").each(function (index) {

                                console.log(index);

                                if ($(this).prop("checked")) {

                                    key3 = $(this).attr("file-path");

                                    if (keys3 != "") {
                                        keys3 = keys3 + ",";
                                    }
                                    keys3 = keys3 + key3;

                                }

                            });


                            action3 = "remove";


                            console.log("ACTION:", action3);
                            console.log("KEY:", key3);
                            console.log("KEYS:", keys3);


                            that.showSpinner = true;
                            that.$root.$children[0].showProgress = true;

                            var response3 = await apidoxweb.deleteFile(
                                action3,
                                key3,
                                keys3
                            ).then((res3) => {

                                that.$root.$children[0].showProgress = false;
                                console.log("res from deleteFile", res3);

                                that.$swal({
                                    icon: "success",
                                    text: "Deletion successful",
                                    showConfirmButton: false,
                                    timer: 2000
                                });

                                setTimeout(() => {

                                    that.dialogMsg = false;
                                    that.setupButtons();



                                    window.table.ajax.reload();

                                    that.hideShowButtons(false);


                                }, 200);

                            }).catch(err3 => {
                                that.$root.$children[0].showProgress = false;
                                console.log(err3);
                                var msg3 = err3.response.data.Error;

                                that.$swal({
                                    icon: "error",
                                    text: msg3,
                                    showConfirmButton: false,
                                    timer: 8000
                                });
                                console.log("Errori", "Non è stato possibile eliminare i dati");
                                console.log("response", response3);

                            }

                            );


                        }








                    }

                });

        },


        btnCancelOtpDelete: function () {

            this.$modal.hide('popupOtpDelete');


        },

        btnConfirmOtpDelete: function () {


            if (this.valueOtpDelete != "") {

                console.log("OTP INP: ", this.valueOtpDelete);
                console.log("ENC OTP INP: ", btoa(this.valueOtpDelete));

                if (window.myVal == btoa(this.valueOtpDelete)) {

                    this.$modal.hide('popupOtpDelete');

                    this.deleteRow();

                }
                else {

                    this.$swal({
                        icon: "error",
                        text: "Valore OTP non corretto",
                        showConfirmButton: false,
                        timer: 2000
                    });


                }



            }
            else {

                this.$swal({
                    icon: "error",
                    text: "Specificare un  valore dell'OTP",
                    showConfirmButton: false,
                    timer: 2000
                });

            }



        },




        getOtpDelete: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            var ids = "";
            var is_file = "";
            var name_element = "";
            var tot = 0;
            var tot_files = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");

                    is_file = $(this).attr("is-file");

                    if (is_file == 1) {
                        tot_files = tot_files + 1;
                    }

                    name_element = $(this).attr("name-element");

                }

            });


            var desc_op = "";

            if (tot == 1) {

                desc_op = "elimina_folder";
                if (is_file == 1) {
                    desc_op = "elimina_file";
                }

            }

            if (tot > 1) {

                desc_op = "elimina_files";


            }

            console.log("IDS SEL:", ids);


            window.myVal = "";

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiotp.generateOTPAndSendMailDox(
                v_token,
                "doxweb",
                desc_op,
                ids,
                name_element
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from generateOTPAndSendMailDox", res);

                window.myVal = res.data.Result;

                that.valueOtpDelete = "";

                setTimeout(() => {

                    $(".contpopupotpdelete").show();

                    this.$modal.show('popupOtpDelete');

                    setTimeout(() => {

                        $("#fldValueOtpDelete").focus();

                    }, 300);


                }, 200);





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );






        },




        getOtpCud: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            var ids = "";
            var name_element = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {


                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");

                    name_element = $(this).attr("name-element");

                }

            });


            window.myVal = "";

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiotp.generateOTPAndSendMailDox(
                v_token,
                "doxweb",
                "download_cud",
                ids,
                name_element
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from generateOTPAndSendMailDox", res);

                window.myVal = res.data.Result;

                that.valueOtpCud = "";

                setTimeout(() => {

                    $(".contpopupotpcud").show();

                    this.$modal.show('popupOtpCud');

                    setTimeout(() => {

                        $("#fldValueOtpCud").focus();

                    }, 300);


                }, 200);





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );






        },






        openPopupUpload: async function () {


            console.log("ARRAY FOLDER PARENT: ", window.infoFolderParent);



            var that = this;


            that.nomeFilePresente = "";
            that.dimFilePresente = "";
            that.dimKbFilePresente = 0;



            var errore = "";


            if (errore == "") {

                var v_token = window.$cookies.get("token");


                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                var response3 = await apidoxweb.getInfoUploadFile(
                    v_token
                ).then((res2) => {

                    console.log(response3);

                    that.$root.$children[0].showProgress = false;
                    console.log("res from getInfoUploadFile", res2);


                    that.dimMinKbFile = res2.data.MinFileSize;

                    that.dimMaxKbFile = res2.data.MaxFileSize;

                    that.userMail = res2.data.UserEMail;

                    $(".contpopupuploadfile").show();

                    this.$modal.show('popupUploadFile');


                    setTimeout(() => {


                        var oggDrop = $('.dropify').dropify({
                            messages: {
                                default: 'Drag',
                                replace: /* s */ '',
                                remove: 'Rimuovi',
                                error: 'File troppo grande'
                            }
                        });

                        $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
                        $(".dropify-render").append("<img>");

                        oggDrop.on('dropify.afterClear', function (event, element) {

                            console.log(event, element);

                            that.removeUploadFile = 1;

                            that.nomeFilePresente = "";
                            that.dimFilePresente = "";
                            that.dimKbFilePresente = 0;

                        });



                        $('#input-file-attach').on('change', function () {

                            that.removeUploadFile = 0;

                            var v_file = $("#input-file-attach")[0].files[0];

                            console.log("FILE: ", v_file);

                            that.nomeFilePresente = v_file.name;

                            var v_size = v_file.size;

                            that.dimKbFilePresente = v_size;

                            var str_size = "0 Kb";


                            if (v_size > 0) {

                                var v_division = v_size / 1000;

                                str_size = v_division.toFixed(1) + " Kb";


                            }



                            that.dimFilePresente = str_size;



                        });



                        console.log("FILE PRESENT: ", res2.data.NomeFile);

                        if (res2.data.NomeFile != null) {

                            if (res2.data.NomeFile != "") {

                                var elements = res2.data.NomeFile.split(".");

                                console.log("ELEMENTS: ", elements);

                                var v_ext = elements[elements.length - 1];

                                console.log("EXT: ", v_ext);

                                $(".rowPrevFile").find('.dropify-render').append("<i class='dropify-font-file'></i>");
                                $(".rowPrevFile").find('.dropify-render').append("<span class='dropify-extension'>" + v_ext + "</span>");
                                $(".rowPrevFile").find(".dropify-wrapper").addClass("has-preview");
                                $(".rowPrevFile").find(".dropify-preview").show("slow");



                                that.nomeFilePresente = res2.data.NomeFile;

                                that.dimKbFilePresente = res2.data.FileSize;


                                var str_size = "0 Kb";

                                var v_size = res2.data.FileSize;

                                if (v_size > 0) {

                                    var v_division = v_size / 1000;

                                    str_size = v_division.toFixed(1) + " Kb";


                                }



                                that.dimFilePresente = str_size;

                            }


                        }








                    }, 300);



                }).catch(err2 => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err2.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }


                );

            }
            else {


                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }




        },

        btnCancelUpload: function () {

            this.$modal.hide('popupUploadFile');

        },





        checkFileMultiExist: async function (v_files) {


            var that = this;

            var v_file_names = "";

            for (var xx5 = 0; xx5 < v_files.length; xx5++) {


                if (v_file_names != "") {
                    v_file_names = v_file_names + ",";
                }

                v_file_names = v_file_names + v_files[xx5].name;

            }


            var folder = window.lastFolderSel;

            console.log("FOLDER_FILE: ", folder);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.checkFileMultiExist(
                folder,
                v_file_names
            ).then((res) => {

                console.log("res from checkFileExist", res);


                if (res.data.result == "OK") // il file non esiste
                {


                    console.log("ok");


                    setTimeout(() => {

                        that.openPopupUploadDocumentsMulti(v_files);

                    }, 200);


                }
                else // esiste chiedo conferma se si vuole sovrascriverlo
                {

                    that.$root.$children[0].showProgress = false;

                    var txt_msg = "The files " + res.data.file_exist + " already exist, do you want to overwrite them ?";

                    var htmlCancelButton = "<img title='Cancel' style='width:30px' src='" + this.getUrlDomain("public/_lib/img/btn_cancel3_doxweb.png") + "' />";

                    var htmlConfirmButton = "<img title='Confirm' style='width:30px' src='" + this.getUrlDomain("public/_lib/img/btn_confirm3_doxweb.png") + "' />";

                    that.$swal
                        .fire({
                            title: "Overwrite",
                            html: txt_msg,
                            icon: "warning",
                            iconColor: "#8ec8a3",
                            showCancelButton: true,
                            confirmButtonText: htmlConfirmButton,
                            cancelButtonText: htmlCancelButton,
                            customClass: {
                                cancelButton: 'order-1',
                                confirmButton: 'order-2',
                            }

                        })
                        .then(async function (result) {

                            if (result.value == true) {



                                console.log("ok");


                                setTimeout(() => {

                                    that.openPopupUploadDocumentsMulti(v_files);

                                }, 200);



                            }

                        });





                }






            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );



        },








        checkFileExist: async function () {


            var that = this;

            var folder = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    folder = $(this).attr("folder_file");

                }

            });

            if (folder == "") {

                folder = window.lastFolderSel;

            }



            var v_file = $("#input-file-attach")[0].files[0].name;


            console.log("FOLDER_FILE: ", folder);
            console.log("FILE: ", v_file);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.checkFileExist(
                folder,
                v_file
            ).then((res) => {

                console.log("res from checkFileExist", res);


                if (res.data.result == "OK") // il file non esiste
                {

                    that.uploadFile();

                }
                else // esiste chiedo conferma se si vuole sovrascriverlo
                {

                    that.$root.$children[0].showProgress = false;

                    var txt_msg = "The file " + v_file + " already exists, do you want to overwrite it ?";

                    var htmlCancelButton = "<img title='Cancel' style='width:30px' src='" + this.getUrlDomain("public/_lib/img/btn_cancel3_doxweb.png") + "' />";

                    var htmlConfirmButton = "<img title='Confirm' style='width:30px' src='" + this.getUrlDomain("public/_lib/img/btn_confirm3_doxweb.png") + "' />";

                    that.$swal
                        .fire({
                            title: "Overwrite",
                            html: txt_msg,
                            icon: "warning",
                            iconColor: "#8ec8a3",
                            showCancelButton: true,
                            confirmButtonText: htmlConfirmButton,
                            cancelButtonText: htmlCancelButton,
                            customClass: {
                                cancelButton: 'order-1',
                                confirmButton: 'order-2',
                            }

                        })
                        .then(async function (result) {

                            if (result.value == true) {



                                console.log("ok");

                                that.uploadFile();




                            }

                        });





                }






            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );



        },

        btnConfirmUpload: function () {

            var errore = "";

            var v_file = $("#input-file-attach")[0];


            if (v_file.files.length == 0) {

                errore = "File required";

            }

            if (errore == "") {

                if (v_file.files.length > 0) {



                    // controllo estensione

                    v_file = v_file.files[0];

                    console.log("FILE 1: ", v_file);

                    var v_elements = v_file.name.split('.');

                    var v_ext_file = v_elements[v_elements.length - 1];

                    console.log("FILE ELEMENTS: ", v_elements);
                    console.log("FILE EXT: ", v_ext_file);


                    var v_ele_file_accept = this.fileAccettati.split(',');

                    console.log("FILE ELEMENTS ACCEPT: ", v_ele_file_accept);

                    var v_find_ext = 0;

                    for (var xx = 0; xx < v_ele_file_accept.length; xx++) {

                        var v_ext_file_tmp = "." + v_ext_file;

                        console.log(v_ext_file_tmp);

                        if (v_ext_file_tmp == v_ele_file_accept[xx]) {
                            v_find_ext = 1;
                        }

                    }

                    if (v_find_ext == 0) {
                        errore = "The accepted file formats are " + this.fileAccettati;
                    }



                    if (errore == "") {

                        // controllo dimensione file

                        if ((this.dimKbFilePresente < this.dimMinKbFile) || (this.dimKbFilePresente > this.dimMaxKbFile)) {


                            var strMin = (this.dimMinKbFile / 1000) + " Kb";

                            var strMax = (this.dimMaxKbFile / 1000000) + " Mb";

                            errore = "The file size must be between " + strMin + " and " + strMax;

                        }


                    }






                }

            }






            if (errore == "") {

                console.log("ok");


                this.checkFileExist();

            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 4000
                });


            }


        },







        btnConfirmUploadOld: function () {

            var errore = "";

            var v_file = $("#input-file-attach")[0];

            if (v_file.files.length == 0) {

                errore = "File required";

            }

            if (errore == "") {

                if (v_file.files.length > 0) {



                    // controllo estensione

                    v_file = v_file.files[0];

                    console.log("FILE 1: ", v_file);

                    var v_elements = v_file.name.split('.');

                    var v_ext_file = v_elements[v_elements.length - 1];

                    console.log("FILE ELEMENTS: ", v_elements);
                    console.log("FILE EXT: ", v_ext_file);


                    var v_ele_file_accept = this.fileAccettati.split(',');

                    console.log("FILE ELEMENTS ACCEPT: ", v_ele_file_accept);

                    var v_find_ext = 0;

                    for (var xx = 0; xx < v_ele_file_accept.length; xx++) {

                        var v_ext_file_tmp = "." + v_ext_file;

                        console.log(v_ext_file_tmp);

                        if (v_ext_file_tmp == v_ele_file_accept[xx]) {
                            v_find_ext = 1;
                        }

                    }

                    if (v_find_ext == 0) {
                        errore = "The accepted file formats are " + this.fileAccettati;
                    }



                    if (errore == "") {

                        // controllo dimensione file

                        if ((this.dimKbFilePresente < this.dimMinKbFile) || (this.dimKbFilePresente > this.dimMaxKbFile)) {


                            var strMin = (this.dimMinKbFile / 1000) + " Kb";

                            var strMax = (this.dimMaxKbFile / 1000000) + " Mb";

                            errore = "The file size must be between " + strMin + " and " + strMax;

                        }


                    }






                }

            }






            if (errore == "") {

                console.log("ok");

                this.uploadFile();

            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 4000
                });


            }


        },

        btnCancelRenameFolderFile: function () {

            this.$modal.hide('popupRenameFolderFile');

        },

        btnConfirmRenameFolderFile: function () {

            var errore = "";

            var that = this;

            if (that.nameNewElement == "") {
                errore = "Folder / File name required";
            }


            if (errore == "") {
                if (that.validateString(that.nameNewElement) == 0) {
                    errore = "Permitted characters A..Z, a..z and 0..9 and . _ - and space";
                }
            }

            if (errore == "") {
                if (that.nameCurrentElement == that.nameNewElement) {
                    errore = "The new name must be different from the old one";
                }
            }


            console.log("IS FILE: ", that.isFile);

            if (that.isFile == 1) {

                if (that.validateFileName(that.nameNewElement) == 0) {
                    errore = "File name is not valid";
                }

            }

            if (errore == "") {

                console.log("ok");

                that.checkRenameFolderFile();


            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2500
                });


            }

        },




        validateString: function (v_string) {

            var regex = /^['A-Za-z0-9\s._-]+$/;
            var result = 1;

            if (!regex.test(v_string)) {
                result = 0;
            }

            return result;
        },

        validateEMail: function (v_email) {

            var result;

            // eslint-disable-next-line
            var validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

            console.log("MAIL: ", v_email);

            if (v_email != undefined) {

                if (v_email != "") {

                    if (v_email.toString().match(validRegex)) {
                        result = true;
                    }
                    else {
                        result = false;

                    }

                }
                else {
                    result = false;


                }


            }
            else {
                result = true;

            }


            return result;

        },

        validateFileName: function (v_string) {

            console.log("VAL STRING: ", v_string);

            var elements = v_string.toString().split(".");

            console.log("ELE LEGTH", elements.length);

            var result = 1;

            if ((elements.length - 1) == 0) {
                result = 0;
            }

            if ((elements.length - 1) > 1) {
                result = 0;
            }


            console.log("RESULT: ", result);

            return result;
        },

        getPathNavigazionePermissions: function () {


            console.log("NAME_FOLDERS_NAVIGATION: ", window.nameFoldersNavigation);

            // aggiorno label path navigazione
            var path_navigazione = "";

            if (this.getWindowMobile() == 0) {

                for (var x = 0; x < window.nameFoldersNavigation.length; x++) {

                    if (path_navigazione != "") {
                        path_navigazione = path_navigazione + " / ";
                    }

                    path_navigazione = path_navigazione + window.nameFoldersNavigation[x];

                }

            }

            if (this.getWindowMobile() == 1) { // mobile

                if (window.nameFoldersNavigation.length <= 4) {

                    for (var x3 = 0; x3 < window.nameFoldersNavigation.length; x3++) {

                        if (path_navigazione != "") {
                            path_navigazione = path_navigazione + " / ";
                        }

                        path_navigazione = path_navigazione + window.nameFoldersNavigation[x3];

                    }


                }



                if (window.nameFoldersNavigation.length > 3) {

                    path_navigazione = "..";

                    var x_start = 0;
                    var len_array = window.nameFoldersNavigation.length;
                    if (len_array >= 4 && len_array <= 6) {
                        x_start = 3;
                    }

                    if (len_array > 6 && len_array <= 8) {
                        x_start = 4;
                    }

                    if (len_array > 8 && len_array <= 10) {
                        x_start = 5;
                    }


                    for (var x4 = x_start; x4 < window.nameFoldersNavigation.length; x4++) {

                        if (path_navigazione != "") {
                            path_navigazione = path_navigazione + " / ";
                        }

                        path_navigazione = path_navigazione + window.nameFoldersNavigation[x4];

                    }

                }

            }

            console.log("PATH NAVIGATION: ", path_navigazione);

            return path_navigazione;

        },



        viewFolder: function () {

            var that = this;

            window.curSrcText = "";

            window.updateFolderSize = 0;


            var ids = $("tr.active-row-doxweb").find(".val_status").attr("data-id");
            var name_folder = $("tr.active-row-doxweb").find(".val_status").attr("name-element");
            var folder_file = $("tr.active-row-doxweb").find(".val_status").attr("folder_file");
            var p_worker = $("tr.active-row-doxweb").find(".val_status").attr("p_worker");
            var p_remove_file = $("tr.active-row-doxweb").find(".val_status").attr("p_remove_file");
            var p_rename_file_folder = $("tr.active-row-doxweb").find(".val_status").attr("p_rename_file_folder");
            var p_otp_delete_file = $("tr.active-row-doxweb").find(".val_status").attr("p_otp_delete_file");
            var p_email = $("tr.active-row-doxweb").find(".val_status").attr("p_email");
            var p_upload = $("tr.active-row-doxweb").find(".val_status").attr("p_upload");
            var p_create_folder = $("tr.active-row-doxweb").find(".val_status").attr("p_create_folder");
            var p_download_and_delete_file = $("tr.active-row-doxweb").find(".val_status").attr("p_download_and_delete_file");
            var p_edit_permissions = $("tr.active-row-doxweb").find(".val_status").attr("p_edit_permissions");
            var p_send_file_by_mail = $("tr.active-row-doxweb").find(".val_status").attr("p_send_file_by_mail");
            var p_expired_file = $("tr.active-row-doxweb").find(".val_status").attr("p_expired_file");


            console.log("VIEW FOLDER ID: ", ids);
            console.log("VIEW FOLDER NAME_FOLDER: ", name_folder);
            console.log("VIEW FOLDER FOLDER_FILE: ", folder_file);
            console.log("VIEW FOLDER P_WORKER: ", p_worker);
            console.log("VIEW FOLDER P_REMOVE_FILE: ", p_remove_file);
            console.log("VIEW FOLDER P_RENAME_FILE_FOLDER: ", p_rename_file_folder);
            console.log("VIEW FOLDER P_OTP_DELETE_FILE: ", p_otp_delete_file);
            console.log("VIEW FOLDER P_EMAIL: ", p_email);
            console.log("VIEW FOLDER P_UPLOAD: ", p_upload);
            console.log("VIEW FOLDER P_CREATE_FOLDER: ", p_create_folder);
            console.log("VIEW FOLDER P_DOWNLOAD_AND_DELETE: ", p_download_and_delete_file);
            console.log("VIEW FOLDER P_EDIT_PERMISSIONS: ", p_edit_permissions);
            console.log("VIEW FOLDER P_SEND_FILE_BY_MAIL: ", p_send_file_by_mail);
            console.log("VIEW FOLDER P_EXPIRED_FILE: ", p_expired_file);

            if (p_remove_file == null) {
                p_remove_file = 0;
            }
            if (p_remove_file == "") {
                p_remove_file = 0;
            }

            if (p_rename_file_folder == null) {
                p_rename_file_folder = 0;
            }
            if (p_rename_file_folder == "") {
                p_rename_file_folder = 0;
            }

            if (p_otp_delete_file == null) {
                p_otp_delete_file = 0;
            }

            if (p_otp_delete_file == "") {
                p_otp_delete_file = 0;
            }

            if (p_email == "") {
                p_email = 0;
            }

            if (p_upload == "") {
                p_upload = 0;
            }

            if (p_create_folder == "") {
                p_create_folder = 0;
            }


            if (p_worker == null) {
                p_worker = 0;
            }

            if (p_worker == "") {
                p_worker = 0;
            }

            if (p_download_and_delete_file == "") {
                p_download_and_delete_file = 0;
            }


            if (p_edit_permissions == null) {
                p_edit_permissions = 0;
            }

            if (p_edit_permissions == "") {
                p_edit_permissions = 0;
            }


            if (p_send_file_by_mail == null) {
                p_send_file_by_mail = 0;
            }

            if (p_send_file_by_mail == "") {
                p_send_file_by_mail = 0;
            }

            if (p_expired_file == "") {
                p_expired_file = 0;
            }





            var infoParentFolder = [{ p_remove_file: p_remove_file, p_rename_file_folder: p_rename_file_folder, p_otp_delete_file: p_otp_delete_file, p_email: p_email, p_upload: p_upload, data_id: ids, name_element: name_folder, p_create_folder: p_create_folder, p_worker: p_worker, p_download_and_delete_file: p_download_and_delete_file, folder_file: folder_file, p_edit_permissions: p_edit_permissions, p_send_file_by_mail: p_send_file_by_mail, p_expired_file: p_expired_file }];

            window.infoFolderParent.push(infoParentFolder);


            window.lastFolderSel = folder_file;

            window.curpage = 0;
            window.askFiles = 1;

            window.p_worker = p_worker;

            window.idFoldersNavigation.push(window.id);

            window.nameFoldersNavigation.push(name_folder);

            window.fullPathFoldersNavigation.push(folder_file);




            window.folder_level = parseInt(window.folder_level) + 1;


            console.log("INFO_FOLDER_PARENT: ", window.infoFolderParent);

            console.log("ID_FOLDERS_NAVIGATION: ", window.idFoldersNavigation);

            console.log("NAME_FOLDERS_NAV: ", window.nameFoldersNavigation);

            console.log("FULL_PATH_NAV: ", window.fullPathFoldersNavigation);

            console.log("FOLDER_LEVEL: ", window.folder_level);







            window.app = "doxweb";

            window.id = parseInt(ids);


            // aggiorno label path navigazione
            var path_navigazione = "";

            if (that.getWindowMobile() == 0) {

                for (var x = 0; x < window.nameFoldersNavigation.length; x++) {

                    if (path_navigazione != "") {
                        path_navigazione = path_navigazione + " / ";
                    }

                    path_navigazione = path_navigazione + window.nameFoldersNavigation[x];

                }

            }

            if (that.getWindowMobile() == 1) { // mobile

                if (window.nameFoldersNavigation.length <= 4) {

                    for (var x3 = 0; x3 < window.nameFoldersNavigation.length; x3++) {

                        if (path_navigazione != "") {
                            path_navigazione = path_navigazione + " / ";
                        }

                        path_navigazione = path_navigazione + window.nameFoldersNavigation[x3];

                    }


                }



                if (window.nameFoldersNavigation.length > 3) {

                    path_navigazione = "..";

                    var x_start = 0;
                    var len_array = window.nameFoldersNavigation.length;
                    if (len_array >= 4 && len_array <= 6) {
                        x_start = 3;
                    }

                    if (len_array > 6 && len_array <= 8) {
                        x_start = 4;
                    }

                    if (len_array > 8 && len_array <= 10) {
                        x_start = 5;
                    }


                    for (var x4 = x_start; x4 < window.nameFoldersNavigation.length; x4++) {

                        if (path_navigazione != "") {
                            path_navigazione = path_navigazione + " / ";
                        }

                        path_navigazione = path_navigazione + window.nameFoldersNavigation[x4];

                    }

                }





            }


            console.log("LABEL URL: ", path_navigazione);


            $(".label_url").html(path_navigazione);



            $("#backHeader").show();



            setTimeout(() => {

                window.table.ajax.reload();

                that.hideShowButtons(false);


            }, 200);



        },







        btnCancelNewFolder: function () {

            this.$modal.hide('popupAddFolder');

        },

        btnConfirmNewFolder: function () {

            var errore = "";

            var that = this;

            if (that.nameNewFolder == "") {
                errore = "Folder name required";
            }


            if (errore == "") {
                if (that.validateString(that.nameNewFolder) == 0) {
                    errore = "Permitted characters A..Z, a..z and 0..9 and . _ - and space";
                }
            }




            if (errore == "") {

                console.log("ok");

                that.createNewFolder(that.nameNewFolder);


            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2500
                });


            }



        },



        uploadFile: async function () {


            var that = this;

            that.$modal.hide('popupUploadFile');

            var is_folder_sel = 0;
            var folder_file = "";
            var folder_id = "";
            var folder_text = "";
            var p_email = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {


                    folder_file = $(this).attr("folder_file");
                    folder_id = $(this).attr("data-id");
                    folder_text = $(this).attr("name-element");
                    p_email = $(this).attr("p_email");

                    is_folder_sel = 1;

                }

            });

            if (folder_file == "") {
                folder_file = window.lastFolderSel;

                p_email = window.infoFolderParent[window.infoFolderParent.length - 1][0]["p_email"];
                folder_id = window.infoFolderParent[window.infoFolderParent.length - 1][0]["data_id"];
                folder_text = window.infoFolderParent[window.infoFolderParent.length - 1][0]["name_element"];

                console.log("P_EMAIL: ", p_email);
                console.log("FOLDER_ID: ", folder_id);
                console.log("FOLDER_TEXT: ", folder_text);

            }

            var a = window.$cookies.get("a");

            if (a == "S") {

                p_email = 1;

            }
            else {

                if (p_email == "") {
                    p_email = 0;
                }

            }


            if (p_email == 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;


                var response = await apidoxweb.uploadFile(
                    folder_file,
                    false,
                    "cedolini",
                    $("#input-file-attach")[0].files[0]
                ).then((res) => {

                    that.showSpinner = false;
                    that.$root.$children[0].showProgress = false;
                    console.log("res from uploadFile", res);

                    if (res.data.result == "OK") {


                        that.$swal({
                            icon: "success",
                            text: "File uploaded successfully",
                            showConfirmButton: false,
                            timer: 2000
                        });


                        if (is_folder_sel == 1) {

                            setTimeout(() => {


                                that.viewFolder();


                            }, 200);


                        }

                        if (is_folder_sel == 0) {

                            setTimeout(() => {

                                window.app = "doxweb";

                                window.table.ajax.reload();


                            }, 200);


                        }

                    }
                    else {

                        that.$swal({
                            icon: "error",
                            text: res.data.errdetails,
                            showConfirmButton: false,
                            timer: 3000
                        });

                    }








                }).catch(err => {
                    that.showSpinner = false;
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;
                    console.log(msg);

                    that.$swal({
                        icon: "error",
                        text: "Error the file is too large to upload to the server",
                        showConfirmButton: false,
                        timer: 3000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );

            }



            if (p_email == 1) {

                var v_name = $("#input-file-attach")[0].files[0].name;

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                var response2 = await apidoxweb.uploadFile(
                    folder_file,
                    false,
                    "cedolini",
                    $("#input-file-attach")[0].files[0]
                ).then(async (res2) => {

                    //that.$root.$children[0].showProgress = false;
                    console.log("res from uploadFile", res2);

                    if (res2.data.result == "OK") {

                        var v_object = "upload";
                        var login = atob(window.$cookies.get("token"));
                        var useremail = this.userMail;

                        console.log("FOLDER_FILE: ", folder_file);
                        console.log("OBJECT: ", v_object);
                        console.log("FOLDER_ID: ", folder_id);
                        console.log("IS_FOLDER_SEL: ", is_folder_sel);
                        console.log("FOLDER_TEXT: ", folder_text);
                        console.log("LOGIN: ", login);
                        console.log("USEREMAIL: ", useremail);

                        var response3 = await apidoxweb.notifyByFolderNew(
                            v_object,
                            folder_id,
                            folder_text,
                            login,
                            useremail,
                            v_name
                        ).then((res3) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from notifyByFolderNew", res3);

                            that.$swal({
                                icon: "success",
                                text: "File uploaded successfully",
                                showConfirmButton: false,
                                timer: 2000
                            });


                            if (is_folder_sel == 1) {

                                setTimeout(() => {


                                    that.viewFolder();


                                }, 200);


                            }

                            if (is_folder_sel == 0) {

                                setTimeout(() => {

                                    window.app = "doxweb";

                                    window.table.ajax.reload();


                                }, 200);


                            }





                        }).catch(err3 => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err3);
                            var msg3 = err3.response.data.Error;
                            console.log(msg3);

                            that.$swal({
                                icon: "error",
                                text: "Error during upload !",
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("ERrori", "Non è stato possibile salvare i dati");
                            console.log("response", response3);

                        }

                        );

                    }
                    else {

                        that.$root.$children[0].showProgress = false;

                        that.$swal({
                            icon: "error",
                            text: res2.data.errdetails,
                            showConfirmButton: false,
                            timer: 3000
                        });



                    }





                }).catch(err2 => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg2 = err2.response.data.Error;
                    console.log(msg2);

                    that.$swal({
                        icon: "error",
                        text: "Error during upload !",
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response2);

                }

                );

            }









        },






        checkRenameFolderFile: async function () {


            var that = this;


            var key = "";
            var is_file = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    is_file = $(this).attr("is-file");
                    if (is_file == 0) {
                        key = $(this).attr("folder_file");
                    }

                    if (is_file == 1) {
                        key = $(this).attr("file-path");
                    }

                }

            });



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.checkFolderFileExist(
                key,
                that.nameCurrentElement,
                that.nameNewElement,
                is_file
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from checkFolderFileExist", res);

                if (res.data.Result == "OK") {

                    console.log("ok");


                    if (is_file == 0) {


                        that.renameFolderFile();
                    }


                    if (is_file == 1) {


                        that.renameFile();
                    }


                }
                else {


                    if (is_file == 0) {


                        that.$swal({
                            icon: "error",
                            text: "Folder already exist",
                            showConfirmButton: false,
                            timer: 2500
                        });



                    }

                    if (is_file == 1) {


                        that.$swal({
                            icon: "error",
                            text: "File already exist",
                            showConfirmButton: false,
                            timer: 2500
                        });



                    }

                }








            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );






        },


        renameFolderFile: async function () {


            var that = this;

            var app = "doxweb";
            var action = "renameFolder";

            var uname = "";

            var a = window.$cookies.get("a");

            if (a == "S") {
                uname = "admin";
            }

            var key = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    var is_file = $(this).attr("is-file");

                    if (is_file == 0) {

                        key = btoa($(this).attr("folder_file"));

                    }

                    if (is_file == 1) {

                        key = $(this).attr("file-path");


                        uname = "";


                        app = "";

                    }



                }

            });


            var v_new = that.nameNewElement;
            var v_old = that.nameCurrentElement;
            var v_a = window.$cookies.get("token");
            var v_b = window.$cookies.get("token_system");


            console.log("ACTION: ", action);
            console.log("UNAME: ", uname);
            console.log("KEY: ", key);
            console.log("NEW: ", v_new);
            console.log("OLD: ", v_old);
            console.log("A: ", v_a);
            console.log("B: ", v_b);
            console.log("APP: ", app);



            that.$modal.hide('popupRenameFolderFile');

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.renameFolderFile(
                action,
                uname,
                key,
                v_new,
                v_old,
                v_a,
                v_b,
                app
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from renameFolderFile", res);


                window.app = app;

                setTimeout(() => {

                    that.$swal({
                        icon: "success",
                        text: "Rename done successfully",
                        showConfirmButton: false,
                        timer: 2000
                    });


                    window.table.ajax.reload();


                }, 200);





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );






        },


        renameFile: async function () {


            var that = this;

            var app = "doxweb";
            var action = "renameFolder";

            var uname = "";

            var a = window.$cookies.get("a");

            if (a == "S") {
                uname = "admin";
            }

            var key = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    var is_file = $(this).attr("is-file");

                    if (is_file == 0) {

                        key = btoa($(this).attr("folder_file"));

                    }

                    if (is_file == 1) {

                        key = $(this).attr("file-path");


                        uname = "";


                        app = "";

                    }



                }

            });


            var v_new = that.nameNewElement;
            var v_old = that.nameCurrentElement;
            var v_a = window.$cookies.get("token");
            var v_b = window.$cookies.get("token_system");


            console.log("ACTION: ", action);
            console.log("UNAME: ", uname);
            console.log("KEY: ", key);
            console.log("NEW: ", v_new);
            console.log("OLD: ", v_old);
            console.log("A: ", v_a);
            console.log("B: ", v_b);
            console.log("APP: ", app);



            that.$modal.hide('popupRenameFolderFile');

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.renameFile(
                action,
                uname,
                key,
                v_new,
                v_old
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from renameFile", res);


                window.app = "doxweb";

                setTimeout(() => {

                    that.$swal({
                        icon: "success",
                        text: "Rename done successfully",
                        showConfirmButton: false,
                        timer: 2000
                    });


                    window.table.ajax.reload();


                }, 200);





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );






        },





        createPermissions: async function (id_folder, name_folder, path_folder) {

            var that = this;


            var is_folder_sel = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    is_folder_sel = 1;

                }

            });



            var dest = "";
            var group_id = "";
            var lastSelection = "";

            var user_mail = window.$cookies.get("doxweb_useremail");


            dest = user_mail;
            lastSelection = user_mail;

            var login = "admin_c";

            var v_id = id_folder;
            var v_text = name_folder;
            var v_icon = true;
            var v_folder_file = path_folder;
            var val = 1;
            var field = "p_enter";

            var nodeOriginalArray = [{ folder_file: v_folder_file }];

            var nodeArray = [{ id: v_id, text: v_text, icon: v_icon, original: nodeOriginalArray }];

            var node = JSON.stringify(nodeArray);


            console.log("CREATE PERMISSIONS DEST: ", dest);
            console.log("CREATE PERMISSIONS GROUP_ID: ", group_id);
            console.log("CREATE PERMISSIONS LOGIN: ", login);
            console.log("CREATE PERMISSIONS LASTSELECTION: ", lastSelection);
            console.log("CREATE PERMISSIONS NODE: ", node);
            console.log("CREATE PERMISSIONS FIELD: ", field);
            console.log("CREATE PERMISSIONS VAL: ", val);

            var response = await apidoxweb.updatePermissions(
                dest,
                group_id,
                login,
                node,
                lastSelection,
                "p_enter",
                1
            ).then(async (res) => {

                console.log("res from updatePermissions p_enter", res);

                var response2 = await apidoxweb.updatePermissions(
                    dest,
                    group_id,
                    login,
                    node,
                    lastSelection,
                    "p_show",
                    1
                ).then(async (res2) => {


                    console.log("res from updatePermissions p_show", res2);

                    var response3 = await apidoxweb.updatePermissions(
                        dest,
                        group_id,
                        login,
                        node,
                        lastSelection,
                        "p_remove_file",
                        1
                    ).then(async (res3) => {


                        console.log("res from updatePermissions p_remove_file", res3);

                        var response4 = await apidoxweb.updatePermissions(
                            dest,
                            group_id,
                            login,
                            node,
                            lastSelection,
                            "p_rename_file_folder",
                            1
                        ).then(async (res4) => {


                            console.log("res from updatePermissions p_rename_file_folder", res4);

                            var response5 = await apidoxweb.updatePermissions(
                                dest,
                                group_id,
                                login,
                                node,
                                lastSelection,
                                "p_remove_folder",
                                1
                            ).then(async (res5) => {


                                console.log("res from updatePermissions p_remove_folder", res5);

                                var response6 = await apidoxweb.updatePermissions(
                                    dest,
                                    group_id,
                                    login,
                                    node,
                                    lastSelection,
                                    "p_upload",
                                    1
                                ).then(async (res6) => {


                                    console.log("res from updatePermissions p_upload", res6);

                                    var response7 = await apidoxweb.updatePermissions(
                                        dest,
                                        group_id,
                                        login,
                                        node,
                                        lastSelection,
                                        "p_create_folder",
                                        1
                                    ).then(async (res7) => {

                                        that.$root.$children[0].showProgress = false;
                                        console.log("res from updatePermissions p_create_folder", res7);



                                        that.$swal({
                                            icon: "success",
                                            text: "Folder created successfully",
                                            showConfirmButton: false,
                                            timer: 2000
                                        });


                                        if (is_folder_sel == 1) {

                                            setTimeout(() => {


                                                that.viewFolder();


                                            }, 200);


                                        }

                                        if (is_folder_sel == 0) {

                                            setTimeout(() => {

                                                window.app = "doxweb";

                                                window.table.ajax.reload();


                                            }, 200);


                                        }





                                    }).catch(err7 => {
                                        that.$root.$children[0].showProgress = false;
                                        console.log(err7);
                                        var msg7 = err7.response.data.Error;

                                        that.$swal({
                                            icon: "error",
                                            text: msg7,
                                            showConfirmButton: false,
                                            timer: 8000
                                        });
                                        console.log("Errori", "Non è stato possibile eliminare i dati");
                                        console.log("response", response7);

                                    }

                                    );





                                }).catch(err6 => {
                                    that.$root.$children[0].showProgress = false;
                                    console.log(err6);
                                    var msg6 = err6.response.data.Error;

                                    that.$swal({
                                        icon: "error",
                                        text: msg6,
                                        showConfirmButton: false,
                                        timer: 8000
                                    });
                                    console.log("Errori", "Non è stato possibile eliminare i dati");
                                    console.log("response", response6);

                                }

                                );








                            }).catch(err5 => {
                                that.$root.$children[0].showProgress = false;
                                console.log(err5);
                                var msg5 = err5.response.data.Error;

                                that.$swal({
                                    icon: "error",
                                    text: msg5,
                                    showConfirmButton: false,
                                    timer: 8000
                                });
                                console.log("Errori", "Non è stato possibile eliminare i dati");
                                console.log("response", response5);

                            }

                            );





                        }).catch(err4 => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err4);
                            var msg4 = err4.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg4,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response4);

                        }

                        );





                    }).catch(err3 => {
                        that.$root.$children[0].showProgress = false;
                        console.log(err3);
                        var msg3 = err3.response.data.Error;

                        that.$swal({
                            icon: "error",
                            text: msg3,
                            showConfirmButton: false,
                            timer: 8000
                        });
                        console.log("Errori", "Non è stato possibile eliminare i dati");
                        console.log("response", response3);

                    }

                    );






                }).catch(err2 => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err2);
                    var msg2 = err2.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg2,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile eliminare i dati");
                    console.log("response", response2);

                }

                );


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },


        createNewFolder: async function (name_folder) {


            var login = "";

            var a = window.$cookies.get("a");

            if (a == "S") {
                login = "admin";
            }
            else {

                login = atob(window.$cookies.get("token"));

            }

            var file_path = "";

            var is_folder_sel = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    file_path = $(this).attr("folder_file");

                    is_folder_sel = 1;

                }

            });


            if (file_path == "") {
                file_path = window.lastFolderSel;
            }

            var parPath = file_path + name_folder + "/";

            console.log("USER: ", login);
            console.log("NAME FOLDER: ", name_folder);
            console.log("FILE PATH: ", file_path);
            console.log("PAR PATH: ", parPath);
            console.log("IS FOLDER SEL: ", is_folder_sel);

            var that = this;

            that.$modal.hide('popupAddFolder');

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.createFolder(
                login,
                name_folder,
                parPath,
                file_path
            ).then((res) => {



                console.log("res from createFolder", res);

                if (res.data.Errore == "") {


                    if (a == "S") {

                        that.$root.$children[0].showProgress = false;

                        that.$swal({
                            icon: "success",
                            text: "Folder created successfully",
                            showConfirmButton: false,
                            timer: 2000
                        });


                        if (is_folder_sel == 1) {

                            setTimeout(() => {


                                that.viewFolder();


                            }, 200);


                        }

                        if (is_folder_sel == 0) {

                            setTimeout(() => {

                                window.app = "doxweb";

                                window.table.ajax.reload();


                            }, 200);


                        }

                    }


                    if (a != "S") {


                        that.createPermissions(res.data.id, name_folder, parPath);

                    }



                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res.data.Errore,
                        showConfirmButton: false,
                        timer: 2500
                    });

                }




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );







        },


        downloadFileAndDelete: function (id_folder) {

            var that = this;


            var htmlCancelButton = "<img title='Cancel' style='width:30px' src='" + that.getUrlDomain("public/_lib/img/btn_cancel3_doxweb.png") + "' />";

            var htmlConfirmButton = "<img title='Confirm' style='width:30px' src='" + that.getUrlDomain("public/_lib/img/btn_confirm3_doxweb.png") + "' />";

            var txt_msg = "The file you are downloading will be subsequently deleted. Do you want to continue ?";

            that.$swal
                .fire({
                    title: "Download And Delete",
                    html: txt_msg,
                    icon: "warning",
                    iconColor: "#8ec8a3",
                    showCancelButton: true,
                    confirmButtonText: htmlConfirmButton,
                    cancelButtonText: htmlCancelButton,
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {



                    if (result.value == true) {

                        var v_token = window.$cookies.get("token");


                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        setTimeout(() => {

                            var file_path = "";

                            $(".val_status").each(function (index) {

                                console.log(index);

                                if ($(this).prop("checked")) {


                                    file_path = $(this).attr("file-path");

                                }

                            });



                            var url_call = that.getUrlDomain("downloadFileAndDelete") + "?path_file=" + file_path + "&folder_id=" + id_folder + "&username=" + v_token;

                            console.log("URL TO CALL: ", url_call);

                            window.app = "doxweb";


                            window.open(url_call, "_blank");


                            setTimeout(() => {

                                window.table.ajax.reload();

                                that.showSpinner = false;
                                that.$root.$children[0].showProgress = false;



                            }, 1000);


                        }, 500);







                    }







                });





        },





        downloadFile: function () {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            setTimeout(() => {

                var v_token = window.$cookies.get("token");


                var file_path = "";

                $(".val_status").each(function (index) {

                    console.log(index);

                    if ($(this).prop("checked")) {


                        file_path = $(this).attr("file-path");

                    }

                });

                console.log("PATH FILE: ", file_path);



                var url_call = that.getUrlDomain("downloadFileNew") + "?path_file=" + file_path + "&username=" + v_token;

                console.log("URL TO CALL: ", url_call);

                window.app = "doxweb";

                window.table.ajax.reload();

                window.open(url_call, "_blank");


                setTimeout(() => {

                    that.showSpinner = false;
                    that.$root.$children[0].showProgress = false;



                }, 1000);


            }, 500);




        },




        downloadCud: function () {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var v_token = window.$cookies.get("token");

            var file_path = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {


                    file_path = $(this).attr("file-path");

                }

            });


            var url_call = that.getUrlDomain("downloadCud") + "?path_file=" + file_path + "&username=" + v_token;

            console.log("URL TO CALL: ", url_call);

            window.open(url_call, "_blank");


            setTimeout(() => {

                window.app = "doxweb";

                window.table.ajax.reload();

                setTimeout(() => {

                    that.showSpinner = false;
                    that.$root.$children[0].showProgress = false;



                }, 1000);

            }, 3000);




        },

        downloadCudOld: function () {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            setTimeout(() => {

                var v_token = window.$cookies.get("token");


                var file_path = "";

                $(".val_status").each(function (index) {

                    console.log(index);

                    if ($(this).prop("checked")) {


                        file_path = $(this).attr("file-path");

                    }

                });



                var url_call = that.getUrlDomain("downloadCud") + "?path_file=" + file_path + "&username=" + v_token;

                console.log("URL TO CALL: ", url_call);

                window.app = "doxweb";

                window.table.ajax.reload();

                window.open(url_call, "_blank");


                setTimeout(() => {

                    that.showSpinner = false;
                    that.$root.$children[0].showProgress = false;



                }, 1000);


            }, 500);




        },



        downloadFileCedolini: function () {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            setTimeout(() => {

                var v_token = window.$cookies.get("token");


                // var file_path = "";

                // $(".val_status").each(function (index) {

                //     console.log(index);

                //     if ($(this).prop("checked")) {


                //         file_path = $(this).attr("file-path");

                //     }

                // });

                var file_path = window.selPathFileDownload;



                var url_call = that.getUrlDomain("downloadFileCedoliniNew") + "?path_file=" + file_path + "&username=" + v_token;

                console.log("URL TO CALL: ", url_call);

                window.app = "doxweb";

                window.table.ajax.reload();

                window.open(url_call, "_blank");


                setTimeout(() => {

                    that.showSpinner = false;
                    that.$root.$children[0].showProgress = false;



                }, 1000);


            }, 500);




        },





        checkSignatureViewFile: async function () {

            var that = this;

            var username = window.$cookies.get("token");
            username = atob(username);


            var data = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {


                    data = $(this).attr("file-path");

                }

            });


            console.log("USERNAME: ", username);
            console.log("DATA: ", data);




            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.checkSignature(
                username,
                data
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from checkSignature", res);


                if (res.data.Result == "OK") {



                    if (!res.data.Exist) {

                        $(".contpopupsignatureviewfile").show();

                        that.$modal.show('popupSignatureViewFile');


                        setTimeout(() => {

                            const canvas = document.querySelector("canvas");

                            window.signaturePad = new SignaturePad(canvas);




                        }, 200);



                    }


                    if (res.data.Exist) {





                        console.log("ok");


                        that.sendMailCedoliniWithoutSignViewFile();




                    }


                }
                else {


                    that.$swal({
                        icon: "error",
                        text: res.data.ErrorDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });



                }







            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );






        },



        checkSignature: async function () {

            var that = this;

            var username = window.$cookies.get("token");
            username = atob(username);


            var data = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {


                    data = $(this).attr("file-path");

                }

            });


            console.log("USERNAME: ", username);
            console.log("DATA: ", data);




            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.checkSignature(
                username,
                data
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from checkSignature", res);


                if (res.data.Result == "OK") {



                    if (!res.data.Exist) {

                        $(".contpopupsignature").show();

                        that.$modal.show('popupSignature');


                        setTimeout(() => {

                            const canvas = document.querySelector("canvas");

                            window.signaturePad = new SignaturePad(canvas);




                        }, 200);



                    }


                    if (res.data.Exist) {





                        console.log("ok");


                        that.sendMailCedoliniWithoutSign();




                    }


                }
                else {


                    that.$swal({
                        icon: "error",
                        text: res.data.ErrorDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });



                }







            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );






        },


        btnCancelSearch: function () {

            this.$modal.hide('popupSearch');

        },



        btnConfirmSearch: function () {

            console.log("ok");

            window.curSrcText = this.textSearch;

            this.$modal.hide('popupSearch');


            window.table.ajax.reload();

        },


        getWindowMobile: function () {

            if (window.innerWidth <= 768) {

                return 1;

            }
            else {

                return 0;

            }

        },




        hideShowButtonsSelRow: function () {


            if (window.idFolderOriginCopyPermissions == 0 && window.addFavorite == 0) { // non sono in copy permissions ne in add favorite

                var tot = 0;
                var tot_file = 0;

                var a = window.$cookies.get("a");

                var models = window.$cookies.get("models");

                console.log(models);

                var view_btn_res_qt_code = 1;

                if (models != null) {

                    var elements = models.split(",");

                    for (var x = 0; x <= elements.length - 1; x++) {

                        if ((parseInt(elements[x]) == 9) || (parseInt(elements[x]) == 10)) {

                            view_btn_res_qt_code = 0;

                        }


                    }


                }

                console.log("VIEW: ", view_btn_res_qt_code);

                var is_file = 0;
                var p_remove_folder = 0;
                var p_upload = 0;
                var p_create_folder = 0;
                var p_edit_permissions = 0;
                var p_worker = 0;
                var p_zip_folder = 0;
                var is_present_zip = 0;
                var p_rename_file_folder = 0;
                var p_copy = 0;


                $(".val_status").each(function (index) {

                    console.log(index);

                    if ($(this).prop("checked")) {

                        tot = tot + 1;

                        is_file = $(this).attr("is-file");
                        p_remove_folder = $(this).attr("p_remove_folder");
                        p_upload = $(this).attr("p_upload");
                        p_create_folder = $(this).attr("p_create_folder");
                        p_edit_permissions = $(this).attr("p_edit_permissions");
                        p_worker = $(this).attr("p_worker");
                        p_zip_folder = $(this).attr("p_zip_folder");
                        is_present_zip = $(this).attr("is_present_zip");
                        p_rename_file_folder = $(this).attr("p_rename_file_folder");
                        p_copy = $(this).attr("p_copy");


                        if (is_file == 1) {
                            tot_file = tot_file + 1;
                        }

                    }

                });

                var pwd_fld_enable = window.$cookies.get("doxweb_fld_pwd");
                var favorities_enable = window.$cookies.get("doxweb_favorities");

                console.log("TOT: ", tot);
                console.log("IS_FILE: ", is_file);
                console.log("P_REMOVE_FOLDER: ", p_remove_folder);
                console.log("P_UPLOAD: ", p_upload);
                console.log("P_CREATE_FOLDER: ", p_create_folder);
                console.log("P_EDIT_PERMISSIONS: ", p_edit_permissions);
                console.log("P_PWD_FLD: ", pwd_fld_enable);
                console.log("P_WORKER: ", p_worker);




                setTimeout(() => {

                    var pulsantis = [];



                    if (tot == 1) { // un solo elemento

                        if (is_file == 0) { // folder


                            if (p_create_folder == 1) {

                                pulsantis.push({
                                    text: "Add",
                                    icon: "mdi-home-circle",
                                    image: this.getUrlDomain("public/_lib/img/DOXWEB BOTTOM 54 più.png"),
                                    link: "/settings",
                                    id: "btn_create",
                                    disabled: false,
                                    title: "Create",
                                    width: 30
                                });


                            }

                            if (p_remove_folder == 1) {

                                pulsantis.push({
                                    text: "Delete",
                                    icon: "mdi-home-circle",
                                    image: this.getUrlDomain("public/_lib/img/BOTTOM 6 cancella file.png"),
                                    link: "/info",
                                    id: "btn_remove",
                                    disabled: false,
                                    title: "Remove",
                                    width: 30
                                });

                            }

                            if (p_rename_file_folder == 1) {

                                pulsantis.push({
                                    text: "Edit",
                                    icon: "mdi-home-circle",
                                    image: this.getUrlDomain("public/_lib/img/icon_edit_doxweb.png"),
                                    link: "/info",
                                    id: "btn_rename",
                                    disabled: false,
                                    title: "Rename",
                                    width: 30
                                });
                            }

                            if (p_upload == 1) {

                                pulsantis.push({
                                    text: "Edit",
                                    icon: "mdi-home-circle",
                                    image: this.getUrlDomain("public/_lib/img/upload_doxweb.png"),
                                    link: "/info",
                                    id: "btn_upload",
                                    disabled: false,
                                    title: "Upload",
                                    width: 30
                                });


                            }


                            if (p_edit_permissions == 1) {

                                pulsantis.push({
                                    text: "Edit",
                                    icon: "mdi-home-circle",
                                    image: this.getUrlDomain("public/_lib/img/permissions_doweb.png"),
                                    link: "/info",
                                    id: "btn_permissions",
                                    disabled: false,
                                    title: "Permissions",
                                    width: 30
                                });


                            }


                            if (p_copy == 1) {

                                pulsantis.push({
                                    text: "Edit",
                                    icon: "mdi-home-circle",
                                    image: this.getUrlDomain("public/_lib/img/copia_diritti.png"),
                                    link: "/info",
                                    id: "btn_copy_permissions",
                                    disabled: false,
                                    title: "Copy Permissions",
                                    width: 30
                                });

                            }

                            if (favorities_enable == "S") {

                                pulsantis.push({
                                    text: "Edit",
                                    icon: "mdi-home-circle",
                                    image: this.getUrlDomain("public/_lib/img/set_password.png"),
                                    link: "/info",
                                    id: "btn_set_password",
                                    disabled: false,
                                    title: "Set Password",
                                    width: 30
                                });


                            }


                            if (p_zip_folder == 1) {

                                pulsantis.push({
                                    text: "Edit",
                                    icon: "mdi-home-circle",
                                    image: this.getUrlDomain("public/_lib/img/zip_folder_button.png"),
                                    link: "/info",
                                    id: "btn_zip_folder",
                                    disabled: false,
                                    title: "Zip Folder",
                                    width: 30
                                });

                            }

                            if (is_present_zip == 1) {

                                pulsantis.push({
                                    text: "Edit",
                                    icon: "mdi-home-circle",
                                    image: this.getUrlDomain("public/_lib/img/zip_folder_download.png"),
                                    link: "/info",
                                    id: "btn_zip_download",
                                    disabled: false,
                                    title: "Download Zip",
                                    width: 30
                                });


                            }


                        }

                        if (is_file == 1) {


                            var p_rename_file_folder2 = window.infoFolderParent[window.infoFolderParent.length - 1][0]["p_rename_file_folder"];

                            var p_remove_file2 = window.infoFolderParent[window.infoFolderParent.length - 1][0]["p_remove_file"];

                            var p_send_file_by_mail = window.infoFolderParent[window.infoFolderParent.length - 1][0]["p_send_file_by_mail"];

                            var p_expired_file = window.infoFolderParent[window.infoFolderParent.length - 1][0]["p_expired_file"];

                            //console.log("P_SEND_FILE_BY_MAIL: ", p_send_file_by_mail);



                            if (tot == 1) {

                                pulsantis.push({
                                    text: "Edit",
                                    icon: "mdi-home-circle",
                                    image: this.getUrlDomain("public/_lib/img/BOTTOM 4 download file.png"),
                                    link: "/info",
                                    id: "btn_download",
                                    disabled: false,
                                    title: "Download",
                                    width: 30
                                });

                                if (p_rename_file_folder2 == 1) {

                                    pulsantis.push({
                                        text: "Edit",
                                        icon: "mdi-home-circle",
                                        image: this.getUrlDomain("public/_lib/img/icon_edit_doxweb.png"),
                                        link: "/info",
                                        id: "btn_rename",
                                        disabled: false,
                                        title: "Rename",
                                        width: 30
                                    });

                                }

                                if (p_remove_file2 == 1) {

                                    pulsantis.push({
                                        text: "Delete",
                                        icon: "mdi-home-circle",
                                        image: this.getUrlDomain("public/_lib/img/BOTTOM 6 cancella file.png"),
                                        link: "/info",
                                        id: "btn_remove",
                                        disabled: false,
                                        title: "Remove",
                                        width: 30
                                    });

                                }


                                if (a == "S") {

                                    pulsantis.push({
                                        text: "View",
                                        icon: "mdi-home-circle",
                                        image: this.getUrlDomain("public/_lib/img/BOTTOM 5 vedi file.png"),
                                        link: "/info",
                                        id: "btn_view_file",
                                        disabled: false,
                                        title: "View",
                                        width: 30
                                    });


                                }



                                if (p_send_file_by_mail == 1) {

                                    pulsantis.push({
                                        text: "View",
                                        icon: "mdi-home-circle",
                                        image: this.getUrlDomain("public/_lib/img/send_file_by_mail_doxweb.png"),
                                        link: "/info",
                                        id: "btn_send_file_by_mail",
                                        disabled: false,
                                        title: "Send By Mail",
                                        width: 30
                                    });


                                }




                                if (p_expired_file == 1) {

                                    pulsantis.push({
                                        text: "View",
                                        icon: "mdi-home-circle",
                                        image: this.getUrlDomain("public/_lib/img/btn_dox_file_expire.png"),
                                        link: "/info",
                                        id: "btn_expiration_file",
                                        disabled: false,
                                        title: "Expiration",
                                        width: 30
                                    });


                                }






                            }








                        }



                    }



                    if (favorities_enable == "S") {

                        pulsantis.push({
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: this.getUrlDomain("public/_lib/img/doxweb_preferiti.png"),
                            link: "/info",
                            id: "btn_favorities",
                            disabled: false,
                            title: "Favorities",
                            width: 30
                        });


                    }





                    this.$root.$children[0].bnavbuttons = pulsantis;



                }, 100);



            }

            if (window.idFolderOriginCopyPermissions > 0) { // sono in copy permissions


                setTimeout(() => {

                    var pulsantis = [];


                    pulsantis.push({
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: this.getUrlDomain("public/_lib/img/copia_diritti.png"),
                        link: "/settings",
                        id: "btn_confirm_copy_permissions",
                        disabled: false,
                        title: "Confirm Copy Permissions",
                        width: 30
                    });

                    pulsantis.push({
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: this.getUrlDomain("public/_lib/img/cancel_function_file_folder.png"),
                        link: "/settings",
                        id: "btn_cancel_copy_permissions",
                        disabled: false,
                        title: "Cancel Copy Permissions",
                        width: 30
                    });


                    this.$root.$children[0].bnavbuttons = pulsantis;

                }, 100);


            }


            if (window.addFavorite == 1) {


                var p_worker2 = 0;

                $(".val_status").each(function (index) {

                    console.log(index);

                    if ($(this).prop("checked")) {


                        p_worker2 = $(this).attr("p_worker");

                    }

                });




                setTimeout(() => {

                    var pulsantis = [];

                    if (p_worker2 == 0) {

                        pulsantis.push({
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: this.getUrlDomain("public/_lib/img/doxweb_preferiti.png"),
                            link: "/settings",
                            id: "btn_confirm_add_favorite",
                            disabled: false,
                            title: "Confirm Add Favorite",
                            width: 30
                        });


                    }


                    pulsantis.push({
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: this.getUrlDomain("public/_lib/img/cancel_function_file_folder.png"),
                        link: "/settings",
                        id: "btn_cancel_add_favorite",
                        disabled: false,
                        title: "Cancel Add Favorite",
                        width: 30
                    });


                    this.$root.$children[0].bnavbuttons = pulsantis;

                }, 100);



            }








        },



        hideShowButtonsSelRowOld: function () {


            if (window.idFolderOriginCopyPermissions == 0 && window.addFavorite == 0) { // non sono in copy permissions ne in add favorite

                var tot = 0;
                var tot_file = 0;

                var a = window.$cookies.get("a");

                var models = window.$cookies.get("models");

                console.log(models);

                var view_btn_res_qt_code = 1;

                if (models != null) {

                    var elements = models.split(",");

                    for (var x = 0; x <= elements.length - 1; x++) {

                        if ((parseInt(elements[x]) == 9) || (parseInt(elements[x]) == 10)) {

                            view_btn_res_qt_code = 0;

                        }


                    }


                }

                console.log("VIEW: ", view_btn_res_qt_code);

                var is_file = 0;
                var p_remove_folder = 0;
                var p_upload = 0;
                var p_create_folder = 0;
                var p_edit_permissions = 0;
                var p_worker = 0;
                var is_present_zip = 0;


                $(".val_status").each(function (index) {

                    console.log(index);

                    if ($(this).prop("checked")) {

                        tot = tot + 1;

                        is_file = $(this).attr("is-file");
                        p_remove_folder = $(this).attr("p_remove_folder");
                        p_upload = $(this).attr("p_upload");
                        p_create_folder = $(this).attr("p_create_folder");
                        p_edit_permissions = $(this).attr("p_edit_permissions");
                        p_worker = $(this).attr("p_worker");
                        is_present_zip = $(this).attr("is_present_zip");


                        if (is_file == 1) {
                            tot_file = tot_file + 1;
                        }

                    }

                });

                var pwd_fld_enable = window.$cookies.get("doxweb_fld_pwd");
                var favorities_enable = window.$cookies.get("doxweb_favorities");

                console.log("TOT: ", tot);
                console.log("IS_FILE: ", is_file);
                console.log("P_REMOVE_FOLDER: ", p_remove_folder);
                console.log("P_UPLOAD: ", p_upload);
                console.log("P_CREATE_FOLDER: ", p_create_folder);
                console.log("P_EDIT_PERMISSIONS: ", p_edit_permissions);
                console.log("P_PWD_FLD: ", pwd_fld_enable);
                console.log("P_WORKER: ", p_worker);




                setTimeout(() => {

                    var pulsantis = [];


                    if (a == "S") { // SU


                        if (is_file == 0) { // folder



                            if (tot == 1) {

                                pulsantis.push({
                                    text: "Add",
                                    icon: "mdi-home-circle",
                                    image: this.getUrlDomain("public/_lib/img/DOXWEB BOTTOM 54 più.png"),
                                    link: "/settings",
                                    id: "btn_create",
                                    disabled: false,
                                    title: "Create",
                                    width: 30
                                });

                                pulsantis.push({
                                    text: "Edit",
                                    icon: "mdi-home-circle",
                                    image: this.getUrlDomain("public/_lib/img/upload_doxweb.png"),
                                    link: "/info",
                                    id: "btn_upload",
                                    disabled: false,
                                    title: "Upload",
                                    width: 30
                                });

                                pulsantis.push({
                                    text: "Edit",
                                    icon: "mdi-home-circle",
                                    image: this.getUrlDomain("public/_lib/img/icon_edit_doxweb.png"),
                                    link: "/info",
                                    id: "btn_rename",
                                    disabled: false,
                                    title: "Rename",
                                    width: 30
                                });

                                pulsantis.push({
                                    text: "Delete",
                                    icon: "mdi-home-circle",
                                    image: this.getUrlDomain("public/_lib/img/BOTTOM 6 cancella file.png"),
                                    link: "/info",
                                    id: "btn_remove",
                                    disabled: false,
                                    title: "Remove",
                                    width: 30
                                });

                                pulsantis.push({
                                    text: "Edit",
                                    icon: "mdi-home-circle",
                                    image: this.getUrlDomain("public/_lib/img/copia_diritti.png"),
                                    link: "/info",
                                    id: "btn_copy_permissions",
                                    disabled: false,
                                    title: "Copy Permissions",
                                    width: 30
                                });

                                pulsantis.push({
                                    text: "Edit",
                                    icon: "mdi-home-circle",
                                    image: this.getUrlDomain("public/_lib/img/zip_folder_button.png"),
                                    link: "/info",
                                    id: "btn_zip_folder",
                                    disabled: false,
                                    title: "Zip Folder",
                                    width: 30
                                });

                                if (is_present_zip == 1) {

                                    pulsantis.push({
                                        text: "Edit",
                                        icon: "mdi-home-circle",
                                        image: this.getUrlDomain("public/_lib/img/zip_folder_download.png"),
                                        link: "/info",
                                        id: "btn_zip_download",
                                        disabled: false,
                                        title: "Download Zip",
                                        width: 30
                                    });


                                }


                            }

                            if (tot == 0) {

                                pulsantis.push({
                                    text: "Add",
                                    icon: "mdi-home-circle",
                                    image: this.getUrlDomain("public/_lib/img/DOXWEB BOTTOM 54 più.png"),
                                    link: "/settings",
                                    id: "btn_create",
                                    disabled: false,
                                    title: "Create",
                                    width: 30
                                });




                                if (window.folder_level > 0) {


                                    pulsantis.push({
                                        text: "Edit",
                                        icon: "mdi-home-circle",
                                        image: this.getUrlDomain("public/_lib/img/upload_doxweb.png"),
                                        link: "/info",
                                        id: "btn_upload",
                                        disabled: false,
                                        title: "Upload",
                                        width: 30
                                    });



                                }





                            }



                        }


                        if (tot == 1) {

                            if (is_file == 1) {

                                pulsantis.push({
                                    text: "View",
                                    icon: "mdi-home-circle",
                                    image: this.getUrlDomain("public/_lib/img/BOTTOM 5 vedi file.png"),
                                    link: "/info",
                                    id: "btn_view_file",
                                    disabled: false,
                                    title: "View",
                                    width: 30
                                });

                                pulsantis.push({
                                    text: "Edit",
                                    icon: "mdi-home-circle",
                                    image: this.getUrlDomain("public/_lib/img/BOTTOM 4 download file.png"),
                                    link: "/info",
                                    id: "btn_download",
                                    disabled: false,
                                    title: "Download",
                                    width: 30
                                });

                                pulsantis.push({
                                    text: "Edit",
                                    icon: "mdi-home-circle",
                                    image: this.getUrlDomain("public/_lib/img/icon_edit_doxweb.png"),
                                    link: "/info",
                                    id: "btn_rename",
                                    disabled: false,
                                    title: "Rename",
                                    width: 30
                                });




                            }



                        }

                        if (tot > 0) {

                            if (tot == tot_file) {

                                pulsantis.push({
                                    text: "Delete",
                                    icon: "mdi-home-circle",
                                    image: this.getUrlDomain("public/_lib/img/BOTTOM 6 cancella file.png"),
                                    link: "/info",
                                    id: "btn_remove",
                                    disabled: false,
                                    title: "Remove",
                                    width: 30
                                });

                            }


                            if (view_btn_res_qt_code == 1) {

                                if (tot == 1) {

                                    if (is_file == 0) {

                                        pulsantis.push({
                                            text: "Edit",
                                            icon: "mdi-home-circle",
                                            image: this.getUrlDomain("public/_lib/img/permissions_doweb.png"),
                                            link: "/info",
                                            id: "btn_permissions",
                                            disabled: false,
                                            title: "Permissions",
                                            width: 30
                                        });


                                    }




                                }


                            }



                        }


                        pulsantis.push({
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: this.getUrlDomain("public/_lib/img/doxweb_preferiti.png"),
                            link: "/info",
                            id: "btn_favorities",
                            disabled: false,
                            title: "Favorities",
                            width: 30
                        });






                    }



                    if (a == "N") { // non SU

                        if (tot == 1) { // un solo elemento

                            if (is_file == 0) { // folder


                                if (p_create_folder == 1) {

                                    pulsantis.push({
                                        text: "Add",
                                        icon: "mdi-home-circle",
                                        image: this.getUrlDomain("public/_lib/img/DOXWEB BOTTOM 54 più.png"),
                                        link: "/settings",
                                        id: "btn_create",
                                        disabled: false,
                                        title: "Create",
                                        width: 30
                                    });


                                }

                                if (p_remove_folder == 1) {

                                    pulsantis.push({
                                        text: "Delete",
                                        icon: "mdi-home-circle",
                                        image: this.getUrlDomain("public/_lib/img/BOTTOM 6 cancella file.png"),
                                        link: "/info",
                                        id: "btn_remove",
                                        disabled: false,
                                        title: "Remove",
                                        width: 30
                                    });


                                }

                                if (p_upload == 1) {

                                    pulsantis.push({
                                        text: "Edit",
                                        icon: "mdi-home-circle",
                                        image: this.getUrlDomain("public/_lib/img/upload_doxweb.png"),
                                        link: "/info",
                                        id: "btn_upload",
                                        disabled: false,
                                        title: "Upload",
                                        width: 30
                                    });


                                }


                                if (p_edit_permissions == 1) {

                                    pulsantis.push({
                                        text: "Edit",
                                        icon: "mdi-home-circle",
                                        image: this.getUrlDomain("public/_lib/img/permissions_doweb.png"),
                                        link: "/info",
                                        id: "btn_permissions",
                                        disabled: false,
                                        title: "Permissions",
                                        width: 30
                                    });


                                }



                                if (favorities_enable == "S") {

                                    pulsantis.push({
                                        text: "Edit",
                                        icon: "mdi-home-circle",
                                        image: this.getUrlDomain("public/_lib/img/set_password.png"),
                                        link: "/info",
                                        id: "btn_set_password",
                                        disabled: false,
                                        title: "Set Password",
                                        width: 30
                                    });


                                }







                            }

                            if (is_file == 1) {


                                var info_parent = window.infoFolderParent[window.infoFolderParent.length - 1];

                                console.log("INFOR PARENT FOLDER: ", info_parent[0]["p_remove_file"]);

                                console.log("INFOR PARENT FOLDER: ", info_parent[0]["p_rename_file_folder"]);


                                if (tot == 1) {



                                    pulsantis.push({
                                        text: "Edit",
                                        icon: "mdi-home-circle",
                                        image: this.getUrlDomain("public/_lib/img/BOTTOM 4 download file.png"),
                                        link: "/info",
                                        id: "btn_download",
                                        disabled: false,
                                        title: "Download",
                                        width: 30
                                    });


                                    if (info_parent[0]["p_rename_file_folder"] == 1) {


                                        pulsantis.push({
                                            text: "Edit",
                                            icon: "mdi-home-circle",
                                            image: this.getUrlDomain("public/_lib/img/icon_edit_doxweb.png"),
                                            link: "/info",
                                            id: "btn_rename",
                                            disabled: false,
                                            title: "Rename",
                                            width: 30
                                        });


                                    }



                                }


                                if (tot > 0) {


                                    if (info_parent[0]["p_remove_file"] == 1) {


                                        pulsantis.push({
                                            text: "Delete",
                                            icon: "mdi-home-circle",
                                            image: this.getUrlDomain("public/_lib/img/BOTTOM 6 cancella file.png"),
                                            link: "/info",
                                            id: "btn_remove",
                                            disabled: false,
                                            title: "Remove",
                                            width: 30
                                        });


                                    }


                                }











                            }



                        }



                        if (favorities_enable == "S") {

                            pulsantis.push({
                                text: "Edit",
                                icon: "mdi-home-circle",
                                image: this.getUrlDomain("public/_lib/img/doxweb_preferiti.png"),
                                link: "/info",
                                id: "btn_favorities",
                                disabled: false,
                                title: "Favorities",
                                width: 30
                            });


                        }



                    }








                    this.$root.$children[0].bnavbuttons = pulsantis;



                }, 100);



            }

            if (window.idFolderOriginCopyPermissions > 0) { // sono in copy permissions


                setTimeout(() => {

                    var pulsantis = [];


                    pulsantis.push({
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: this.getUrlDomain("public/_lib/img/copia_diritti.png"),
                        link: "/settings",
                        id: "btn_confirm_copy_permissions",
                        disabled: false,
                        title: "Confirm Copy Permissions",
                        width: 30
                    });

                    pulsantis.push({
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: this.getUrlDomain("public/_lib/img/cancel_function_file_folder.png"),
                        link: "/settings",
                        id: "btn_cancel_copy_permissions",
                        disabled: false,
                        title: "Cancel Copy Permissions",
                        width: 30
                    });


                    this.$root.$children[0].bnavbuttons = pulsantis;

                }, 100);


            }


            if (window.addFavorite == 1) {


                var p_worker2 = 0;

                $(".val_status").each(function (index) {

                    console.log(index);

                    if ($(this).prop("checked")) {


                        p_worker2 = $(this).attr("p_worker");

                    }

                });




                setTimeout(() => {

                    var pulsantis = [];

                    if (p_worker2 == 0) {

                        pulsantis.push({
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: this.getUrlDomain("public/_lib/img/doxweb_preferiti.png"),
                            link: "/settings",
                            id: "btn_confirm_add_favorite",
                            disabled: false,
                            title: "Confirm Add Favorite",
                            width: 30
                        });


                    }


                    pulsantis.push({
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: this.getUrlDomain("public/_lib/img/cancel_function_file_folder.png"),
                        link: "/settings",
                        id: "btn_cancel_add_favorite",
                        disabled: false,
                        title: "Cancel Add Favorite",
                        width: 30
                    });


                    this.$root.$children[0].bnavbuttons = pulsantis;

                }, 100);



            }








        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        getUrlDomainForView: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname;

            }

            return result + nome_metodo;

        },

        getUrlApiDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + "api/newdoxweb/" + nome_metodo;

        },




        setupGrid: function () {


            var filtri = `<img src='/dox/img/refresh-doxweb.png' style='border:0!important;' class='selaggiorna' title='Refresh' /><img src='/dox/img/back_to_root_top_doxweb.png' style='border:0!important;' class='selbacktoroot' title='Back Root' />`;


            var pointerVue = this;



            $(document).ready(function () {


                window.columns = [{
                    data: 'sel_row',
                    orderable: false,
                    searchable: false,
                    name: '',
                    width: '0.2%',
                },

                {
                    data: 'DT_RowIndex',
                    orderable: false,
                    searchable: false,
                    name: '',
                    width: '0.2%',
                },

                {
                    data: 'type_element',
                    orderable: false,
                    searchable: false,
                    name: '',
                    width: '3%',
                },

                {
                    data: 'text',
                    orderable: true,
                    searchable: true,
                    name: '',
                    width: '4%',
                },



                {
                    data: 'size_element',
                    orderable: true,
                    searchable: true,
                    name: '',
                    width: '3%',
                },




                {
                    data: 'date',
                    orderable: true,
                    searchable: true,
                    name: '',
                    width: '4%',

                    render: function (data, row) {
                        console.log(data, row);

                        var date = "";
                        try {

                            date = data.substring(0, 10);
                        } catch (error) {
                            console.log(error);
                        }

                        var hour = "";
                        try {

                            hour = data.split(" ")[1].slice(0, 5);
                        } catch (error) {
                            console.log(error);
                        }

                        var html = "<div class='cl-div-hid'>" + data + "</div><div class='font-row-grid-datetime'><div class='cl-time'>" + hour + "</div><div class='cl-date'>" + date + "</div></div>";

                        return html;
                    }

                },


                {
                    data: 'view_folder',
                    orderable: true,
                    searchable: true,
                    name: '',
                    width: '1%',
                },


                {
                    data: 'status',
                    orderable: true,
                    searchable: true,
                    name: '',
                    width: '1%',
                },

                ];



                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: true,
                    paging: false,
                    serverSide: false,


                    /*  pageSize: 30, */
                    "sDom": "lfrti",

                    data: window.dataset,



                    ajax: {
                        url: pointerVue.getUrlApiDomain("getMyFolders"),
                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {


                                if (window.table != undefined) {

                                    window.table.clear().draw();


                                }



                            } catch (error) {
                                console.log(error);
                            }

                            window.start = window.perpage;

                            valori.start = 0;
                            valori.length = window.perpage;


                            valori.curpage = window.curpage;
                            valori.p_worker = window.p_worker;
                            valori.askFiles = window.askFiles;
                            valori.folder_level = window.folder_level;
                            valori.id = window.id;
                            valori.login = window.login;
                            valori.useremail = window.useremail;
                            valori.a = window.a;
                            valori.b = window.b;
                            valori.app = window.app;

                            valori.updateFolderSize = window.updateFolderSize;


                            valori.curSrcText = window.curSrcText;

                            window.valori = valori;
                            return valori;

                        },
                        error: function (xhr, error, code) {

                            var url_req = window.table.ajax.url();
                            var url_params = window.table.ajax.params();

                            console.log("DATATABLE URL: ", url_req);
                            console.log("DATATABLE PARAMS: ", url_params);

                            console.log(xhr, code, error);

                           // pointerVue.logError(xhr, code, error, url_req, url_params);


                        }

                    },



                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: ''
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {




                        console.log(settings);

                        $(".spin").hide();


                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });



                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();




                        if (window.folder_level > 0) {

                            $(".selbacktoroot").show();
                            $("#backHeader").show();

                        }
                        else {

                            $(".selbacktoroot").hide();
                            $("#backHeader").hide();

                        }






                    }

                });




                //Events
                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();

                            setTimeout(() => {

                                $(".spin").hide();

                            }, 500);


                            var valori = window.valori;
                            valori.start = window.start;

                            $.ajax({
                                url: pointerVue.getUrlApiDomain("getMyFolders"),
                                data: window.valori,
                                success: function (data) {
                                    //  $(".spin").hide();
                                    window.loading = 0;
                                    window.start += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        //  window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                $("#myTable_filter").find("label").remove();

                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <div class="text_title">Documents</div>
                    ` + filtri + `

                    <div class="label_url"></div>

                    <img id="backHeader" src="/dox/img/back-doxweb-header.png" style="width:35px;height:35px;display:none;cursor:pointer"  title='back' />

                
                </div>

               


                <div><img src='/dox/img/cerca-doxweb.png' style='border:0!important;' class='selricerca' title='Search' /></div>

            `);


                setTimeout(() => {

                    if (window.labelUrlForCedolini != "") {

                        $(".label_url").html(window.labelUrlForCedolini);


                    }


                }, 200);



                $(".selricerca").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.textSearch = "";

                    $(".contpopupsearch").show();

                    pointerVue.$modal.show('popupSearch');

                    setTimeout(() => {

                        $("#fldSearch").focus();

                    }, 200);

                });



                $(".selaggiorna").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.hideShowButtons(false);

                    window.app = "doxweb";

                    // pointerVue.$root.$children[0].showProgress = true;

                    window.updateFolderSize = 1;


                    window.table.ajax.reload();



                });




                $(".selbacktoroot").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.backToRoot();

                });






                $('body').on('click', '.cont_documents_doxweb tr', function () {



                    setTimeout(() => {

                        console.log("CANCELVIEWROE: ", window.cancelViewRow);

                        if (window.cancelViewRow == 0) {

                            console.log("TR-ACTIVE");

                            var row_data = window.table.row(this).data();

                            if (row_data != null) {


                                console.log("ROW DATA: ", row_data);


                                var last_id_sel = $("tr.active-row-doxweb").find(".id_row").attr("data-id");

                                var id_sel = $(this).find(".id_row").attr("data-id");


                                var set_cls_active = 0;

                                if (last_id_sel == "undefined") {
                                    set_cls_active = 1;
                                } else {
                                    if (last_id_sel != id_sel) {
                                        set_cls_active = 1;
                                    }
                                }

                                console.log("ROM IS ACTIVE: ", set_cls_active);



                                console.log("THIS: ", this);

                                var hasClassActive = $(this).hasClass("active-row-doxweb");

                                if (hasClassActive) { // è già selezionata



                                    if (row_data.isDir) { // è una folder


                                        //  pointerVue.viewFolder();
                                        pointerVue.checkPasswordFolderEnabled("view_folder");

                                    }
                                    else {

                                        console.log("active-row-doxweb");

                                        $(this).find(".val_status").prop("checked", false);

                                        $(this).removeClass("active-row-doxweb");



                                    }





                                }


                                if (!hasClassActive) { // non è selezionata

                                    console.log("NOT active-row-doxweb");


                                    $(".val_status").prop("checked", false);

                                    $("tr").find("td:eq(5)").find("div.rowContainIcon").remove();



                                    $("tr").find("td:eq(6)").find("div.rowContainIconViewRow").remove();

                                    $("tr").find("td:eq(6)").removeClass("columnViewRow");



                                    $("tr").find("td:eq(7)").find("div.rowContainIconCancelRow").remove();

                                    $("tr").find("td:eq(7)").removeClass("columnCancelRow");





                                    $("tr").removeClass("active-row-doxweb");









                                    $(this).find(".val_status").prop("checked", true);

                                    $(this).addClass("active-row-doxweb");


                                    if (row_data.isDir) { // è una folder

                                        $(this).css("cursor", "pointer");

                                        var ele_td = $(".active-row-doxweb").find("td:eq(5)");

                                        console.log("ELEMENT TD: ", ele_td);




                                        // icona view row     

                                        var ele_td3 = $(".active-row-doxweb").find("td:eq(6)");
                                        ele_td3.addClass("columnViewRow");

                                        var div3 = $("<div class='rowContainIconViewRow'>");

                                        var txtImg3 = "<img src = '" + pointerVue.getUrlDomain("public/_lib/img/BOTTOM 5 vedi file.png") + "' class='iconViewRow'  />";

                                        div3.html(txtImg3);

                                        ele_td3.html(div3);





                                        // icona cancellazione view row     

                                        var ele_td2 = $(".active-row-doxweb").find("td:eq(7)");
                                        ele_td2.addClass("columnCancelRow");

                                        var div2 = $("<div class='rowContainIconCancelRow'>");

                                        var txtImg2 = "<img src = '" + pointerVue.getUrlDomain("public/_lib/img/cancel_view_folder.png") + "' class='cancelViewRow'  />";

                                        div2.html(txtImg2);

                                        ele_td2.html(div2);

                                    }






                                }

                                window.id_par_sel = $("tr.active-row-doxweb").find(".id_row").attr("data-id");

                                if (window.id_par_sel == undefined) {
                                    window.id_par_sel = id_sel;
                                }

                                console.log("ID SELECTED: ", window.id_par_sel);


                                console.log("FIND VAL_STATUS: ", $(this).find(".val_status"));

                                var find_check = $(this).find(".val_status");
                                if (find_check.is(":checked")) {
                                    console.log("SELEZIONATO");

                                }

                                if (!find_check.is(":checked")) {
                                    console.log("DESELEZIONATO");

                                }


                                pointerVue.hideShowButtonsSelRow();


                            }


                        }



                        if (window.cancelViewRow == 1) {


                            window.cancelViewRow = 0;

                            $(".val_status").prop("checked", false);



                            $("tr").find("td:eq(5)").find("div.rowContainIcon").remove();




                            $("tr").find("td:eq(6)").find("div.rowContainIconViewRow").remove();

                            $("tr").find("td:eq(6)").removeClass("columnViewRow");



                            $("tr").find("td:eq(7)").find("div.rowContainIconCancelRow").remove();

                            $("tr").find("td:eq(7)").removeClass("columnCancelRow");



                            $("tr").removeClass("active-row-doxweb");



                            pointerVue.hideShowButtons(false);

                        }


                    }, 200);




                });




            });

        },


        setButtonsAddFavorite: async function () {


            setTimeout(() => {

                var pulsantis = [];


                pulsantis.push({
                    text: "Add",
                    icon: "mdi-home-circle",
                    image: this.getUrlDomain("public/_lib/img/doxweb_preferiti.png"),
                    link: "/settings",
                    id: "btn_confirm_add_favorite",
                    disabled: false,
                    title: "Confirm Add Favorite",
                    width: 30
                });

                pulsantis.push({
                    text: "Add",
                    icon: "mdi-home-circle",
                    image: this.getUrlDomain("public/_lib/img/cancel_function_file_folder.png"),
                    link: "/settings",
                    id: "btn_cancel_add_favorite",
                    disabled: false,
                    title: "Cancel Add Favorite",
                    width: 30
                });


                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);


        },


        setButtonsCopyPermissions: async function () {


            setTimeout(() => {

                var pulsantis = [];


                pulsantis.push({
                    text: "Add",
                    icon: "mdi-home-circle",
                    image: this.getUrlDomain("public/_lib/img/copia_diritti.png"),
                    link: "/settings",
                    id: "btn_confirm_copy_permissions",
                    disabled: false,
                    title: "Confirm Copy Permissions",
                    width: 30
                });

                pulsantis.push({
                    text: "Add",
                    icon: "mdi-home-circle",
                    image: this.getUrlDomain("public/_lib/img/cancel_function_file_folder.png"),
                    link: "/settings",
                    id: "btn_cancel_copy_permissions",
                    disabled: false,
                    title: "Cancel Copy Permissions",
                    width: 30
                });


                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);


        },

        hideShowButtons: async function (v_enable) {


            if (window.idFolderOriginCopyPermissions == 0 && window.addFavorite == 0) // non sono in copia permessi ne in add favorite
            {


                var favorities_enable = window.$cookies.get("doxweb_favorities");

                var p_create_folder_enable = window.$cookies.get("doxweb_p_create_folder");

                console.log(v_enable);

                console.log("ARRAY FOLDER PARENT: ", window.infoFolderParent);

                setTimeout(() => {



                    var pulsantis = [];

                    if (window.folder_level == 0) {

                        if (p_create_folder_enable == 1) {

                            pulsantis.push({
                                text: "Add",
                                icon: "mdi-home-circle",
                                image: this.getUrlDomain("public/_lib/img/DOXWEB BOTTOM 54 più.png"),
                                link: "/settings",
                                id: "btn_create",
                                disabled: false,
                                title: "Create",
                                width: 30
                            });


                        }



                    }

                    if (window.folder_level > 0) {


                        var p_create_folder = window.infoFolderParent[window.infoFolderParent.length - 1][0]["p_create_folder"];

                        var p_upload = window.infoFolderParent[window.infoFolderParent.length - 1][0]["p_upload"];

                        var p_edit_permissions = window.infoFolderParent[window.infoFolderParent.length - 1][0]["p_edit_permissions"];

                        if (p_create_folder == 1) {

                            pulsantis.push({
                                text: "Add",
                                icon: "mdi-home-circle",
                                image: this.getUrlDomain("public/_lib/img/DOXWEB BOTTOM 54 più.png"),
                                link: "/settings",
                                id: "btn_create",
                                disabled: false,
                                title: "Create",
                                width: 30
                            });

                        }

                        if (p_upload == 1) {

                            pulsantis.push({
                                text: "Edit",
                                icon: "mdi-home-circle",
                                image: this.getUrlDomain("public/_lib/img/upload_doxweb.png"),
                                link: "/info",
                                id: "btn_upload",
                                disabled: false,
                                title: "Upload",
                                width: 30
                            });


                        }

                        if (p_edit_permissions == 1) {

                            pulsantis.push({
                                text: "Edit",
                                icon: "mdi-home-circle",
                                image: this.getUrlDomain("public/_lib/img/permissions_doweb.png"),
                                link: "/info",
                                id: "btn_permissions",
                                disabled: false,
                                title: "Permissions",
                                width: 30
                            });


                        }




                    }




                    if (favorities_enable == "S") {

                        pulsantis.push({
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: this.getUrlDomain("public/_lib/img/doxweb_preferiti.png"),
                            link: "/info",
                            id: "btn_favorities",
                            disabled: false,
                            title: "Favorities",
                            width: 30
                        });


                    }




                    this.$root.$children[0].bnavbuttons = pulsantis;

                }, 100);

            }


            if (window.idFolderOriginCopyPermissions > 0) // sono in copia permessi
            {

                setTimeout(() => {

                    var pulsantis = [];


                    pulsantis.push({
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: this.getUrlDomain("public/_lib/img/copia_diritti.png"),
                        link: "/settings",
                        id: "btn_confirm_copy_permissions",
                        disabled: false,
                        title: "Confirm Copy Permissions",
                        width: 30
                    });

                    pulsantis.push({
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: this.getUrlDomain("public/_lib/img/cancel_function_file_folder.png"),
                        link: "/settings",
                        id: "btn_cancel_copy_permissions",
                        disabled: false,
                        title: "Cancel Copy Permissions",
                        width: 30
                    });


                    this.$root.$children[0].bnavbuttons = pulsantis;

                }, 100);

            }




            if (window.addFavorite == 1) // sono in add favorite
            {

                setTimeout(() => {

                    var pulsantis = [];


                    pulsantis.push({
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: this.getUrlDomain("public/_lib/img/doxweb_preferiti.png"),
                        link: "/settings",
                        id: "btn_confirm_add_favorite",
                        disabled: false,
                        title: "Confirm Add Favorite",
                        width: 30
                    });

                    pulsantis.push({
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: this.getUrlDomain("public/_lib/img/cancel_function_file_folder.png"),
                        link: "/settings",
                        id: "btn_cancel_add_favorite",
                        disabled: false,
                        title: "Cancel Add Favorite",
                        width: 30
                    });


                    this.$root.$children[0].bnavbuttons = pulsantis;

                }, 100);

            }






        },



        hideShowButtonsOld: async function (v_enable) {


            if (window.idFolderOriginCopyPermissions == 0 && window.addFavorite == 0) // non sono in copia permessi ne in add favorite
            {


                var favorities_enable = window.$cookies.get("doxweb_favorities");


                console.log(v_enable);

                console.log("ARRAY FOLDER PARENT: ", window.infoFolderParent);

                setTimeout(() => {


                    var a = window.$cookies.get("a");

                    console.log("VALORE A: ", a);

                    var pulsantis = [];


                    if (a == "S") {

                        pulsantis.push({
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: this.getUrlDomain("public/_lib/img/DOXWEB BOTTOM 54 più.png"),
                            link: "/settings",
                            id: "btn_create",
                            disabled: false,
                            title: "Create",
                            width: 30
                        });

                        if (window.folder_level > 0) {


                            pulsantis.push({
                                text: "Edit",
                                icon: "mdi-home-circle",
                                image: this.getUrlDomain("public/_lib/img/upload_doxweb.png"),
                                link: "/info",
                                id: "btn_upload",
                                disabled: false,
                                title: "Upload",
                                width: 30
                            });

                            pulsantis.push({
                                text: "Edit",
                                icon: "mdi-home-circle",
                                image: this.getUrlDomain("public/_lib/img/permissions_doweb.png"),
                                link: "/info",
                                id: "btn_permissions",
                                disabled: false,
                                title: "Permissions",
                                width: 30
                            });

                        }


                    }





                    if (window.folder_level > 0) {


                        if (a == "N") {

                            var p_upload = window.infoFolderParent[window.infoFolderParent.length - 1][0]["p_upload"];

                            var p_create_folder = window.infoFolderParent[window.infoFolderParent.length - 1][0]["p_create_folder"];


                            if (p_create_folder == 1) {


                                pulsantis.push({
                                    text: "Add",
                                    icon: "mdi-home-circle",
                                    image: this.getUrlDomain("public/_lib/img/DOXWEB BOTTOM 54 più.png"),
                                    link: "/settings",
                                    id: "btn_create",
                                    disabled: false,
                                    title: "Create",
                                    width: 30
                                });


                            }



                            if (p_upload == 1) {


                                pulsantis.push({
                                    text: "Edit",
                                    icon: "mdi-home-circle",
                                    image: this.getUrlDomain("public/_lib/img/upload_doxweb.png"),
                                    link: "/info",
                                    id: "btn_upload",
                                    disabled: false,
                                    title: "Upload",
                                    width: 30
                                });


                            }



                        }





                    }

                    if (favorities_enable == "S") {

                        pulsantis.push({
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: this.getUrlDomain("public/_lib/img/doxweb_preferiti.png"),
                            link: "/info",
                            id: "btn_favorities",
                            disabled: false,
                            title: "Favorities",
                            width: 30
                        });


                    }




                    this.$root.$children[0].bnavbuttons = pulsantis;

                }, 100);

            }


            if (window.idFolderOriginCopyPermissions > 0) // sono in copia permessi
            {

                setTimeout(() => {

                    var pulsantis = [];


                    pulsantis.push({
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: this.getUrlDomain("public/_lib/img/copia_diritti.png"),
                        link: "/settings",
                        id: "btn_confirm_copy_permissions",
                        disabled: false,
                        title: "Confirm Copy Permissions",
                        width: 30
                    });

                    pulsantis.push({
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: this.getUrlDomain("public/_lib/img/cancel_function_file_folder.png"),
                        link: "/settings",
                        id: "btn_cancel_copy_permissions",
                        disabled: false,
                        title: "Cancel Copy Permissions",
                        width: 30
                    });


                    this.$root.$children[0].bnavbuttons = pulsantis;

                }, 100);

            }




            if (window.addFavorite == 1) // sono in add favorite
            {

                setTimeout(() => {

                    var pulsantis = [];


                    pulsantis.push({
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: this.getUrlDomain("public/_lib/img/doxweb_preferiti.png"),
                        link: "/settings",
                        id: "btn_confirm_add_favorite",
                        disabled: false,
                        title: "Confirm Add Favorite",
                        width: 30
                    });

                    pulsantis.push({
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: this.getUrlDomain("public/_lib/img/cancel_function_file_folder.png"),
                        link: "/settings",
                        id: "btn_cancel_add_favorite",
                        disabled: false,
                        title: "Cancel Add Favorite",
                        width: 30
                    });


                    this.$root.$children[0].bnavbuttons = pulsantis;

                }, 100);

            }






        },







        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/DOXWEB BOTTOM 54 più.png",
                        link: "/settings",
                        id: "btn_create",
                        disabled: false,
                        width: 35
                    },

                    {
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon_edit_emt.png",
                        link: "/info",
                        id: "btn_rename",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/DOXWEB BOTTOM 49 cestino.png",
                        link: "/info",
                        id: "btn_remove",
                        disabled: false,
                        width: 35
                    },

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/qrconfirm",
                        id: "btn_qrcode",
                        image: "https://app.smart-book.it/public/smartbook/ICON_3.png",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                    {
                        text: "Pdf",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/sb-export-pdf-50.png",
                        class: "inverted",
                        link: "/pdf",
                        id: "btn_pdf",
                        disabled: false,
                    },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },



        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },

        resetValidation() {
            this.$refs.form.resetValidation();
        },


    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}


.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;

}
</style>

<style>
canvas {
    border: 1px solid #c7c4a9;
    margin-top: 10px;
}

#container-view-pdf {
    display: flex;
    flex-direction: column;
    align-items: center;
}



.fieldValueOtpCud input {
    text-align: center !important;
    font-size: 20px !important;
}

.listFile {
    overflow-y: auto;
    max-height: 280px;
    min-height: 280px;
}

.contpopupmultiupload .cl-checkbox i {
    font-size: 13px !important;
    transform: translateY(0px) !important;
}


#fldValueOtpDelete {
    text-align: center;
    font-size: 20px;
}

.columnUploadFileSelect {
    font-weight: bold;
    margin-left: 3px;
    width: 3px;
    text-align: center;
}


.columnUploadFilePreview {
    font-weight: bold;
    margin-left: 10px;
    width: 81px;
    text-align: center;
}

.columnUploadFileName {
    font-weight: bold;
    width: 180px;
    margin-left: 10px;
}

.columnUploadFileSize {
    font-weight: bold;
}


.rowUploadFile {
    display: flex;
    height: 40px;
    align-items: center;
}

.div-hover {
    border: 5px dashed #8ec8a3 !important;
}


.cont_documents_doxweb input[type="checkbox"] {
    accent-color: #8ec8a3;
}


.contpopuppermissions .rowShared {
    display: flex;
    align-items: center;
    height: 22px;
    margin-bottom: 3px;
}

.contpopuppermissions .colDescShared {
    margin-left: 10px;
    width: 288px;
}

.contpopuppermissions .selMail {
    cursor: pointer;
    width: 200px;
}

.contpopuppermissions .selGroup {
    cursor: pointer;
    width: 130px;
}

.contpopuppermissions .colDeleteShared {}

.cont_documents_doxweb .columnCancelRow {
    padding: 0px;
    background: white;
    width: 70px;
    border-left: 1px solid black;
}

.cont_documents_doxweb .columnViewRow {
    padding: 0px;
    background: white;
    width: 70px;
}


.cont_documents_doxweb .rowContainIcon {
    display: flex;
    float: inline-end;
    margin-right: 5px;
}

.cont_documents_doxweb .rowContainIconCancelRow {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
}


.cont_documents_doxweb .rowContainIconViewRow {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
}





.cont_documents_doxweb .styleMobileFileFolder {
    font-size: 11px;
    width: 305px;
}


.cont_documents_doxweb .styleMobile {
    font-size: 11px;
}

.cont_documents_doxweb .styleMobileSize {
    font-size: 11px;
    width: 55px
}

.cont_documents_doxweb .cancelViewRow {
    width: 30px;
    height: 30px;
    z-index: 999;
}

.cont_documents_doxweb .iconViewRow {
    width: 30px;
    height: 30px;
}




.forgottenPassword {
    color: black;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
}

.rowFavorite {
    font-weight: bold;
    cursor: pointer;
    margin-top: 4px;
}

.contpopupfavorities .cl-checkbox i {
    font-size: 13px !important;
    transform: translateY(0px) !important;
}

.contpopupfavorities .cl-checkbox {
    margin-top: 0px !important;
    padding-top: 0px !important;
}

.contCheck {
    align-items: top;
}


#firmaCanvas {
    border: 1px solid black;
}

.titleSignature {
    margin-top: 10px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 0px;
}

.subtitleSignature {
    margin-top: 10px;
    text-align: center;
    font-weight: bold;
    font-size: 13px;
    margin-top: 0px;
}

#rowRecursive label {
    font-size: 11px !important;
}

.textWarning {
    text-align: center;
    font-size: 11px;
    padding-left: 10px;
    padding-right: 10px;
}


.eleRow {
    margin-left: 10px;
}

.cont_documents_doxweb .cl-date {
    font-size: 9px;
    color: gray;
}


.cl-div-hid {
    display: none;
}

.cont_documents_doxweb .cl-checkbox i {
    font-size: 13px !important;
}


/* .cont_documents_doxweb .font-row-grid-datetime {
    font-size: 11px !important;
    width: 70px !important;
} */


.cont_documents_doxweb .font-row-grid-datetime {
    font-size: 11px !important;
    width: 80px !important;
}




.cont_documents_doxweb .dataTables_empty {
    display: none !important;
}

.clIconSwallGreen {
    border-color: #8ec8a3 !important;
    color: #8ec8a3 !important;
}


.v-icon i {
    color: #8ec8a3 !important;
}

.clConfirmGroupOrMail {
    margin-top: -19px !important;
}

.primary-text-green {
    color: #8ec8a3 !important;
    caret-color: #8ec8a3 !important;
}

.clColorGreen {
    color: #8ec8a3 !important;
}

.v-label-green {
    color: #8ec8a3 !important;
}

.v-label--active {
    color: #8ec8a3 !important;
}

.v-radio .v-icon {
    color: #8ec8a3 !important;
    accent-color: #8ec8a3 !important;
}

.v-radio:checked {
    color: #8ec8a3 !important;
}


input[type=radio]:checked {
    color: #8ec8a3 !important;
}

#fldSearch {
    caret-color: #8ec8a3 !important;
}

.clBorderBottomGreen {
    border-bottom: 3px solid #8ec8a3 !important;
    border-bottom-color: #8ec8a3 !important;
    caret-color: #8ec8a3 !important;
}


.clMail {

    padding-bottom: 0px !important;
    height: 30px !important;
    padding-top: 0px !important;
    margin-top: -18px !important;

}


.cntRi {
    align-items: center;
    display: flex;
    flex-direction: row;
    min-height: 23px;
    margin-left: 10px;
}

.clTitlePermissions {
    min-width: 300px;
    color: white;
    padding: 3px;
    background: rgb(157, 155, 155);
    font-weight: bold;
    font-size: 13px;
    margin-left: 10px;
    margin-right: 10px;
}

.contpopuppermissions .flex-container {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
}



.contpopuppermissions .flex-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contpopuppermissions .v-label {
    font-weight: bold !important;
    font-size: 11px !important;
    color: black !important
}

.contpopupsendfilebymail .v-label {
    font-weight: bold !important;
    font-size: 11px !important;
    color: black !important
}

.contpopupexpiringfile .v-label {
    font-weight: bold !important;
    font-size: 11px !important;
    color: black !important
}


.rowPrevFile .dropify-wrapper {
    height: 187px !important;
    width: 363px !important;
    margin-bottom: 20px;
    margin-left: 1px;
}


.label_url {
    color: black;
    margin-left: 15px;
}

.dataTable tbody tr,
.dataTable tbody tr td {
    vertical-align: middle;
    padding: 6px !important;
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #f5cf2e;

}

.pay a {
    color: #f5cf2e;
    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #f5cf2e;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.table-responsive {
    max-width: 100vw;
}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    background-image: url(/smartb/img/FiltroNucleo.png);
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);
}


.cont-table .spin {

    display: flex;
    position: absolute;
    z-index: 100;
    min-width: 100vw;
    min-height: 100vh;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}

.cont_documents_doxweb {
    overflow-x: auto !important;
}

.cont_documents_doxweb #myTable {
    margin-right: 3px;
    width: 685px !important;
}

.cont_documents_doxweb table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_documents_doxweb table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
}

.cont_documents_doxweb th {
    padding-left: 5px !important;
}

.cont_documents_doxweb ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_documents_doxweb .table-responsive {
    padding: 0px;
    min-width: 80vw;
    max-width: 96vw;
}


.cont_documents_doxweb #myTable_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    background-color: white;
    border-color: #8ec8a3;

}

.cont_documents_doxweb div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 11px;
    color: gray;
}

.cont_documents_doxweb #myTable_wrapper {
    padding-top: 0px;
    font-size: 11px;
    color: black;
    font-weight: bold;
    min-width: 700px;
    max-width: 700px;
    width: 700px;
}

.cont_documents_doxweb .text_title {
    background-color: white;
    color: black;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    font-size: 10px;
    min-width: 2vw
}


.cont_documents_doxweb .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}


.cont_documents_doxweb .selazienda {
    max-width: 23px;
    min-height: 23px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}



.cont_documents_doxweb .cl_checkall {
    padding-left: 1px;
    padding-top: 2px;
}

.cont_documents_doxweb .checkall {
    width: 13px;
    height: 13px;
}


.cont_documents_doxweb .val_status {
    width: 10px !important;
    margin-top: 2px !important
}

.cont_documents_doxweb .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}

.cont_documents_doxweb .selbacktoroot {
    max-width: 35px;
    min-height: 35px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 35px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    display: none;
}


.cont_documents_doxweb .selbacktheader {
    border: 0 !important;
    width: 35px !important;
    height: 35px !important;
    cursor: pointer;
    display: none;
}



.cont_documents_doxweb .selaggiorna {
    max-width: 23px;
    min-height: 23px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 23px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}


.cont_documents_doxweb .cont_buttons {
    display: flex;
    align-items: center;
    float: left;
    min-width: 86px;
    padding-left: 10px;
    justify-content: inherit;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.itemAzienda {
    margin-left: 15px;
}

.cont_documents_doxweb .cl-time {
    font-size: 11px;
}



@media screen and (max-width: 960px) {

    .cont_documents_doxweb .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_documents_doxweb #myTable {
        /* margin-top:54px; */
    }

    .cont_documents_doxweb #myTable_filter {
        top: 56px;
        position: fixed;

    }

}

@media screen and (max-width: 1024px) {

    .contIcon {
        display: inherit;
    }



    .cont_documents_doxweb #myTable {
        max-width: 100vw !important;
        margin-right: 0px;
        min-width: 100vw !important;
        position: relative;
        width: 100vw !important;

    }

    .cont_documents_doxweb #myTable_filter {
        top: 56px;
        position: fixed;

    }

    .cont_documents_doxweb .table-responsive {
        padding: 0px;
        min-width: 100vw !important;
        max-width: 100vw !important;
        left: 0px;
        position: fixed;

    }

    .cont_documents_doxweb #myTable_wrapper {
        min-width: initial;
        min-width: 100vw !important;
        max-width: 100vw !important;
        width: 100vw !important;
        overflow-x: auto !important;
    }

    .cont_documents_doxweb .cl_num {
        margin-left: -2px !important;
    }


    .cont_documents_doxweb .cl_checkall {
        padding-left: 1px;
        padding-top: 2px;
    }

    .label_url {
        color: black;
        margin-left: 15px;
        font-size: 8px;
    }

    .cont_documents_doxweb .styleMobile {
        font-size: 8px;
    }

    .cont_documents_doxweb .styleMobileSize {
        font-size: 9px;
        width: 40px;
    }

    .cont_documents_doxweb .styleMobileFileFolder {
        font-size: 9px;
        width: 105px;
    }

    .cont_documents_doxweb .columnCancelRow {
        padding: 0px !important;
        background: white;
        width: 50px;
    }

    .cont_documents_doxweb .cl-time {
        font-size: 9px;
    }

    .cont_documents_doxweb .cl-date {
        font-size: 9px;
    }

    .cont_documents_doxweb .font-row-grid-datetime {
        font-size: 9px !important;
        width: 69px !important;
    }

    .cont_documents_doxweb .cancelViewRow {
        width: 30px;
        height: 30px;
        z-index: 999;
        margin-left: 10px;
        margin-right: -7px;
    }

    .cont_documents_doxweb .rowContainIconCancelRow {
        margin-left: -14px;
    }


    canvas {
        border: 1px solid #c7c4a9;
        margin-top: 10px;
        margin-left: 573px;
    }



}
</style>
